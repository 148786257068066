import {
	DeleteButton,
	EditButton,
	List,
	ShowButton,
	useTable,
} from "@refinedev/antd";
import { BaseRecord, CrudFilters, HttpError, IResourceComponentsProps, useList, useTranslate, useUpdate, useNavigation, useNotification } from "@refinedev/core";
import { Button, Card, Form, FormProps, Input, Modal, Select, Space, Table, Tag } from "antd";
import React, { useCallback, useState } from "react";
import { DateTime } from "luxon";
import { QrcodeOutlined } from "@ant-design/icons";
import { Scanner } from '@yudiel/react-qr-scanner';

// Fonction pour décoder les entités HTML
const decodeHTMLEntities = (text: string): string => {
	const textArea = document.createElement('textarea');
	textArea.innerHTML = text;
	return textArea.value;
};

const Filter: React.FC<{ formProps: FormProps, t: any, events: Event[] | undefined }> = ({ formProps, t, events }) => {
	const { data: shops } = useList({
		resource: "shops",
	});

	const uniqueEvents = events?.filter((event: any, index: any, self: any) =>
		index === self?.findIndex((t: any) => t?.id === event?.id)
	);

	return (
		<Form layout="vertical" {...formProps}>
			<div>
				<div>
					<Form.Item name={"firstName"} label={t("users.fields.firstName")}>
						<Input placeholder={t("users.fields.firstName")} />
					</Form.Item>
					<Form.Item name={"lastName"} label={t("users.fields.lastName")}>
						<Input placeholder={t("users.fields.lastName")} />
					</Form.Item>
					<Form.Item name={"phoneNumber"} label={t("users.fields.phoneNumber")}>
						<Input placeholder={t("users.fields.phoneNumber")} />
					</Form.Item>
				</div>
				<div>
					<Form.Item name={"attended"} label={t("event.fields.attended")}>
						<Select
							allowClear
							options={[
								{
									label: t("event.fields.attended"),
									value: true,
								},
								{
									label: t("event.fields.notAttended"),
									value: false,
								},
							]}
							placeholder={t("event.fields.attended")}
						/>
					</Form.Item>
					<Form.Item name={"shopId"} label={t("event.fields.shop.title")}>
						<Select
							key={shops?.data?.length}
							allowClear
							options={shops?.data?.map((shop: any) => ({
								label: shop?.name + " - " + shop?.location?.address,
								value: shop?.id,
							}))}
							placeholder={t("event.fields.shop.title")}
						/>
					</Form.Item>
					<Form.Item name={"eventId"} label={t("event.fields.title")}>
						<Select
							allowClear
							options={uniqueEvents?.map((event: any) => ({
								label: decodeHTMLEntities(t("event.label", {
									date: DateTime.fromISO(event.date).toLocaleString(DateTime.DATE_SHORT),
									time: DateTime.fromISO(event.date).toLocaleString(DateTime.TIME_SIMPLE),
									shop: event.shop.name,
									city: event.shop.location.city
								})),
								value: event.id,
							}))}
							placeholder={t("event.fields.title")}
						/>
					</Form.Item>
					<Form.Item name="status" label={t("users.fields.status.title")}>
						<Select
							placeholder={t("users.fields.status.title")}
							allowClear
							options={[
								{ label: t("users.fields.status.student"), value: "student" },
								{ label: t("users.fields.status.parent"), value: "parent" },
							]}
						/>
					</Form.Item>
				</div>
			</div>
			<Form.Item>
				<Button htmlType="submit">
					{t("actions.filter")}
				</Button>
				<Button htmlType="submit" onClick={() => formProps?.form?.resetFields()}>
					{t("actions.reset")}
				</Button>
			</Form.Item>
		</Form>
	);
}


export const ReservationList: React.FC<IResourceComponentsProps> = () => {
	const t = useTranslate();

	const { tableProps, searchFormProps } = useTable<
		any,
		HttpError,
		any
	>({
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { q, firstName, lastName, attended, shopId, phoneNumber, eventId, status } = params;

			filters.push(
				{
					field: "attented",
					operator: "eq",
					value: attended,
				},
				{
					field: "firstName",
					operator: "eq",
					value: firstName,
				},
				{
					field: "lastName",
					operator: "eq",
					value: lastName,
				},
				{
					field: "phoneNumber",
					operator: "eq",
					value: phoneNumber,
				},
				{
					field: "shopId",
					operator: "eq",
					value: shopId,
				},
				{
					field: "eventId",
					operator: "eq",
					value: eventId,
				},
				{
					field: "gender",
					operator: "in",
					value: status === "parent" ? ["KIDS"] : ["MAN", "WOMAN"],
				}
			);

			return filters;
		},
	});

	const getUserStatus = (genre: any) => {
		if (genre === 'KIDS') {
			return <Tag color="blue">{t("users.fields.status.parent")}</Tag>;
		}

		if (genre === 'MAN' || genre === 'WOMAN') {
			return <Tag color="green">{t("users.fields.status.student")}</Tag>;
		}
	};

	return (
		<List>
			<Card title={t("actions.filter")}>
				<Filter formProps={searchFormProps} t={t} events={tableProps?.dataSource?.map((event: any) => event?.event)} />
			</Card>
			<Table {...tableProps} rowKey="id">
				<Table.Column dataIndex="id" title={"ID"} />
				<Table.Column render={(record: any) => record.user.firstName} title={t("users.fields.firstName")} />
				<Table.Column render={(record: any) => record.user.lastName} title={t("users.fields.lastName")} />
				<Table.Column render={(record: any) => record.user.phoneNumber} title={t("users.fields.phoneNumber")} />
				<Table.Column render={(record: any) => record.event.shop.name} title={t("event.fields.shop.title")} />
				<Table.Column render={(record: any) => DateTime.fromISO(record.event.date).toLocaleString(DateTime.DATE_SHORT)} title={t("event.fields.date")} />
				<Table.Column render={(record: any) => DateTime.fromISO(record.eventSlotReservation.time).toLocaleString(DateTime.TIME_SIMPLE)} title={t("event.fields.time")} />
				<Table.Column render={(record: any) => <Tag color={record.attended ? "green" : "red"}>{record.attended ? t("event.fields.attended") : t("event.fields.notAttended")}</Tag>} title={t("event.fields.attended")} />
				<Table.Column render={(record: any) => <Tag color={record.cancelled ? "red" : "green"}>{record.cancelled ? t("event.fields.cancelled") : t("event.fields.notCancelled")}</Tag>} title={t("event.fields.cancelled")} />
				<Table.Column
					dataIndex={["user", "gender"]}
					title={t("users.fields.status.title")}
					render={(value: any) => getUserStatus(value) ?? "-"}
				/>
				<Table.Column
					title={"Actions"}
					dataIndex="actions"
					render={(_, record: BaseRecord) => (
						<Space>
							<EditButton hideText size="small" recordItemId={record.id} />
							<ShowButton hideText size="small" recordItemId={record.id} />
							{/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
