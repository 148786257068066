import {
	DateField,
	TextField,
	NumberField,
} from "@refinedev/antd";
import { IResourceComponentsProps, useCreate, useNavigation, useShow, useTranslate, useUpdate } from "@refinedev/core";
import { Button, Typography, Checkbox, message, Tooltip } from "antd";
import React, { useState } from "react";
import { Show } from "../../components/crud/show";
import { CheckCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

export const UserShow: React.FC<IResourceComponentsProps> = () => {
	const { queryResult } = useShow({});
	const { data, isLoading } = queryResult;
	const t = useTranslate();
	const record = data?.data;
	const { show } = useNavigation();

	const { mutateAsync } = useCreate();
	const { mutateAsync: updateMutation } = useUpdate();

	const handleResendInvitation = async () => {
		await mutateAsync({
			resource: "users",
			values: {
				email: record?.email,
				role: record?.role,
				partnerId: record?.partnerId ?? undefined,
				bypass: true,
			},
		});
	}

	// Determine if the user is a parent or student
	const isParent = record?.gender === "KIDS";
	const isStudent = record?.gender === "MAN" || record?.gender === "WOMAN";

	const handleValidateDossier = async () => {
		try {
			await updateMutation({
				resource: "users",
				id: record?.id ?? "",
				values: {
					situations: {
						dossierValid: true,
						dossierComplet: true,
					},
				},
			});
			message.success(t("users.messages.dossierValidated"));
		} catch (error) {
			message.error(t("users.messages.dossierValidationError"));
		}
	};

	return (
		<Show isLoading={isLoading}>
			<Title level={4}>{t("users.titles.show")}</Title>
			<div className="flex flex-row items-center" hidden={record?.firstName}>
				<Button type="default" onClick={() => handleResendInvitation()}>
					{t("users.buttons.resendInvitation")}
				</Button>
			</div>

			<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
				<div>
					<Title level={5}>{"ID"}</Title>
					<NumberField value={record?.id ?? ""} />
				</div>
				<div>
					<Title level={5}>{t("users.fields.firstName")}</Title>
					<TextField value={record?.firstName ?? "-"} />
				</div>
				<div>
					<Title level={5}>{t("users.fields.lastName")}</Title>
					<TextField value={record?.lastName ?? "-"} />
				</div>
				<div>
					<Title level={5}>{t("users.fields.email")}</Title>
					<TextField value={record?.email} />
				</div>
				<div>
					<Title level={5}>{t("users.fields.phoneNumber")}</Title>
					<TextField value={record?.phoneNumber ?? "-"} />
				</div>
				<div>
					<Title level={5}>{t("users.fields.role")}</Title>
					<TextField value={record?.role} />
				</div>
				{record?.partnerId && (
					<div className="flex flex-row items-center">
						<Title level={5}>{t("users.fields.partnerId")}</Title>
						<TextField value={record?.partnerId} className="mr-4" />
						<Button type="default" onClick={() => show("partner", record?.partnerId)}>
							{t("users.buttons.showPartner")}
						</Button>
					</div>
				)}
				{record?.situations?.age && isStudent && (
					<div>
						<Title level={5}>{t("users.fields.situations.age")}</Title>
						<TextField value={record?.situations?.age} />
					</div>
				)}
				{isParent && record?.situations?.children && (
					<div className="col-span-2">
						<Title level={5}>{t("users.fields.situations.parent")}</Title>
						{record?.situations?.children.length > 0 ? (
							<div className="space-y-2">
								{record?.situations?.children.map((child: any, index: number) => (
									<div key={index}>
										<Title level={5} className="inline-block mr-2">
											{`${t("users.fields.situations.child")} ${index + 1}:`}
										</Title>
										<TextField
											value={`${t("users.fields.age")}: ${child.age}, ${t("users.fields.gender")}: ${child.gender === "BOY"
												? t("users.fields.boy")
												: t("users.fields.girl")
												}`}
											className="inline-block"
										/>
									</div>
								))}
							</div>
						) : (
							<TextField value={t("users.fields.situations.noChildren")} />
						)}
					</div>
				)}
				{isStudent && (
					<div>
						<Title level={5}>{t("users.fields.situations.studentStatus")}</Title>
						<TextField value={t("users.fields.situations.student")} />
					</div>
				)}
				<div>
					<Title level={5}>{t("users.fields.createdAt")}</Title>
					<DateField value={record?.createdAt} />
				</div>
				<div className="col-span-2 mt-4">
					<Title level={5}>{t("users.fields.dossierStatus")}</Title>
					<div className="flex items-center space-x-4">
						<Tooltip title={record?.dossierValid ? t("users.tooltips.dossierAlreadyValid") : ""}>
							<Button
								type="primary"
								onClick={handleValidateDossier}
								disabled={record?.dossierValid}
								icon={record?.dossierValid ? <CheckCircleOutlined /> : null}
							>
								{record?.dossierValid
									? t("users.buttons.dossierValidated")
									: t("users.buttons.validateDossier")}
							</Button>
						</Tooltip>
						<span>
							{record?.dossierValid
								? t("users.messages.dossierIsValid")
								: t("users.messages.dossierNotValidated")}
						</span>
					</div>
				</div>
				<div>
					<Title level={5}>{t("users.fields.dossierValid")}</Title>
					<TextField value={record?.dossierValid ? t("actions.yes") : t("actions.no")} />
				</div>
				<div>
					<Title level={5}>{t("users.fields.dossierComplet")}</Title>
					<TextField value={record?.dossierComplet ? t("actions.yes") : t("actions.no")} />
				</div>
			</div>
		</Show>
	);
};
