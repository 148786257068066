import {
	DeleteButton,
	EditButton,
	List,
	ShowButton,
	useTable,
} from "@refinedev/antd";
import {
	BaseRecord,
	CrudFilters,
	HttpError,
	IResourceComponentsProps,
	useTranslate,
} from "@refinedev/core";
import {
	AutoComplete,
	Button,
	Card,
	Form,
	FormProps,
	Input,
	Select,
	Space,
	Table,
} from "antd";
import React from "react";

// Define the expected structure of the data
interface Document {
	id: number;
}

interface UserDocumentRecord extends BaseRecord {
	userId: number;
	firstName: string;
	lastName: string;
	gender: "MAN" | "WOMAN" | "KIDS";
	documents: Document[];
	situation: {
		dossierComplet: boolean;
	};
}

// Filter Component
const Filter: React.FC<{ formProps: FormProps; t: any }> = ({
	formProps,
	t,
}) => {
	return (
		<Form layout="vertical" {...formProps}>
			<Form.Item name={"dossierComplet"} label={t("documents.fields.dossierComplet")}>
				<Select
					allowClear
					options={[
						{ label: t("documents.fields.yes"), value: true },
						{ label: t("documents.fields.no"), value: false },
					]}
					placeholder={t("documents.fields.dossierComplet")}
				/>
			</Form.Item>
			<Form.Item name={"dossierValid"} label={t("documents.fields.dossierValid")}>
				<Select
					allowClear
					options={[
						{ label: t("documents.fields.yes"), value: true },
						{ label: t("documents.fields.no"), value: false },
					]}
					placeholder={t("documents.fields.dossierValid")}
				/>
			</Form.Item>
			<Form.Item>
				<Button htmlType="submit">{t("actions.filter")}</Button>
				<Button htmlType="submit" onClick={() => formProps?.form?.resetFields()} className="ml-2">
					{t("actions.reset")}
				</Button>
			</Form.Item>
		</Form>
	);
};

// DocumentsList Component
export const DocumentsList: React.FC<IResourceComponentsProps> = () => {
	const t = useTranslate();

	const { tableProps, searchFormProps } = useTable<
		any,
		HttpError,
		any
	>({
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { dossierComplet, dossierValid } = params;

			filters.push(
				{
					field: "situation.dossierComplet",
					operator: "eq",
					value: dossierComplet
				}
			);

			filters.push(
				{
					field: "situation.dossierValid",
					operator: "eq",
					value: dossierValid
				}
			);

			return filters;
		},
	});


	return (
		<List createButtonProps={{
			style: {
				backgroundColor: "#EDA0D8",
			},
			resource: "documents",
			hideText: true,
		}}>
			<Card title={t("actions.filter")}>
				<Filter formProps={searchFormProps} t={t} />
			</Card>
			<Table {...tableProps} rowKey="userId">
				<Table.Column title={t("documents.fields.lastName")} dataIndex="lastName" />
				<Table.Column title={t("documents.fields.firstName")} dataIndex="firstName" />
				<Table.Column
					title={t("documents.fields.beneficiaryType")}
					dataIndex="gender"
					render={(gender: string) =>
						gender === "KIDS" ? t("documents.role.parent") : t("documents.role.student")
					}
				/>
				<Table.Column
					title={t("documents.fields.documentCount")}
					dataIndex="documents"
					render={(documents: Document[]) => documents.length}
				/>
				<Table.Column
					title={t("documents.fields.dossierComplet")}
					dataIndex={"documents"}
					render={(documents: any) => {
						console.log(documents);
						const dossierComplet = documents.some(
							(doc: any) => doc.situation?.dossierComplet
						);
						return dossierComplet ? t("documents.fields.yes") : t("documents.fields.no");
					}
					}
				/>
				{/* colum for dossierValid */}
				<Table.Column
					title={t("documents.fields.dossierValid")}
					dataIndex={"documents"}
					render={(documents: any) => {
						console.log(documents);
						const dossierValid = documents.some(
							(doc: any) => doc.situation?.dossierValid
						);
						return dossierValid ? t("documents.fields.yes") : t("documents.fields.no");
					}
					}
				/>
				<Table.Column
					title={"Actions"}
					dataIndex="actions"
					render={(_, record: UserDocumentRecord) => (
						<Space>
							<ShowButton hideText size="small" recordItemId={record.userId} />
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
