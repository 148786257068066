import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTranslate } from '@refinedev/core';
const UsersTimeGraph = ({ data }: { data: any[] | undefined }) => {
	const t = useTranslate();

	return (
		<ResponsiveContainer width="100%" height={400}>
			<LineChart
				data={data || []}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<defs>
					<linearGradient id="colorCount" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor="#e34958" stopOpacity={0.8} />
						<stop offset="95%" stopColor="#e34958" stopOpacity={0} />
					</linearGradient>
				</defs>
				<CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
				<XAxis dataKey="date" stroke="#cccccc" />
				<YAxis stroke="#cccccc" />
				<Tooltip wrapperStyle={{ borderColor: 'white', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)' }} />
				<Legend />
				<Line
					type="monotone"
					dataKey="count"
					name={t('dashboard.user')}
					stroke="#e34958"
					fillOpacity={1}
					fill="url(#colorCount)"
					strokeWidth={2}
					activeDot={{ r: 8, strokeWidth: 2, stroke: '#e34958', fill: '#ffffff' }}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};

export default UsersTimeGraph;
