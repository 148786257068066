import { DownOutlined } from "@ant-design/icons";
import { useGetLocale, useSetLocale } from "@refinedev/core";
import { Avatar, Button, Dropdown, Layout, Menu, Space } from "antd";
import { useTranslation } from "react-i18next";

export const Header: React.FC = () => {
	const { i18n } = useTranslation();
	const locale = useGetLocale();
	const changeLanguage = useSetLocale();

	const currentLocale = locale();

	const menu = (
		<Menu selectedKeys={currentLocale ? [currentLocale] : []}>
			{[...(i18n.languages || [])].sort().map((lang: string) => (
				<Menu.Item
					key={lang}
					onClick={() => changeLanguage(lang)}
					icon={
						<span style={{ marginRight: 8 }}>
							<Avatar size={16} src={`/images/flags/${lang}.svg`} />
						</span>
					}
				>
					{lang === "en" ? "English" : "French"}
				</Menu.Item>
			))}
		</Menu>
	);

	//   
	//   
	//   
	return (
		<Layout.Header
			style={{
				display: "flex",
				justifyContent: "flex-end",
				alignItems: "center",
				padding: "0px 24px",
				height: "48px",
				backgroundColor: "#FFF",
			}}
		>
			<Dropdown overlay={menu}>
				<Button type="link">
					<Space>
						<Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
						{currentLocale === "en" ? "English" : "French"}
						<DownOutlined />
					</Space>
				</Button>
			</Dropdown>
		</Layout.Header>
	);
};