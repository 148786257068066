import { useState, useEffect } from 'react';
import { colors } from '../../contants'; // Assurez-vous que le chemin d'accès est correct
import { Button, Form, Image } from 'antd';
import { useList, useLogin, useRegister, useTranslate } from "@refinedev/core";

export interface IInvitationForm {
	token: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	password: string;
}


export const Invitation = () => {
	const [form] = Form.useForm<IInvitationForm>();
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
	const t = useTranslate();

	const values = Form.useWatch([], form);

	useEffect(() => {
		form.validateFields({ validateOnly: true })
			.then((formValues) => {
				setIsSubmitDisabled(!Object.values(formValues).length)  // check keys to handle init state
			})
			.catch(() => {
				setIsSubmitDisabled(true)
			})
	}, [values]);

	const { mutate: register, isLoading } = useRegister<IInvitationForm>();

	const url = window.location.href;
	const token = url.split('=')[1];

	if (!token) {
		return (
			<div className="flex justify-center items-center h-screen bg-primary">
				<div className="w-96">
					<div className="bg-white p-6 rounded-lg shadow-lg">
						<div className="flex justify-center mb-8">
							<Image
								width={200}
								src="logo.png"
							/>
						</div>
						<div>
							<p>{t("pages.invitation.invalidToken")}</p>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="flex justify-center items-center h-screen bg-primary">
			<div className="w-96">
				<div className="bg-white p-6 rounded-lg shadow-lg">
					<div className="flex justify-center mb-8">
						<Image
							width={200}
							src="logo.png"
						/>
					</div>
					<div>
						<p>{t("pages.invitation.title")}</p>
					</div>
					<Form
						form={form}
						onFinish={(values) => {
							const body = {
								token: token,
								firstName: values.firstName,
								lastName: values.lastName,
								phoneNumber: values.phoneNumber,
								password: values.password
							}
							register(body);
						}}
					>
						<Form.Item
							labelCol={{ span: 24 }}
							label={t("pages.invitation.fields.firstName")}
							name="firstName"
							rules={[
								{
									required: true,
									message: t("pages.invitation.errors.validFirstName"),
								},
							]}
						>
							<input
								className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none"
								id="firstName"
								type="text"
								placeholder={t("pages.login.fields.firstName")}
							/>
						</Form.Item>
						<Form.Item
							labelCol={{ span: 24 }}
							label={t("pages.invitation.fields.lastName")}
							name="lastName"
							rules={[
								{
									required: true,
									message: t("pages.invitation.errors.validLastName"),
								},
							]}
						>
							<input
								className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none"
								id="lastName"
								type="text"
								placeholder={t("pages.invitation.fields.lastName")}
							/>
						</Form.Item>
						<Form.Item
							labelCol={{ span: 24 }}
							label={t("pages.invitation.fields.phoneNumber")}
							name="phoneNumber"
							rules={[
								{
									required: true,
									message: t("pages.invitation.errors.validPhoneNumber"),
								},
							]}
						>
							<input
								className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none"
								id="phoneNumber"
								type="tel"
								placeholder={t("pages.invitation.fields.phoneNumber")}
							/>
						</Form.Item>
						<Form.Item
							labelCol={{ span: 24 }}
							label={t("pages.invitation.fields.password")}
							name="password"
							rules={[
								{
									required: true,
									message: t("pages.login.errors.validPassword"),
								},
							]}
						>
							<input
								className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none"
								id="password"

								type="password"
								placeholder={t("pages.invitation.fields.password")}
							/>
						</Form.Item>
						<div className="mb-4">
							<Button
								loading={isLoading}
								className="w-full bg-primary text-white rounded-lg font-semibold text-center"
								disabled={isSubmitDisabled}

								onClick={() => {
									form.submit();
								}
								}
							>
								{t("pages.invitation.buttons.submit")}
							</Button>
						</div>

					</Form>
				</div>
			</div>
		</div>

	);
};
