import { Button, Card, Modal, Typography, Table, Input, InputNumber, Image, Select, Tabs, Upload, Form, AutoComplete } from "antd";
import React, { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined, SwapOutlined, PlusOutlined, MinusOutlined, UploadOutlined } from "@ant-design/icons";
import { IResourceComponentsProps, file2Base64, useCreate, useList, useShow, useTranslate, useUpdate, useNotification } from "@refinedev/core";
import TabPane from "antd/es/tabs/TabPane";
import { Show } from "@refinedev/antd";

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

export const InventoryShow: React.FC<IResourceComponentsProps> = () => {
	const t = useTranslate();
	const { queryResult } = useShow({});
	const { data, isLoading, refetch } = queryResult;
	const record = data?.data;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedItemId, setSelectedItemId] = useState(0);
	const [newShopId, setNewShopId] = useState("");
	const [categoryFilter, setCategoryFilter] = useState(null);
	const [sizeFilter, setSizeFilter] = useState(null);
	const categories = [...new Set(record?.items.map((item: any) => item.category.code))];
	const sizes = [...new Set(record?.items.map((item: any) => item.size.value))];
	const [addItemModalVisible, setAddItemModalVisible] = useState(false);
	const [categoryLabel, setCategoryLabel] = useState("");
	const [newItemData, setNewItemData] = useState({
		name: "",
		categoryId: "",
		sizeId: "",
		conditionId: "",
		quantity: 1,
		brandId: null,
		frontImage: "",
		backImage: "",
		defaultImage: "",
		tagImage: "",
		detailsImage: "",
		status: "AVAILABLE",
	});

	const [filteredItems, setFilteredItems] = useState(record?.items);
	const { mutateAsync } = useUpdate();
	const { mutateAsync: mutateAsyncAddItem } = useCreate();

	const { data: shops } = useList({
		resource: "shops",
	});

	const showModal = (itemId: number) => {
		setSelectedItemId(itemId);
		setIsModalVisible(true);
	};

	const handleOk = async () => {
		await handleTransferItem(selectedItemId, newShopId);
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	// Fonction pour gérer l'ouverture du modal d'ajout
	const showAddItemModal = () => {
		setAddItemModalVisible(true);
	};

	// Fonction pour gérer la fermeture du modal d'ajout
	const handleAddItemCancel = () => {
		setAddItemModalVisible(false);
	};

	const { data: categoriesData, isLoading: userIsLoading } = useList({
		resource: "donation/categories",
		pagination: {
			pageSize: 1000,
		}

	});

	console.log('categoriesData', categoriesData);

	const { data: sizesData, isLoading: sizesIsLoading } = useList({
		resource: "donation/categories/sizes",
	});

	const { data: conditionsData, isLoading: conditionsIsLoading } = useList({
		resource: "donation/conditions",
	});

	const handleAddItemSubmit = async () => {
		try {
			let formData = { ...newItemData };

			// Convertir les images en base64
			if (Array.isArray(newItemData.frontImage) && newItemData.frontImage[0]?.originFileObj) {
				const frontImage = await file2Base64(newItemData.frontImage[0]);
				formData.frontImage = frontImage;
			}

			if (Array.isArray(newItemData.backImage) && newItemData.backImage[0]?.originFileObj) {
				const backImage = await file2Base64(newItemData.backImage[0]);
				formData.backImage = backImage;
			}

			if (Array.isArray(newItemData.defaultImage) && newItemData.defaultImage[0]?.originFileObj) {
				const defaultImage = await file2Base64(newItemData.defaultImage[0]);
				formData.defaultImage = defaultImage;
			}

			if (Array.isArray(newItemData.tagImage) && newItemData.tagImage[0]?.originFileObj) {
				const tagImage = await file2Base64(newItemData.tagImage[0]);
				formData.tagImage = tagImage;
			}

			if (Array.isArray(newItemData.detailsImage) && newItemData.detailsImage[0]?.originFileObj) {
				const detailsImage = await file2Base64(newItemData.detailsImage[0]);
				formData.detailsImage = detailsImage;
			}


			await mutateAsyncAddItem({
				resource: "inventory/" + record?.id + "/item",
				values: formData,
			});

			// Rafraîchir les items de l'inventaire
			await refetch();
			setAddItemModalVisible(false);
			setNewItemData({
				name: "",
				categoryId: "",
				sizeId: "",
				conditionId: "",
				quantity: 1,
				brandId: null,
				frontImage: "",
				backImage: "",
				defaultImage: "",
				tagImage: "",
				detailsImage: "",
				status: "AVAILABLE",

			});
			setCategoryLabel("");
			handleFileChange({ fileList: [] }, "frontImage");
			handleFileChange({ fileList: [] }, "backImage");
			handleFileChange({ fileList: [] }, "defaultImage");
			handleFileChange({ fileList: [] }, "tagImage");
			handleFileChange({ fileList: [] }, "detailsImage");

		} catch (error) {
			console.error('Error adding new item: ', error);
		}
	};

	const handleFileChange = (info: any, type: string) => {
		// Pour un seul fichier, prenez le dernier sélectionné
		const file = info.fileList.slice(-1);
		setNewItemData({ ...newItemData, [type]: file });
	};


	const handleTransferItem = async (itemId: number, newShopId: string) => {

		try {
			await mutateAsync({
				resource: "inventory/item/" + itemId + "/transfer",
				id: "",
				values: { shopId: newShopId },
				mutationMode: "pessimistic",
			});

			await refetch(); // Assurez-vous que refetch est bien une fonction asynchrone ou renvoie une promesse.

			// Pas besoin de définir ici filteredItems, useEffect s'en chargera.
		} catch (error) {
			console.error("Error updating item:", error);
		}
	}

	const handleReviewItem = async (itemId: string, decision: boolean) => {
		try {
			await mutateAsync({
				resource: "inventory/item/" + itemId + "/review",
				id: "",
				values: { decision: decision },
				mutationMode: "pessimistic",
			});

			await refetch(); // Assurez-vous que refetch est bien une fonction asynchrone ou renvoie une promesse.

			// Pas besoin de définir ici filteredItems, useEffect s'en chargera.
		} catch (error) {
			console.error("Error updating item:", error);
		}
	};

	const handleInventoryItem = async (itemId: string) => {
		try {
			await mutateAsync({
				resource: "inventory/" + record?.id + "/item/" + itemId,
				id: "",
				values: {
					status: "SOLD"
				},
				mutationMode: "pessimistic",
			});

			await refetch(); // Assurez-vous que refetch est bien une fonction asynchrone ou renvoie une promesse.

			// Pas besoin de définir ici filteredItems, useEffect s'en chargera.
		} catch (error) {
			console.error("Error updating item:", error);
		}
	}

	const handleStockItem = async (itemId: string) => {
		try {
			await mutateAsync({
				resource: "inventory/" + record?.id + "/item/" + itemId,
				id: "",
				values: {
					status: "AVAILABLE"
				},
				mutationMode: "pessimistic",
			});

			await refetch(); // Assurez-vous que refetch est bien une fonction asynchrone ou renvoie une promesse.

			// Pas besoin de définir ici filteredItems, useEffect s'en chargera.
		} catch (error) {
			console.error("Error updating item:", error);
		}
	}


	const flattenCategories = (categories: any) => {
		if (!categories) return [];
		// keep only categories which are a child of another category (i.e. not a root category)
		const children = categories.filter((category: any) => category.parentId);
		const options = children.map((category: any) => {
			return {
				label: category.code,
				value: category.id,
			};
		});
		return options;
	}


	useEffect(() => {
		// Filtrer les articles en fonction des sélections de catégorie et de taille
		const filtered = record?.items.filter((item: any) => {
			return (categoryFilter ? item.category.code === categoryFilter : true) &&
				(sizeFilter ? item.size.value === sizeFilter : true);
		});
		setFilteredItems(filtered);
	}, [categoryFilter, sizeFilter, record]);

	const columns = [
		{
			title: 'Image',
			dataIndex: 'frontImage',
			key: 'frontImage',
			render: (text: string, record: any) => (
				<Image width={50} src={text || record.frontImage} alt="Item Image" />
			),
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Category',
			dataIndex: 'category',
			key: 'category',
			render: (category: any) => t(`category.categories.${category.code}`) || 'Unknown',
		},
		{
			title: 'Size',
			dataIndex: 'size',
			key: 'size',
			render: (size: any) => size?.value || 'Unknown',
		},
		{
			title: 'Action',
			key: 'action',
			render: (text: string, record: any) => {
				if (record.status === 'TO_REVIEW') {
					return (
						<>
							<Button onClick={() => handleReviewItem(record.id, true)} icon={<CheckOutlined />} style={{ marginRight: 8 }}>{t("inventory.actions.accept")}</Button>
							<Button onClick={() => handleReviewItem(record.id, false)} icon={<CloseOutlined />} style={{ marginRight: 8 }}>{t("inventory.actions.refuse")}</Button>
						</>
					);
				} else if (record.status === 'AVAILABLE') {
					return (
						<>
							<Button icon={<MinusOutlined />} onClick={() => handleInventoryItem(record.id)}>{t("inventory.actions.solded")}</Button>
							<Button icon={<SwapOutlined />} onClick={() => showModal(record.id)}>{t("inventory.actions.transfer")}</Button>
						</>
					);
				} else if (record.status === 'SOLD') {
					return (
						<Button onClick={() => handleStockItem(record.id)}>{t("inventory.actions.stock")}</Button>
					);
				}
			},
		},
	];

	return (
		<Show isLoading={isLoading}>
			<div>
				<Title level={4} className="mb-4">{t("shops.fields.name")}: {record?.shop?.name} {' '} - {' '} {record?.shop?.location?.address + ", " + record?.shop?.location?.city + " " + record?.shop?.location?.postalCode + ", " + record?.shop?.location?.country}</Title>
				<div className="flex gap-6 mt-4">
					<Select placeholder={t("inventory.filter.category")} onChange={value => setCategoryFilter(value)} allowClear className="w-1/6">
						{(categories as string[]).map((category, index) => <Option key={index} value={category}>{t(`category.categories.${category}`)}</Option>)}
					</Select>
					<Select placeholder={t("inventory.filter.size")} onChange={value => setSizeFilter(value)} allowClear className="w-1/6">
						{(sizes as string[]).map((size, index) => <Option key={index} value={size}>{size}</Option>)}
					</Select>
					<Button title="Add Item" onClick={showAddItemModal} icon={<PlusOutlined />} type="default">{t("inventory.actions.addItem")}</Button>
				</div>
				<Tabs defaultActiveKey="1" className="mt-4">
					<TabPane tab={t("inventory.status.AVAILABLE") + " (" + record?.items.filter((item: any) => item.status === 'AVAILABLE').length + ")"} key="1">
						<Table dataSource={filteredItems?.filter((item: any) => item.status === 'AVAILABLE')} columns={columns} rowKey="id" scroll={{ x: 768 }} />
					</TabPane>
					<TabPane tab={t("inventory.status.TO_REVIEW") + " (" + record?.items.filter((item: any) => item.status === 'TO_REVIEW').length + ")"} key="2">
						<Table dataSource={filteredItems?.filter((item: any) => item.status === 'TO_REVIEW')} columns={columns} rowKey="id" scroll={{ x: 768 }} />
					</TabPane>
					<TabPane tab={t("inventory.status.SOLD") + " (" + record?.items.filter((item: any) => item.status === 'SOLD').length + ")"} key="3">
						<Table dataSource={filteredItems?.filter((item: any) => item.status === 'SOLD')} columns={columns} rowKey="id" scroll={{ x: 768 }} />
					</TabPane>

				</Tabs>

				<Modal title={t("inventory.actions.transfer")} open={isModalVisible} onOk={handleOk} onCancel={handleCancel} okType="default">
					<Select
						showSearch
						className="w-full mb-4 mt-4"
						placeholder={t("shops.fields.name")}
						optionFilterProp="children"
						onChange={value => setNewShopId(value)}
					>
						{shops?.data?.filter(shop => shop.id !== record?.shop?.id).map(shop => (
							<Option key={shop.id} value={shop.id}>{shop.name} - {shop.location.address}</Option>
						))}
					</Select>
				</Modal>
				<Modal
					title={t("inventory.actions.addItem")}
					open={addItemModalVisible}
					onOk={handleAddItemSubmit}
					okType="default"
					onCancel={handleAddItemCancel}
				>
					<div className="flex flex-col gap-4">
						<p className="text-sm">{t("inventory.fields.name")}*</p>
						<Input
							placeholder={t("inventory.fields.name")}
							value={newItemData.name}
							onChange={e => setNewItemData({ ...newItemData, name: e.target.value })}
						/>
						<p className="text-sm">{t("inventory.fields.category")}*</p>
						<AutoComplete
							placeholder={t("inventory.fields.category")}
							onSelect={(value, option) => {
								// Mettre à jour le formulaire avec la valeur sélectionnée
								setNewItemData({ ...newItemData, categoryId: value });
								setCategoryLabel(option?.label?.toString() || "");
							}}
							value={categoryLabel}
							onChange={value => {
								setCategoryLabel(value);
								setNewItemData({ ...newItemData, categoryId: "" });
							}}
							options={flattenCategories(categoriesData?.data).map((category: any) => ({
								label: t(`category.categories.${category.label}`),
								value: category.value,
							}))}
							filterOption={(inputValue, option) => {
								if (option?.label == null || option?.label == undefined) return false;
								return option?.label.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
							}}
						>
							{/* {flattenCategories(categoriesData?.data).map((category: any) => (
								<Option key={category.value} value={category.value}>{t(`category.categories.${category.label}`)}</Option>
							))} */}
						</AutoComplete>
						<p className="text-sm">{t("inventory.fields.size")}*</p>
						<Select
							placeholder={t("inventory.fields.size")}
							value={newItemData.sizeId || undefined}
							onChange={value => setNewItemData({ ...newItemData, sizeId: value })}
						>
							{sizesData?.data?.map(size => (
								<Option key={size.id} value={size.id}>{size.value}</Option>
							))}
						</Select>
						<p className="text-sm">{t("inventory.fields.condition")}*</p>
						<Select
							placeholder={t("inventory.fields.condition")}
							value={newItemData.conditionId || undefined}
							onChange={value => setNewItemData({ ...newItemData, conditionId: value })}
						>
							{conditionsData?.data?.map(condition => (
								<Option key={condition.id} value={condition.id}>{t(`condition.conditions.${condition.name}`)}</Option>
							))}
						</Select>
						<p className="text-sm">{t("inventory.fields.status")}*</p>
						<Select
							placeholder={t("inventory.fields.status")}
							value={newItemData.status || undefined}
							onChange={value => setNewItemData({ ...newItemData, status: value })}
						>
							<Option value="AVAILABLE">{t("inventory.status.AVAILABLE")}</Option>
							<Option value="TO_REVIEW">{t("inventory.status.TO_REVIEW")}</Option>
							<Option value="SOLD">{t("inventory.status.SOLD")}</Option>
						</Select>
						<p className="text-sm">{t("inventory.fields.frontImage")}</p>
						<Upload
							beforeUpload={() => false} // Empêche l'upload automatique
							onChange={(info) => handleFileChange(info, "frontImage")}

							listType="picture"
							multiple={false}
							accept="image/*"
							maxCount={1}

						>
							<Button icon={<UploadOutlined />}>{t("inventory.fields.uploadImage")}</Button>
						</Upload>
						<p className="text-sm">{t("inventory.fields.backImage")}</p>
						<Upload

							listType="picture"
							multiple={false}
							accept="image/*"
							maxCount={1}

							beforeUpload={() => false} // Empêche l'upload automatique
							onChange={(info) => handleFileChange(info, "backImage")}
						>
							<Button icon={<UploadOutlined />}>{t("inventory.fields.uploadImage")}</Button>
						</Upload>
						<p className="text-sm">{t("inventory.fields.defaultImage")}</p>
						<Upload

							listType="picture"
							multiple={false}
							accept="image/*"
							maxCount={1}

							beforeUpload={() => false} // Empêche l'upload automatique
							onChange={(info) => handleFileChange(info, "defaultImage")}
						>
							<Button icon={<UploadOutlined />}>{t("inventory.fields.uploadImage")}</Button>
						</Upload>
						<p className="text-sm">{t("inventory.fields.tagImage")}</p>
						<Upload

							listType="picture"
							multiple={false}
							accept="image/*"
							maxCount={1}

							beforeUpload={() => false} // Empêche l'upload automatique
							onChange={(info) => handleFileChange(info, "tagImage")}
						>
							<Button icon={<UploadOutlined />}>{t("inventory.fields.uploadImage")}</Button>
						</Upload>
						<p className="text-sm">{t("inventory.fields.detailsImage")}</p>
						<Upload

							listType="picture"
							multiple={false}
							accept="image/*"
							maxCount={1}

							beforeUpload={() => false} // Empêche l'upload automatique
							onChange={(info) => handleFileChange(info, "detailsImage")}
						>
							<Button icon={<UploadOutlined />}>{t("inventory.fields.uploadImage")}</Button>
						</Upload>



					</div>
				</Modal>
			</div >
		</Show >
	);
};
