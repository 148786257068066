import {
	DeleteButton,
	EditButton,
	List,
	ShowButton,
	useTable,
} from "@refinedev/antd";
import { BaseRecord, CrudFilters, HttpError, IResourceComponentsProps, useList, useTranslate, useUpdate, useNavigation, useNotification } from "@refinedev/core";
import { Button, Card, Form, FormProps, Input, Modal, Select, Space, Table, Tag } from "antd";
import React, { useCallback, useState } from "react";
import { DateTime } from "luxon";
import { QrcodeOutlined } from "@ant-design/icons";
import { Scanner } from '@yudiel/react-qr-scanner';

const Filter: React.FC<{ formProps: FormProps, t: any }> = ({ formProps, t }) => {
	return (
		<Form layout="vertical" {...formProps}>
			<div>
				<div>
					<Form.Item name={"firstName"} label={t("users.fields.firstName")}>
						<Input placeholder={t("users.fields.firstName")} />
					</Form.Item>
					<Form.Item name={"lastName"} label={t("users.fields.lastName")}>
						<Input placeholder={t("users.fields.lastName")} />
					</Form.Item>
				</div>
			</div>
			<Form.Item>
				<Button htmlType="submit">
					{t("actions.filter")}
				</Button>
				<Button htmlType="submit" onClick={() => formProps?.form?.resetFields()}>
					{t("actions.reset")}
				</Button>
			</Form.Item>
		</Form>
	);
}


export const PotsList = () => {
	const t = useTranslate();

	const { tableProps, searchFormProps } = useTable<
		any,
		HttpError,
		any
	>({
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { q, firstName, lastName } = params;

			filters.push(
				{
					field: "firstName",
					operator: "eq",
					value: firstName,
				},
				{
					field: "lastName",
					operator: "eq",
					value: lastName,
				},
			);

			return filters;
		},
	});

	return (
		<List>
			<Card title={t("actions.filter")}>
				<Filter formProps={searchFormProps} t={t} />
			</Card>
			<Table {...tableProps} rowKey="id">
				<Table.Column dataIndex="id" title={"ID"} />
				<Table.Column title={t("users.fields.firstName")} render={(value: any) => value?.user?.firstName} />
				<Table.Column title={t("users.fields.lastName")} render={(value: any) => value?.user?.lastName} />
				<Table.Column title={t("users.fields.phoneNumber")} render={(value: any) => value?.user?.phoneNumber} />
				<Table.Column title={t("pots.fields.balance")} render={(value: any) => value?.balance} />
				<Table.Column title={t("pots.fields.totalHistory")} render={(value: any) => value?.history.length} />
				<Table.Column
					title={"Actions"}
					dataIndex="actions"
					render={(_, record: BaseRecord) => (
						<Space>
							<EditButton hideText size="small" recordItemId={record.id} />
							<ShowButton hideText size="small" recordItemId={record.id} />
							{/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
