import { DateField, Edit, useForm } from "@refinedev/antd";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Card, DatePicker, Form, Input, Select } from "antd";
import { DateTime } from "luxon";
import React, { useState } from "react";

// export const ReservationShow: React.FC<IResourceComponentsProps> = () => {
// 	const { queryResult } = useShow({});
// 	const { data, isLoading } = queryResult;

// 	const record = data?.data;
// 	const t = useTranslate();
// 	
// 	return (
// 		<Show isLoading={isLoading} contentProps={{
// 			style: {
// 				display: "flex",
// 				flexDirection: "column",
// 				gap: "1rem",
// 				backgroundColor: "transparent",
// 				boxShadow: "none",
// 			},
// 		}}>
// 			<div className="flex flex-col gap-4">
// 				<div className="flex flex-row gap-4">
// 					<Card title={t("reservations.title")} className="flex-1 w-full md:w-1/2 xl:w-1/3 min-w-1/2">
// 						<Title level={5}>{t("users.fields.lastName")}</Title>
// 						<TextField value={record?.user?.lastName ?? ""} />
// 						<Title level={5}>{t("users.fields.firstName")}</Title>
// 						<TextField value={record?.user?.firstName ?? ""} />
// 						<Title level={5}>{t("event.fields.time")}</Title>
// 						<TextField value={DateTime.fromISO(record?.eventSlotReservation.time).toLocaleString(DateTime.TIME_SIMPLE)} />
// 						<Title level={5}>{t("event.fields.attended")}</Title>
// 						<TextField value={record?.attended ? t("event.fields.attended") : t("event.fields.notAttended")} />
// 					</Card>
// 					<Card title={t("event.event")} className="flex-1 w-full md:w-1/2 xl:w-1/3 min-w-1/2">
// 						<Title level={5}>{"ID"}</Title>
// 						<NumberField value={record?.id ?? ""} />
// 						<Title level={5}>{t("shop.fields.location.address")}</Title>
// 						<TextField value={record?.event?.shop?.location?.address + ", " + record?.event?.shop?.location?.city + " " + record?.event?.shop?.location?.postalCode + ", " + record?.event?.shop?.location?.country} />
// 						<TextField value={record?.user?.firstName ?? ""} />
// 						<Title level={5}>{t("event.fields.date")}</Title>
// 						<TextField value={DateTime.fromISO(record?.event?.date).toLocaleString(DateTime.DATE_SHORT)} />
// 					</Card>
// 				</div>
// 			</div>
// 		</Show>
// 	);
// };

export const ReservationEdit: React.FC<IResourceComponentsProps> = () => {
	const { formProps, saveButtonProps, formLoading, onFinish } = useForm({});
	const [eventSlotReservationId, setEventSlotReservationId] = useState(formProps.initialValues?.eventSlotReservationId);
	const t = useTranslate();

	const onFormFinish = (values: any) => {
		const { attended, cancelled } = values;


		onFinish({ eventSlotReservationId: eventSlotReservationId, attended: attended, cancelled: cancelled });
	}



	return (
		<Edit saveButtonProps={{
			...saveButtonProps,
			style: {
				backgroundColor: "#EDA0D8",
			},
		}} isLoading={formLoading} contentProps={{
			style: {
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
			},

		}}>
			<Form {...formProps} layout="vertical" onFinish={onFormFinish}>
				<Form.Item label={t("users.fields.lastName")} name="user.lastName" initialValue={formProps.initialValues?.user?.lastName}>
					<Input disabled />
				</Form.Item>
				<Form.Item label={t("users.fields.firstName")} name="user.firstName" initialValue={formProps.initialValues?.user?.firstName}>
					<Input disabled />
				</Form.Item>
				<Form.Item label={t("event.fields.time")} name="eventSlotReservationId" initialValue={DateTime.fromISO(formProps.initialValues?.eventSlotReservation.time).toLocaleString(DateTime.TIME_SIMPLE)}>
					<Select
						onChange={(value) => setEventSlotReservationId(value)}
						options={formProps.initialValues?.event?.slots.map((slot: any) => {
							return {
								label: DateTime.fromISO(slot.time).toLocaleString(DateTime.TIME_SIMPLE),
								value: slot.id,
							}
						})}
					/>

				</Form.Item>
				<Form.Item label={t("event.fields.attended")} name="attended" initialValue={formProps.initialValues?.attended}>
					<Select
						options={[
							{
								label: t("event.fields.attended"),
								value: true,
							},
							{
								label: t("event.fields.notAttended"),
								value: false,
							},
						]}
					/>
				</Form.Item>
				<Form.Item label={t("event.fields.cancelled")} name="cancelled" initialValue={formProps.initialValues?.cancelled}>
					<Select
						options={[
							{
								label: t("event.fields.cancelled"),
								value: true,
							},
							{
								label: t("event.fields.notCancelled"),
								value: false,
							},
						]}
					/>
				</Form.Item>

			</Form>
		</Edit >
	);
};
