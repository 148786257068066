import { ImageField, NumberField, TextField } from "@refinedev/antd";
import { IResourceComponentsProps, useNavigation, useShow, useTranslate } from "@refinedev/core";
import { Button, Card, Divider, Typography } from "antd";
import React from "react";
import { Show } from "../../components/crud/show";
import { EuroCircleOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons";

const { Title } = Typography;


export const PartnersShow: React.FC<IResourceComponentsProps> = () => {
	const { queryResult } = useShow({});
	const { data, isLoading } = queryResult;
	const { show } = useNavigation();
	const t = useTranslate();
	const record = data?.data;

	const handleOfferRedirect = (id: number) => {
		show("offers", id);
	}

	return (
		<Show isLoading={isLoading}>
			<Title level={5}>{"ID"}</Title>
			<NumberField value={record?.id ?? ""} disabled />
			<Title level={5}>{t("partner.fields.socialReason")}</Title>
			<TextField value={record?.socialReason} />
			<Title level={5}>{t("partner.fields.location.address")}</Title>
			<TextField value={record?.location?.address + ", " + record?.location?.city + " " + record?.location?.postalCode + ", " + record?.location?.country} />
			<Title level={5}>{t("partner.fields.logo")}</Title>
			<ImageField value={record?.logo} className="w-10 h-10" height={100} width={100} />
			<Title level={5}>{t("partner.fields.offers")}</Title>
			<div className="flex flex-row gap-4">
				{
					record?.offers?.map((offer: any) => (
						<Card title={offer.name}>
							<Title level={5}>{"ID"}</Title>
							<NumberField value={offer.id} />
							<Title level={5}>{t("offers.fields.price")}</Title>
							<NumberField value={offer.price} />
							<Title level={5}>{t("offers.fields.multiple_use")}</Title>
							<TextField value={offer.multipleUse ? t("offers.fields.true") : t("offers.fields.false")} />
							<Title level={5}>{t("offers.fields.type")}</Title>
							<TextField value={offer.type} />
							<Divider />
							<Button onClick={() => handleOfferRedirect(offer.id)} type="text" icon={<EuroCircleOutlined />} className="cursor-pointer mb-4">{t("offers.actions.see")}</Button>
						</Card>
					))
				}
			</div>
		</Show>
	);
};
