import { NumberField, TextField } from "@refinedev/antd";
import { IResourceComponentsProps, useList, useMany, useNavigation, useNotification, useShow, useTranslate, useUpdate } from "@refinedev/core";
import { Button, Card, Modal, Typography, Statistic, Row, Col } from "antd";
import React, { useCallback } from "react";
import { Show } from "../../components/crud/show";
import { QrcodeOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons";
import { Scanner } from "@yudiel/react-qr-scanner";
import moment from "moment";
import { DateTime } from "luxon";

const { Title } = Typography;

export const EventShow: React.FC<IResourceComponentsProps> = () => {
	const { queryResult } = useShow({});
	const { data, isLoading } = queryResult;
	const [modalVisible, setModalVisible] = React.useState(false);
	const record = data?.data;

	const t = useTranslate();
	const { show } = useNavigation();
	const handleShopRedirect = () => {
		show("shops", record?.shop?.id);
	}

	const { data: reservationsData } = useList({
		resource: "reservations",
		filters: [
			{
				field: "eventId",
				operator: "eq",
				value: record?.id,
			}
		],
	});



	// [
	// 	{
	// 		"id": 3,
	// 		"userId": 2,
	// 		"eventId": 3,
	// 		"eventSlotReservationId": 6,
	// 		"attended": true,
	// 		"eventSlotReservation": {
	// 			"id": 6,
	// 			"eventId": 3,
	// 			"time": "2024-03-30T12:20:00.000Z",
	// 			"remainingSlots": 19
	// 		},
	// 		"user": {
	// 			"firstName": "John",
	// 			"lastName": "Doe",
	// 			"email": "tirrierd+b@gmail.com",
	// 			"phoneNumber": "1234567890",
	// 			"id": 2
	// 		},
	// 		"event": {
	// 			"id": 3,
	// 			"shopId": 2,
	// 			"piecesLimit": 2,
	// 			"date": "2024-03-29T15:00:00.000Z",
	// 			"shop": {
	// 				"id": 2,
	// 				"name": "Chez mamie",
	// 				"description": "On est bien ici",
	// 				"locationId": 9,
	// 				"location": {
	// 					"id": 9,
	// 					"city": "Casablanca",
	// 					"country": "Morocco",
	// 					"address": "Rue des Charmilles 20",
	// 					"postalCode": "20410",
	// 					"latitude": 33.553201,
	// 					"longitude": -7.637375
	// 				}
	// 			}
	// 		}
	// 	}
	// ]

	const { mutateAsync } = useUpdate();

	const { open, close } = useNotification()

	const onResult = async (result: any) => {


		try {
			await mutateAsync({
				resource: "reservations/" + result + "/attend/" + record?.id,
				id: "",
				values: {},
				mutationMode: "pessimistic",
			});

		} catch (error) {

		}
	}

	const isEventFinished = record?.date && moment(record.date).isBefore(moment());

	const totalRegistered = reservationsData?.data?.length ?? 0;
	const totalAttended = reservationsData?.data?.filter(reservation => reservation.attended).length ?? 0;
	const totalNotAttended = totalRegistered - totalAttended;

	return (
		<Show isLoading={isLoading}>
			{isEventFinished && (
				<div className="event-statistics mb-8">
					<Title level={4}>{t("event.statistics.title")}</Title>
					<Row gutter={16}>
						<Col span={8}>
							<Statistic title={t("event.statistics.totalRegistered")} value={totalRegistered} />
						</Col>
						<Col span={8}>
							<Statistic title={t("event.statistics.totalAttended")} value={totalAttended} />
						</Col>
						<Col span={8}>
							<Statistic title={t("event.statistics.totalNotAttended")} value={totalNotAttended} />
						</Col>
					</Row>
				</div>
			)}

			<div className="flex flex-row gap-4 my-4">
				<Button
					style={{ backgroundColor: "#EDA0D8", color: 'white' }}
					className="flex items-center p-4 cursor-pointer"
					type="text" onClick={handleShopRedirect} icon={<ShopOutlined />}>{t("event.action.seeShop")}</Button>
				<Button
					onClick={() => setModalVisible(true)}
					style={{ backgroundColor: "#EDA0D8", color: 'white' }}
					className="flex items-center p-4 cursor-pointer">
					<QrcodeOutlined /> {t("actions.scan")}</Button >
			</div>
			<Title level={5}>{t("event.fields.location.address")}</Title>
			<TextField value={record?.shop?.location?.address + ", " + record?.shop?.location?.city + " " + record?.shop?.location?.postalCode + ", " + record?.shop?.location?.country} />
			<Title level={5}>{"ID"}</Title>
			<NumberField value={record?.id ?? ""} />
			<Title level={5}>{t("event.fields.date")}</Title>
			<TextField value={record?.date} />
			<Title level={5}>{t("event.fields.slots.title")}</Title>
			<div className="flex flex-row gap-4">
				{
					record?.slots?.map((slot: any) => (
						<Card title={t("event.fields.slots.id") + " " + slot.id} key={slot.id}>
							<Title level={5}>{t("event.fields.time")}</Title>
							<TextField value={DateTime.fromISO(slot.time).toLocaleString(DateTime.TIME_SIMPLE)} />
							<Title level={5}>{t("event.fields.remainingSlots")}</Title>
							<TextField value={slot.remainingSlots} />
						</Card>
					))
				}
			</div>

			<Modal
				title={t("actions.scan")}
				open={modalVisible}
				onCancel={() => setModalVisible(false)}
				footer={null}
			>
				<Scanner
					options={{
						delayBetweenScanSuccess: 1000,
						constraints: {
							facingMode: 'environment'
						},
					}}
					onResult={(result: any) => {
						onResult(result);
					}}
					onError={(error: any) => {

						open?.({
							type: "error",
							message: t("actions.scan"),
							description: t("actions.scanError"),
							key: "scanned",
						})
						close?.("scanned");

					}}
				/>
			</Modal>

		</Show>
	);
};
