import {
	DeleteButton,
	EditButton,
	List,
	ShowButton,
	useTable,
} from "@refinedev/antd";
import { BaseRecord, CrudFilters, HttpError, IResourceComponentsProps, useList, useTranslate, useUpdate, useNavigation, useNotification } from "@refinedev/core";
import { Button, Card, Form, FormProps, Input, Modal, Select, Space, Table, Tag } from "antd";
import React, { useCallback, useState } from "react";
import { DateTime } from "luxon";
import { QrcodeOutlined } from "@ant-design/icons";
import { Scanner } from '@yudiel/react-qr-scanner';


const Filter: React.FC<{ formProps: FormProps, t: any }> = ({ formProps, t }) => {
	return (
		<Form layout="vertical" {...formProps}>
			<div>
				<div>
					<Form.Item name={"firstName"} label={t("users.fields.firstName")}>
						<Input placeholder={t("users.fields.firstName")} />
					</Form.Item>
					<Form.Item name={"lastName"} label={t("users.fields.lastName")}>
						<Input placeholder={t("users.fields.lastName")} />
					</Form.Item>
					<Form.Item name={"email"} label={t("users.fields.email")}>
						<Input placeholder={t("users.fields.email")} />
					</Form.Item>
				</div>
			</div>
			<Form.Item>
				<Button htmlType="submit">
					{t("actions.filter")}
				</Button>
				<Button htmlType="submit" onClick={() => formProps?.form?.resetFields()}>
					{t("actions.reset")}
				</Button>
			</Form.Item>
		</Form>
	);
}



export const DevicesList = () => {
	const t = useTranslate();

	const { tableProps, searchFormProps } = useTable<
		any,
		HttpError,
		any
	>({
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { q, firstName, lastName } = params;

			filters.push(
				{
					field: "firstName",
					operator: "eq",
					value: firstName,
				},
				{
					field: "lastName",
					operator: "eq",
					value: lastName,
				},
				{
					field: "email",
					operator: "eq",
					value: q,
				}
			);

			return filters;
		},
	});

	return (
		<List>
			<Card title={t("actions.filter")}>
				<Filter formProps={searchFormProps} t={t} />
			</Card>
			<Table {...tableProps} rowKey="id">
				<Table.Column dataIndex="uniqueDeviceId" title={t("devices.fields.uniqueDeviceId")} />
				<Table.Column dataIndex="user" title={t("devices.fields.user")} render={(user: any) => {
					return user?.firstName + " " + user?.lastName;
				}} />
				<Table.Column dataIndex="manufacturer" title={t("devices.fields.manufacturer")} />
				<Table.Column dataIndex="model" title={t("devices.fields.model")} />
				<Table.Column dataIndex="systemName" title={t("devices.fields.systemName")} />
				<Table.Column dataIndex="systemVersion" title={t("devices.fields.systemVersion")} />
				<Table.Column dataIndex="mapKitTokenCount" title={t("devices.fields.mapKitTokenCount")} />
				<Table.Column dataIndex="pushToken" title={t("devices.fields.pushToken")} render={(pushToken: string) => {
					// allow user to copy with click on tag
					const copyToClipboard = () => {
						navigator.clipboard.writeText(pushToken);
					};
					if (!pushToken) {
						return (
							<Tag color="red">X</Tag>
						);
					}
					return (
						<Tag onClick={copyToClipboard} color="blue">{pushToken?.substring(0, 10) + "..."}</Tag>
					);
				}} />
				<Table.Column
					title={"Actions"}
					dataIndex="actions"
					render={(_, record: BaseRecord) => (
						<Space>
							<ShowButton hideText size="small" recordItemId={record.id} />
							{/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
