import {
	DeleteButton,
	EditButton,
	List,
	ShowButton,
	useTable,
} from "@refinedev/antd";
import { BaseRecord, CrudFilters, HttpError, IResourceComponentsProps, useList, useTranslate } from "@refinedev/core";
import { Button, Card, Form, FormProps, Input, Select, Space, Table } from "antd";
import { DateTime } from "luxon";
import React from "react";

export const PartnersList: React.FC<IResourceComponentsProps> = () => {
	const { tableProps } = useTable({
		syncWithLocation: true,
	});

	const t = useTranslate();

	return (
		<List createButtonProps={{
			style: {
				backgroundColor: "#EDA0D8",
			},
			title: t("actions.create"),
			resource: "partner",
			hideText: true,
		}}>
			<Table {...tableProps} rowKey="id">
				<Table.Column dataIndex="id" title={"ID"} />
				<Table.Column dataIndex="socialReason" title={t("partner.fields.socialReason")} />
				<Table.Column render={(value: any) => value?.location?.address + ", " + value?.location?.city + ", " + value?.location?.postalCode} title={t("partner.fields.location.address")} />
				<Table.Column render={(value: any) => value?.offers?.length} title={t("partner.fields.offers")} />
				<Table.Column
					title={"Actions"}
					dataIndex="actions"
					render={(_, record: BaseRecord) => (
						<Space>
							<EditButton hideText size="small" recordItemId={record.id} />
							<ShowButton hideText size="small" recordItemId={record.id} />
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
