import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const InventoryAvailabilityGraph = ({ data }: { data: any[] | undefined }) => {
	return (
		<ResponsiveContainer width="100%" height={400}>
			<LineChart
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="date" />
				<YAxis allowDecimals={false} />
				<Tooltip />
				<Legend />
				<Line type="monotone" dataKey="count" stroke="#8884d8" name="Available Items" activeDot={{ r: 8 }} />
			</LineChart>
		</ResponsiveContainer>
	);
};

export default InventoryAvailabilityGraph;
