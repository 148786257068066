import { useState } from 'react';
import { Form, Button, Image } from 'antd';
import { useTranslate, useUpdatePassword, useNotification, useNavigation, UpdatePasswordFormTypes } from "@refinedev/core";

export const ResetPassword = () => {
	const [form] = Form.useForm();
	const t = useTranslate();
	const { mutate: updatePassword, isLoading } = useUpdatePassword();
	const { open } = useNotification();
	const navigate = useNavigation();

	const handleSubmit = (values: any) => {
		if (values.newPassword !== values.confirmPassword) {
			open?.({
				type: "error",
				message: t("pages.resetPassword.errors.matchPassword"),
			});
			return;
		}

		const token = new URLSearchParams(window.location.search).get("token");
		if (!token) {
			open?.({
				type: "error",
				message: t("pages.resetPassword.errors.noToken"),
			});
			return;
		}

		updatePassword(
			{
				password: values.newPassword,
				token: token // Passing the token to the backend via the updatePassword call
			} as UpdatePasswordFormTypes & { token: string },
			{
				onSuccess: (data) => {
					if (data.success) {
						open?.({
							type: "success",
							message: t("pages.resetPassword.messages.passwordUpdated"),
						});
						navigate?.push(data.redirectTo || '/login'); // Redirect on successful password reset
					} else {
						open?.({
							type: "error",
							message: data.error?.message || t("pages.resetPassword.errors.updateFailed"),
						});
					}
				},
				onError: (error) => {
					open?.({
						type: "error",
						message: error.message || t("pages.resetPassword.errors.updateFailed"),
					});
				}
			}
		);
	};

	return (
		<div className="flex justify-center items-center h-screen bg-primary">
			<div className="w-96">
				<div className="bg-white p-6 rounded-lg shadow-lg">
					<div className="flex justify-center mb-8">
						<Image
							width={200}
							src="https://equipagesolidaire.fr/assets/dressed_logo-8c603e5e.jpeg"
						/>
					</div>

					<Form
						form={form}
						onFinish={handleSubmit}
					>

						<Form.Item
							labelCol={{ span: 24 }}
							label={t("pages.resetPassword.fields.newPassword")}
							name="newPassword"
							rules={[
								{
									required: true,
									message: t("pages.resetPassword.errors.validPassword"),
								},
							]}
						>
							<input
								className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none"
								type="password"
								placeholder={t("pages.resetPassword.fields.newPassword")}
							/>
						</Form.Item>

						<Form.Item
							labelCol={{ span: 24 }}
							label={t("pages.resetPassword.fields.confirmPassword")}
							name="confirmPassword"
							rules={[
								{
									required: true,
									message: t("pages.resetPassword.errors.matchPassword"),
								},
							]}
						>
							<input
								className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none"
								type="password"
								placeholder={t("pages.resetPassword.fields.confirmPassword")}
							/>
						</Form.Item>

						<div className="mb-4">
							<Button
								onClick={() => form.submit()}
								loading={isLoading}
								className="w-full bg-primary text-white rounded-lg font-semibold text-center">
								{t("pages.resetPassword.buttons.resetPassword")}
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
};
