import { TextField, Show } from "@refinedev/antd";
import { useShow, useTranslate } from "@refinedev/core";
import { Card, Descriptions, Tag } from "antd";
import React from "react";
import { DateTime } from "luxon";

export const NotificationsShow = () => {
	const { queryResult } = useShow();
	const { data, isLoading } = queryResult;

	const record = data?.data;
	const t = useTranslate();

	return (
		<Show isLoading={isLoading}>
			<Card title={t("notifications.fields.details")}>
				<Descriptions column={1} bordered>
					<Descriptions.Item label={t("notifications.fields.title")}>
						<TextField value={record?.title} />
					</Descriptions.Item>
					<Descriptions.Item label={t("notifications.fields.body")}>
						<TextField value={record?.body} />
					</Descriptions.Item>
					<Descriptions.Item label={t("notifications.fields.method")}>
						<TextField value={record?.contactMethod} />
					</Descriptions.Item>
					<Descriptions.Item label={t("notifications.fields.read")}>
						<Tag color={record?.read ? "green" : "red"}>
							{record?.read ? t("notifications.fields.read") : t("notifications.fields.unread")}
						</Tag>
					</Descriptions.Item>
					<Descriptions.Item label={t("notifications.fields.sender")}>
						<TextField value={record?.senderId} />
					</Descriptions.Item>
					<Descriptions.Item label={t("notifications.fields.receiver")}>
						<TextField value={record?.receiverId} />
					</Descriptions.Item>
					<Descriptions.Item label={t("notifications.fields.createdAt")}>
						<TextField value={DateTime.fromISO(record?.createdAt).toLocaleString(DateTime.DATETIME_MED)} />
					</Descriptions.Item>
					<Descriptions.Item label={t("notifications.fields.updatedAt")}>
						<TextField value={DateTime.fromISO(record?.updatedAt).toLocaleString(DateTime.DATETIME_MED)} />
					</Descriptions.Item>
				</Descriptions>
			</Card>
		</Show>
	);
};
