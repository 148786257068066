import { Edit, getValueFromEvent, useForm, ImageField } from "@refinedev/antd";
import { IResourceComponentsProps, useCustom, useList, useTranslate, file2Base64 } from "@refinedev/core";
import { AutoComplete, Button, Card, DatePicker, Form, Input, InputNumber, Select, TimePicker, Tooltip, Typography, message, Upload } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import type { GetProp, UploadProps } from 'antd';
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

export const OffersEdit: React.FC<IResourceComponentsProps> = () => {
	const { formProps, saveButtonProps, onFinish } = useForm({});
	const [isDigital, setIsDigital] = useState<string>(formProps?.initialValues?.type);

	const onFormFinish = async (values: any) => {

		onFinish(values);
	}

	const t = useTranslate();

	return (
		<Edit saveButtonProps={{
			...saveButtonProps,
			style: {
				backgroundColor: "#EDA0D8",
			},

		}}>
			<Form {...formProps} layout="vertical" onFinish={onFormFinish}>
				<Form.Item label={"ID"} name="id">
					<Input disabled />
				</Form.Item>
				<Form.Item label={t("partner.fields.socialReason")} name={["partner", "socialReason"]}>
					<Input disabled />
				</Form.Item>
				<Form.Item label={t("offers.fields.name")} name="name" rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item label={t("offers.fields.price")} name="price" rules={[{ required: true }]}>
					<InputNumber />
				</Form.Item>
				<Form.Item label={t("offers.fields.multiple_use")} name="multipleUse" initialValue={formProps?.initialValues?.multipleUse} rules={[{ required: true }]}>
					<Select>
						<Select.Option value={true}>{t("actions.yes")}</Select.Option>
						<Select.Option value={false}>{t("actions.no")}</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label={t("offers.fields.type")} name="type" rules={[{ required: true }]}>
					<Select onSelect={(value) => setIsDigital(value)}>
						<Select.Option value="PHYSICAL">{t("offers.fields.physical")}</Select.Option>
						<Select.Option value="DIGITAL">{t("offers.fields.digital")}</Select.Option>
					</Select>
				</Form.Item>
				{
					isDigital === "DIGITAL" && (
						<Form.Item label={t("offers.fields.code")} name="digitalCode" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
					)
				}

			</Form>
		</Edit >
	);
};
