type MarkerData = {
	id: string;
	latitude: number;
	longitude: number;
	pickupDaysPreference: string; // Exemple: "Lundi, Mercredi, Vendredi"
	pickupHoursPreference: string; // Exemple: "14h-16h"
};

type MapWithMarkersProps = {
	mapKitJSToken: string;
	markers: MarkerData[];
	onMarkerSelect: (marker: string) => void;
	selectedMarkersIds: string[];
	focusCoords?: { latitude: number; longitude: number };
};

import React, { useEffect, useRef } from 'react';

const MapWithMarkers = ({ mapKitJSToken, markers, onMarkerSelect, selectedMarkersIds, focusCoords }: MapWithMarkersProps) => {
	const mapContainerRef = useRef<HTMLDivElement>(null);
	const mapRef = useRef<any>(null); // Stocker la référence de la carte

	useEffect(() => {
		if (!window.mapkit) return;

		const initMapKit = () => {
			window.mapkit.init({
				authorizationCallback: (done: (token: string) => void) => {
					done(mapKitJSToken);
				},
			});
		};

		initMapKit();

		const setupMap = () => {
			if (mapContainerRef.current && !mapRef.current) {
				mapRef.current = new window.mapkit.Map(mapContainerRef.current);

				// Focaliser sur la France
				const franceCenter = new window.mapkit.Coordinate(47.081012, 2.398782);
				const region = new window.mapkit.CoordinateRegion(
					franceCenter,
					new window.mapkit.CoordinateSpan(8, 8)
				);
				mapRef.current.region = region;
			}
		};

		setupMap();
		createAnnotation(markers);
	}, [mapKitJSToken]);

	const createAnnotation = (markers: MarkerData[]) => {
		if (!mapRef.current) return;

		// Nettoyer les anciennes annotations
		mapRef.current.removeAnnotations(mapRef.current.annotations);

		// Ajouter de nouvelles annotations avec les couleurs mises à jour
		const offset = 0.0003;
		// if a marker has the exact same coordinates as another, it will be hidden behind the other, so we add a small offset if markers have the same coordinates
		markers.forEach((marker, index) => {
			if (markers.findIndex(m => m.latitude === marker.latitude && m.longitude === marker.longitude) !== index) {
				marker.latitude += offset;
				marker.longitude += offset;
			}
		});

		markers.forEach(marker => {

			const location = new window.mapkit.Coordinate(marker.latitude, marker.longitude);
			const isSelected = selectedMarkersIds.includes(marker.id);
			const annotation = new window.mapkit.MarkerAnnotation(location, {
				title: marker.id.toString() + " - " + marker.pickupDaysPreference + " - " + marker.pickupHoursPreference,
				color: isSelected ? "red" : "#4eabe9",
				glyphText: "\u{1F4CD}",
			});

			annotation.addEventListener('select', () => onMarkerSelect(marker.id));
			mapRef.current.addAnnotation(annotation);
		});
	}

	useEffect(() => {
		if (!mapRef.current) return;

		createAnnotation(markers);
	}, [markers, selectedMarkersIds]);

	useEffect(() => {
		if (!mapRef.current || !focusCoords) return;

		const center = new window.mapkit.Coordinate(focusCoords.latitude, focusCoords.longitude);
		mapRef.current.setCenterAnimated(center);
	}, [focusCoords]);

	return (
		<div
			id="map-with-markers-container"
			ref={mapContainerRef}
			style={{
				height: '50vh',
				width: '100%',
			}}
		/>
	);
};

export default MapWithMarkers;
