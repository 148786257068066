import { useMany, CrudFilters, HttpError, IResourceComponentsProps, BaseRecord, useTranslate, useList } from "@refinedev/core";

import {
	List,
	TextField,
	useTable,
	EditButton,
	ShowButton,
	useSelect,
	DateField,
	DeleteButton,
} from "@refinedev/antd";

import { SearchOutlined } from "@ant-design/icons";

import {
	Table,
	Space,
	Form,
	Input,
	Button,
	DatePicker,
	Card,
	Select,
	Tag,
	FormProps,
	Row,
	Col,
	Image,
} from "antd";
import React from "react";
import { DateTime } from "luxon";

export const PickupList: React.FC<IResourceComponentsProps> = () => {
	const t = useTranslate();
	const { tableProps, searchFormProps } = useTable<
		any,
		HttpError,
		any
	>({
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { q, city, status, createdAt } = params;

			filters.push(
				{
					field: "status",
					operator: "eq",
					value: status,
				},
				{
					field: "city",
					operator: "eq",
					value: city,
				},
			);

			return filters;
		},
	});


	return (
		<List createButtonProps={{
			style: {
				backgroundColor: "#EDA0D8",
				color: "white",
			},
			title: t("actions.create"),
			accessControl: {
				enabled: true,
			},
			resource: "pickup",
		}}>
			<Table {...tableProps} rowKey="id">
				<Table.Column dataIndex="id" title={"ID"} />
				<Table.Column
					dataIndex="status"
					title={t("pickup.fields.status.title")}
					render={(value) => {
						let color;
						switch (value) {
							case "COMPLETED":
								color = "green";
								break;
							case "IN_PROGRESS":
								color = "blue";
								break;
							case "PENDING":
								color = "orange";
								break;
							default:
								color = "";
								break;
						}
						return <Tag color={color}>{t(`pickup.fields.status.${value}`).toUpperCase()}</Tag>;
					}}
				/>
				<Table.Column dataIndex="user" title={t("pickup.fields.deliveryMan")} render={(value: any) => value?.firstName + " " + value?.lastName} />
				<Table.Column dataIndex="pickupWeek" title={t("pickup.fields.pickupWeek")} render={(value: any) => {

					const weekFormatter = (week: string) => {
						const [year, weekPart] = week?.split('-');
						const weekNumber = parseInt(weekPart.replace('th', ''), 10);

						// Calculate the first day of the first week of the year
						let firstDayOfYear = DateTime.fromISO(`${year}-01-01`);
						// Adjust to the first Monday of the year, assuming weeks start on Monday
						if (firstDayOfYear.weekday !== 1) {
							firstDayOfYear = firstDayOfYear.plus({ days: 8 - firstDayOfYear.weekday });
						}

						// Calculate the start and end of the target week
						const startOfWeek = firstDayOfYear.plus({ weeks: weekNumber - 1 });
						const endOfWeek = startOfWeek.plus({ days: 6 });

						// Now you can format these dates as needed
						return `${startOfWeek.toFormat('yyyy-MM-dd')} - ${endOfWeek.toFormat('yyyy-MM-dd')}`;

					}

					return value ? weekFormatter(value) : ""
				}
				} />
				<Table.Column dataIndex="donations" title={t("pickup.fields.numberOfDonations")} render={(value: any) => value?.length} />
				<Table.Column dataIndex="createdAt" title={t("pickup.fields.createdAt")} render={(value) => <DateField value={value} />} />

				<Table.Column
					title={"Actions"}
					dataIndex="actions"
					render={(_, record: BaseRecord) => (
						<Space>
							<ShowButton hideText size="small" recordItemId={record.id} />
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
