import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import {
	DeleteButton,
	EditButton,
	List,
	useTable,
} from "@refinedev/antd";
import {
	BaseRecord, CrudFilters, HttpError, IResourceComponentsProps,
	useUpdate,
	useList,
	useTranslate,
	useInvalidate
} from "@refinedev/core";
import { AutoComplete, Button, Card, Form, FormProps, InputNumber, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState, useRef, ReactNode } from "react";

interface Category {
	id: number;
	code: string;
	pointsValue: number;
}

interface EditableCellProps {
	title: ReactNode;
	editable: boolean;
	children: ReactNode;
	dataIndex: keyof Category;
	record: Category;
	handleSave: (record: Category) => Promise<void>;
}

const Filter: React.FC<{ formProps: FormProps; t: any }> = ({ formProps, t }) => {
	const { data: categoriesData } = useList<Category>({
		resource: "categories",
		pagination: {
			pageSize: 1000,
		},
	});

	const [categories, setCategories] = useState<Category[]>(categoriesData?.data || []);

	useEffect(() => {
		setCategories(categoriesData?.data || []);
	}, [categoriesData]);

	return (
		<Form layout="vertical" {...formProps}>
			<div>
				<div>
					<Form.Item name={"code"} label={t("donation.fields.category")}>
						<AutoComplete
							options={categories.map((category) => ({
								label: t(`category.categories.${category.code}`),
								value: category.code,
							}))}
							placeholder={t("donation.fields.category")}
							filterOption={(inputValue, option) =>
								option?.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
							}
						/>
					</Form.Item>
				</div>
			</div>
			<Form.Item>
				<Button htmlType="submit">
					{t("actions.filter")}
				</Button>
				<Button htmlType="submit" onClick={() => formProps?.form?.resetFields()} className="ml-2">
					{t("actions.reset")}
				</Button>
			</Form.Item>
		</Form>
	);
};

const EditableCell: React.FC<EditableCellProps> = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const [value, setValue] = useState<number | string | undefined>(record ? record[dataIndex] : undefined);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (editing && inputRef.current) {
			inputRef.current.focus();
		}
	}, [editing]);

	const toggleEdit = () => {
		setEditing(!editing);
		setValue(record[dataIndex]);
	};

	const save = async () => {
		try {
			if (value !== undefined) {
				await handleSave({ ...record, [dataIndex]: value });
				toggleEdit();
			}
		} catch (errInfo) {
			console.log('Save failed:', errInfo);
		}
	};

	let childNode = children;

	if (editable) {
		childNode = editing ? (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<InputNumber
					type="number"
					ref={inputRef as unknown as React.RefObject<HTMLInputElement>}
					value={typeof value === 'number' ? value : 0}
					onChange={(val) => setValue(val as number)}
					onPressEnter={save}
				/>
				<Button onClick={save} type="primary" size="small" style={{ marginLeft: 8 }}>
					<CheckOutlined />
				</Button>
			</div>
		) : (
			<Tooltip title="Click to edit">
				<div
					className="editable-cell-value-wrap"
					style={{ paddingRight: 24, cursor: 'pointer', borderBottom: '1px dashed #d9d9d9' }}
					onClick={toggleEdit}
				>
					{children}
					<EditOutlined style={{ marginLeft: 8, color: '#FF0000' }} />
				</div>
			</Tooltip>
		);
	}

	return <td {...restProps}>{childNode}</td>;
};

export const CategoryList: React.FC<IResourceComponentsProps> = () => {
	const { tableProps, searchFormProps, tableQueryResult } = useTable<Category, HttpError, any>({
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { code } = params;

			filters.push({
				field: "code",
				operator: "eq",
				value: code,
			});

			return filters;
		},
	});

	const t = useTranslate();
	const { mutate } = useUpdate<Category>();
	const invalidate = useInvalidate();

	const handleSave = async (record: Category) => {
		mutate({
			resource: "categories",
			id: record.id,
			values: { pointsValue: record.pointsValue },
		}, {
			onSuccess: () => {
				invalidate({
					resource: "categories",
					invalidates: ["list"]
				});
				tableQueryResult.refetch();  // Refetch the data after update
			}
		});
	};

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: t("category.fields.code"),
			dataIndex: "code",
			render: (code: string) => t(`category.categories.${code}`),
		},
		{
			title: t("category.fields.pointsValue"),
			dataIndex: "pointsValue",
			editable: true,
		},
		{
			title: "Actions",
			dataIndex: "actions",
			render: (_: any, record: BaseRecord) => (
				<Space>
					<EditButton hideText size="small" recordItemId={record.id} />
					<DeleteButton hideText size="small" recordItemId={record.id} />
				</Space>
			),
		},
	];

	const mergedColumns = columns.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: Category) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: handleSave,
			}),
		};
	});

	return (
		<List>
			<Card title={t("actions.filter")}>
				<Filter formProps={searchFormProps} t={t} />
			</Card>
			<Table
				{...tableProps}
				rowKey="id"
				components={{
					body: {
						cell: EditableCell,
					},
				}}
				columns={mergedColumns}
			/>
		</List>
	);
};
