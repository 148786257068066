import React from 'react';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';

const DonationTimeGraph = ({ data }: { data: any[] | undefined }) => {
	const allStatuses = ["DRAFT", "TO_REVIEW", "ACCEPTED", "REFUSED", "TO_PICKUP", "PICKED_UP", "IN_PROGRESS", "COMPLETED"];
	const allDates = [...new Set(data?.map(d => d.date))];

	const completeData = allDates.map(date => {
		const dateEntries = data?.filter(d => d.date === date);
		const dateEntry: { date: any, [key: string]: any } = { date };

		allStatuses.forEach(status => {
			const entry = dateEntries?.find(d => d.countsByStatus[status] !== undefined);
			dateEntry[status] = entry ? entry.countsByStatus[status] : 0;
		});

		return dateEntry;
	});

	// Préparation des données pour Recharts
	const formattedData = data?.map(entry => {
		const { date, countsByStatus } = entry;
		return {
			date,
			...countsByStatus,
		};
	});

	const generateColor = (index: number) => {
		const hue = index * 137.508; // Utilisez l'or en séquence pour la dispersion
		return `hsl(${hue % 360}, 50%, 50%)`;
	};

	return (
		<ResponsiveContainer width="100%" height={400}>
			<LineChart
				data={completeData}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="date" />
				<YAxis allowDecimals={false} />
				<Tooltip />
				<Legend />
				{allStatuses.map((status, index) => (
					<Line
						key={status}
						type="monotone"
						dataKey={status}
						stroke={generateColor(index)}
						name={status.replace('_', ' ')} // Beautify status for legend
						activeDot={{ r: 8 }}
					/>
				))}
			</LineChart>
		</ResponsiveContainer>
	);
};

export default DonationTimeGraph;
