import {
	DeleteButton,
	List,
	ShowButton,
	useTable,
} from "@refinedev/antd";
import { BaseRecord, CrudFilters, HttpError, useTranslate } from "@refinedev/core";
import { Button, Card, Form, FormProps, Input, Space, Table, Tag } from "antd";
import { DateTime } from "luxon";
import React from "react";

const Filter: React.FC<{ formProps: FormProps, t: any }> = ({ formProps, t }) => {
	return (
		<Form layout="vertical" {...formProps}>
			<Form.Item name={"title"} label={t("notifications.fields.title")}>
				<Input placeholder={t("notifications.fields.title")} />
			</Form.Item>
			<Form.Item>
				<Button htmlType="submit">
					{t("actions.filter")}
				</Button>
				<Button htmlType="button" onClick={() => formProps?.form?.resetFields()}>
					{t("actions.reset")}
				</Button>
			</Form.Item>
		</Form>
	);
}

export const NotificationsList = () => {
	const t = useTranslate();

	const { tableProps, searchFormProps } = useTable<
		BaseRecord,
		HttpError,
		any
	>({
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { title } = params;

			filters.push(
				{
					field: "title",
					operator: "contains",
					value: title,
				}
			);

			return filters;
		},
	});

	return (
		<List createButtonProps={{
			style: {
				backgroundColor: "#EDA0D8",
				color: "white",
			},
			title: t("actions.create"),
			accessControl: {
				enabled: true,
			},
		}}>
			<Card title={t("actions.filter")}>
				<Filter formProps={searchFormProps} t={t} />
			</Card>
			<Table {...tableProps} rowKey="id">
				<Table.Column dataIndex="title" title={t("notifications.fields.title")} />
				<Table.Column dataIndex="body" title={t("notifications.fields.body")} />
				<Table.Column dataIndex="contactMethod" title={t("notifications.fields.method")} />
				<Table.Column dataIndex="read" title={t("notifications.fields.read")} render={(read: boolean) => (
					<Tag color={read ? "green" : "red"}>{read ? t("notifications.fields.read") : t("notifications.fields.unread")}</Tag>
				)} />
				<Table.Column dataIndex="senderId" title={t("notifications.fields.sender")} render={(senderId: string) => {
					if (!senderId) return <Tag color="red">{t("notifications.fields.system")}</Tag>;
				}} />
				<Table.Column dataIndex="receiverId" title={t("notifications.fields.receiver")} />
				<Table.Column dataIndex="createdAt" title={t("notifications.fields.createdAt")} render={(value) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)} />
				<Table.Column
					title={t("actions.actions")}
					dataIndex="actions"
					render={(_, record: BaseRecord) => (
						<Space>
							<ShowButton hideText size="small" recordItemId={record.id} />
							<DeleteButton hideText size="small" recordItemId={record.id} />
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
