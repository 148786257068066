import React from "react";
import { createRoot } from "react-dom/client";
import { ConfigProvider, theme } from "antd";
import App from "./App";
import "./i18n";
const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
import * as Sentry from "@sentry/react";

Sentry.init({
	dsn: "https://556809d0143a18c4e4a57b68a025973f@o4504718776664064.ingest.us.sentry.io/4507016107065344",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

});


root.render(
	<ConfigProvider
		theme={{
			token: {
				// Seed Token
				colorPrimary: '#FF0000',
				borderRadius: 2,
				fontFamily: "Open Sans",


				// Alias Token
				colorBgContainer: 'white',
				colorTextLightSolid: 'white',
			},
			components: {
				Button: {
					colorPrimary: 'red',
				},
			},
		}}>
		<React.Suspense fallback="loading">
			<App />
		</React.Suspense>
	</ConfigProvider>
);
