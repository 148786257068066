import { Edit, useForm } from "@refinedev/antd";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Form, Input } from "antd";
import React from "react";

export const ConditionEdit: React.FC<IResourceComponentsProps> = () => {
	const { formProps, saveButtonProps } = useForm({});
	const t = useTranslate();
	return (
		<Edit saveButtonProps={{
			...saveButtonProps,
			style: {
				backgroundColor: "#EDA0D8",
			},

		}}>
			<Form {...formProps} layout="vertical">
				<Form.Item
					label={"id"}
					name={["id"]}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input disabled />
				</Form.Item>
				<Form.Item
					label={t("condition.fields.name")}
					name={["name"]}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input disabled />
				</Form.Item>
				<Form.Item
					label={t("condition.fields.pointsValue")}
					name={["pointsValue"]}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input type="number" />
				</Form.Item>
			</Form>
		</Edit>
	);
};
