import {
	DeleteButton,
	EditButton,
	List,
	ShowButton,
	useTable,
} from "@refinedev/antd";
import { BaseRecord, IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Space, Table } from "antd";
import React from "react";

export const ConditionList: React.FC<IResourceComponentsProps> = () => {
	const { tableProps } = useTable({
		syncWithLocation: true,
	});

	const t = useTranslate();

	return (
		<List>
			<Table {...tableProps} rowKey="id">
				<Table.Column dataIndex="id" title={"ID"} />
				<Table.Column dataIndex="name" title={t("condition.fields.name")} render={(name: string) => t(`condition.conditions.${name}`)} />
				<Table.Column dataIndex="pointsValue" title={t("condition.fields.pointsValue")} />
				<Table.Column
					title={"Actions"}
					dataIndex="actions"
					render={(_, record: BaseRecord) => (
						<Space>
							<EditButton hideText size="small" recordItemId={record.id} />
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
