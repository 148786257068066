import React from "react";
import {
	useApiUrl,
	useCustom,
	useGetIdentity,
	useNavigation,
	useTranslate,
} from "@refinedev/core";
import {
	Card,
	Col,
	Row,
	Badge,
	Button,
	Typography,
} from "antd";
import {
	BoxPlotOutlined,
	UserAddOutlined,
	AlertOutlined,
	GiftOutlined,
	ClockCircleOutlined,
	FundProjectionScreenOutlined,
} from "@ant-design/icons";
import UsersTimeGraph from "../../components/UsersTimeGraph";
import DonationTimeGraph from "../../components/DonationTimeGraph";
import ReservationsTimeGraph from "../../components/ReservationsTimeGraph";
import InventoryAvailabilityGraph from "../../components/InventoryAvailabilityGraph";

export const DashboardPage: React.FC = () => {
	const t = useTranslate();

	const { list } = useNavigation();
	const { data: identity } = useGetIdentity<any>();

	const { data: KpisData } = useCustom<{
		usersThruTime: any[];
		inventoryItemsToReview: number;
		documentsToReview: number;
		donationsToReview: number;
		donationsThruTime: any[];
		donationsToPickup: number;
		reservationsThruTime: any[];
		inventoryItemsAvailableThruTime: any[];
		totalPotsBalance: number;
		totalOffersClaimed: number;
		totalDonations: number;
		totalReservations: number;
		totalUsers: number;
	}>({
		url: `${import.meta.env.VITE_API_URL}/kpis`,
		method: "get",
	});

	// Action required
	const inventoryItemsToReview = KpisData?.data?.inventoryItemsToReview;
	const donationsToReview = KpisData?.data?.donationsToReview;
	const donationsToPickup = KpisData?.data?.donationsToPickup;
	const documentsToReview = KpisData?.data?.documentsToReview;

	// Information
	const usersThruTime = KpisData?.data?.usersThruTime;
	const donationsThroughTime = KpisData?.data?.donationsThruTime;
	const reservationsThruTime = KpisData?.data?.reservationsThruTime;
	const inventoryItemsAvailableThruTime =
		KpisData?.data?.inventoryItemsAvailableThruTime;
	const totalPotsBalance = KpisData?.data?.totalPotsBalance;
	const totalOffersClaimed = KpisData?.data?.totalOffersClaimed;
	const totalDonations = KpisData?.data?.totalDonations;
	const totalReservations = KpisData?.data?.totalReservations;
	const totalUsers = KpisData?.data?.totalUsers;

	return (
		<div className="p-6 bg-white min-h-screen">
			<Typography.Title level={4}>
				{t("dashboard.welcome")}
			</Typography.Title>
			<Row gutter={[16, 16]}>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Card
						title={t("dashboard.user")}
						className="mb-8"
						bodyStyle={{ padding: "1rem" }}
					>
						<div className="text-center">
							<p className="text-lg font-semibold">
								{identity?.firstName} {identity?.lastName}
							</p>
							<p className="text-gray-600">{identity?.email}</p>
							<p className="text-gray-600">{identity?.phoneNumber}</p>
							<p className="text-gray-600">{identity?.role}</p>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Card
						title={t("dashboard.totalUsers")}
						className="mb-8"
						bodyStyle={{ padding: "1rem", textAlign: "center" }}
					>
						<Typography.Title level={2} className="text-blue-600">
							{totalUsers}
						</Typography.Title>
					</Card>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Badge.Ribbon text={t("dashboard:urgent")} color="#e34958">
						<Card
							title={t("dashboard.inventoryItemsToReview")}
							className="shadow-md"
							bodyStyle={{ padding: "1rem" }}
						>
							<div className="flex items-center justify-between">
								<div className="flex items-center text-2xl">
									<AlertOutlined className="text-red-500 mr-2" />
									{inventoryItemsToReview}
								</div>
								<Button
									type="primary"
									onClick={() => list("inventory")}
								>
									{t("dashboard:review")}
								</Button>
							</div>
						</Card>
					</Badge.Ribbon>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Badge.Ribbon text={t("dashboard:urgent")} color="#e34958">
						<Card
							title={t("dashboard.donationsToReview")}
							className="shadow-md"
							bodyStyle={{ padding: "1rem" }}
						>
							<div className="flex items-center justify-between">
								<div className="flex items-center text-2xl">
									<AlertOutlined className="text-red-500 mr-2" />
									{donationsToReview}
								</div>
								<Button
									type="primary"
									onClick={() => list("donation")}
								>
									{t("dashboard:review")}
								</Button>
							</div>
						</Card>
					</Badge.Ribbon>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Badge.Ribbon text={t("dashboard:urgent")} color="#e34958">
						<Card
							title={t("dashboard.donationsToPickup")}
							className="shadow-md"
							bodyStyle={{ padding: "1rem" }}
						>
							<div className="flex items-center justify-between">
								<div className="flex items-center text-2xl">
									<AlertOutlined className="text-red-500 mr-2" />
									{donationsToPickup}
								</div>
								<Button
									type="primary"
									onClick={() => list("donation")}
								>
									{t("dashboard:review")}
								</Button>
							</div>
						</Card>
					</Badge.Ribbon>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Badge.Ribbon text={t("dashboard:urgent")} color="#e34958">
						<Card
							title={t("dashboard.documentsToReview")}
							className="shadow-md"
							bodyStyle={{ padding: "1rem" }}
						>
							<div className="flex items-center justify-between">
								<div className="flex items-center text-2xl">
									<AlertOutlined className="text-red-500 mr-2" />
									{documentsToReview}
								</div>
								<Button
									type="primary"
									onClick={() => list("documents")}
								>
									{t("dashboard:review")}
								</Button>
							</div>
						</Card>
					</Badge.Ribbon>
				</Col>
			</Row>
			<Typography.Title level={4} className="mt-8">
				{t("dashboard.statistics")}
			</Typography.Title>
			<Row gutter={[16, 16]} className="mt-4">
				<Col xs={24} sm={12} md={8} lg={6}>
					<Card
						title={t("dashboard.totalDonations")}
						className="shadow-lg"
						bodyStyle={{ padding: "1rem", textAlign: "center" }}
						extra={<GiftOutlined className="text-green-500" />}
					>
						<Typography.Title level={2} className="text-green-600">
							{totalDonations}
						</Typography.Title>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Card
						title={t("dashboard.totalReservations")}
						className="shadow-lg"
						bodyStyle={{ padding: "1rem", textAlign: "center" }}
						extra={<ClockCircleOutlined className="text-yellow-500" />}
					>
						<Typography.Title level={2} className="text-yellow-600">
							{totalReservations}
						</Typography.Title>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Card
						title={t("dashboard.totalPotsBalance")}
						className="shadow-lg"
						bodyStyle={{ padding: "1rem", textAlign: "center" }}
						extra={
							<FundProjectionScreenOutlined className="text-blue-500" />
						}
					>
						<Typography.Title level={2} className="text-blue-600">
							{totalPotsBalance}
						</Typography.Title>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Card
						title={t("dashboard.totalOffersClaimed")}
						className="shadow-lg"
						bodyStyle={{ padding: "1rem", textAlign: "center" }}
						extra={<BoxPlotOutlined className="text-purple-500" />}
					>
						<Typography.Title level={2} className="text-purple-600">
							{totalOffersClaimed}
						</Typography.Title>
					</Card>
				</Col>
			</Row>
			<Row gutter={[16, 16]} className="mt-4">
				<Col xs={24} md={12}>
					<Card
						title={t("dashboard.usersThruTime")}
						className="shadow-md h-full"
						extra={<UserAddOutlined className="text-indigo-500" />}
					>
						<UsersTimeGraph data={usersThruTime} />
					</Card>
				</Col>
				<Col xs={24} md={12}>
					<Card
						title={t("dashboard.donationsThruTime")}
						className="shadow-md h-full"
						extra={<GiftOutlined className="text-green-500" />}
					>
						<DonationTimeGraph data={donationsThroughTime} />
					</Card>
				</Col>
			</Row>
			<Row gutter={[16, 16]} className="mt-4">
				<Col xs={24} md={12}>
					<Card
						title={t("dashboard.reservationsThruTime")}
						className="shadow-md h-full"
						extra={<ClockCircleOutlined className="text-yellow-500" />}
					>
						<ReservationsTimeGraph data={reservationsThruTime} />
					</Card>
				</Col>
				<Col xs={24} md={12}>
					<Card
						title={t("dashboard.inventoryItemsAvailableThruTime")}
						className="shadow-md h-full"
						extra={
							<FundProjectionScreenOutlined className="text-blue-500" />
						}
					>
						<InventoryAvailabilityGraph data={inventoryItemsAvailableThruTime} />
					</Card>
				</Col>
			</Row>
		</div>
	);
};
