import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Radio, Checkbox, Tag } from "antd";
import { useCreate, useTranslate, useCustom, useApiUrl } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";

const { TextArea } = Input;

export const NotificationsCreate: React.FC = () => {
	const t = useTranslate();
	const { mutateAsync } = useCreate();
	const { formProps, saveButtonProps } = useForm({});
	const [targetType, setTargetType] = useState("all");
	const [contactMethod, setContactMethod] = useState("PUSH");
	const [queryParams, setQueryParams] = useState<any>({});
	const [massTarget, setMassTarget] = useState<string[]>([]);
	const [deviceCount, setDeviceCount] = useState<number>(0);
	const [uniqueDeviceData, setUniqueDeviceData] = useState<any>({});

	const handleTargetTypeChange = (e: any) => {
		setTargetType(e.target.value);
		setUniqueDeviceData({}); // Reset unique device data
	};

	const handleContactMethodChange = (value: string) => {
		setContactMethod(value);
	};

	const handleMassTargetChange = (checkedValues: string[]) => {
		setMassTarget(checkedValues);
	};

	const apiUrl = useApiUrl();

	const { data: devicesData, refetch: fetchDevices } = useCustom({
		url: `${apiUrl}/notifications/devices/filter`,
		method: "get",
		config: {
			query: queryParams,
		},
		queryOptions: {
			enabled: false, // This ensures it doesn't run on mount
		},
	});

	const handleSubmit = async (values: any) => {
		const devices = targetType === "individual" ? [uniqueDeviceData] : devicesData?.data;
		const users = devices?.map((device: any) => device.user);
		mutateAsync({
			resource: "notifications/send/push",
			values: {
				devices,
				users,
				title: values.title,
				message: values.body,
			},
		});
	};

	useEffect(() => {
		const filters: { deviceType?: string[]; userRole?: string[] } = {};
		if (targetType === "all") {
			setQueryParams({});
		} else {
			if (massTarget.includes("android") || massTarget.includes("ios")) {
				filters.deviceType = massTarget.filter(target => target === "android" || target === "ios");
			}
			if (massTarget.includes("DONATOR") || massTarget.includes("BENEFICIARY")) {
				filters.userRole = massTarget.filter(target => target === "DONATOR" || target === "BENEFICIARY").map(target => target.toUpperCase());
			}
			setQueryParams(filters);
		}
	}, [massTarget, targetType]);

	useEffect(() => {
		if (Object.keys(queryParams).length > 0 || targetType === "all") {
			fetchDevices().then(() => {
				if (devicesData) {
					setDeviceCount(devicesData.data.length);
				}
			});
		}
	}, [queryParams]);

	useEffect(() => {
		if (devicesData?.data?.length) {
			setDeviceCount(devicesData.data.length);
		}
		if (targetType === "individual") {
			setDeviceCount(1);
		}
	}, [devicesData]);

	return (
		<Create saveButtonProps={{ ...saveButtonProps, style: { backgroundColor: "#EDA0D8" } }}>
			<Form {...formProps} layout="vertical" onFinish={handleSubmit}>
				<Form.Item
					name="title"
					label={t("notifications.fields.title")}
					rules={[{ required: true }]}
				>
					<Input placeholder={t("notifications.fields.title")} />
				</Form.Item>
				<Form.Item
					name="body"
					label={t("notifications.fields.body")}
					rules={[{ required: true }]}
				>
					<TextArea rows={4} placeholder={t("notifications.fields.body")} />
				</Form.Item>
				<Form.Item
					name="contactMethod"
					label={t("notifications.fields.method")}
					rules={[{ required: true }]}
				>
					<Select placeholder={t("notifications.fields.method")} onChange={handleContactMethodChange}>
						<Select.Option value="PUSH">{t("notifications.methods.push")}</Select.Option>
						<Select.Option value="EMAIL" disabled>{t("notifications.methods.email")}</Select.Option>
						<Select.Option value="IN_APP" disabled>{t("notifications.methods.inApp")}</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item
					name="targetType"
					label={t("notifications.fields.targetType")}
					rules={[{ required: true }]}
				>
					<Radio.Group onChange={handleTargetTypeChange} value={targetType} defaultValue={targetType}>
						<Radio value="all">{t("notifications.target.all")}</Radio>
						<Radio value="mass">{t("notifications.target.mass")}</Radio>
						<Radio value="individual">{t("notifications.target.individual")}</Radio>
					</Radio.Group>
				</Form.Item>

				{targetType === "individual" && (
					<Form.Item
						name="user"
						label={t("notifications.fields.user")}
						rules={[{ required: true }]}
					>
						<Select
							placeholder={t("notifications.fields.user")}
							options={devicesData?.data?.map((device: any) => ({
								label: `${device?.user?.email} - ${device?.uniqueDeviceId}`,
								value: device?.uniqueDeviceId,
							}))}
							onChange={(value) => {
								const selectedDevice = devicesData?.data?.find((device: any) => device?.uniqueDeviceId === value);
								setUniqueDeviceData(selectedDevice);
							}}
						/>
					</Form.Item>
				)}

				{targetType === "mass" && (
					<Form.Item
						name="massTarget"
						label={t("notifications.fields.massTarget")}
					>
						<Checkbox.Group onChange={handleMassTargetChange}>
							<Checkbox value="android">{t("notifications.targets.android")}</Checkbox>
							<Checkbox value="ios">{t("notifications.targets.ios")}</Checkbox>
							<Checkbox value="DONATOR">{t("notifications.targets.DONATOR")}</Checkbox>
							<Checkbox value="BENEFICIARY">{t("notifications.targets.BENEFICIARY")}</Checkbox>
						</Checkbox.Group>
					</Form.Item>
				)}
			</Form>
			{deviceCount > 0 && (
				<Tag color="blue" style={{ marginTop: "10px" }}>
					{t("notifications.fields.deviceCount")}: {deviceCount} {t("notifications.fields.devices")}
				</Tag>
			)}
		</Create>
	);
};
