import { Create, Edit, useForm } from "@refinedev/antd";
import { IResourceComponentsProps, useCustom, useList, useTranslate } from "@refinedev/core";
import { AutoComplete, Form, Input, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";

interface IRole {
	name: string;
}

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
	const { formProps, saveButtonProps, onFinish } = useForm({});
	const [role, setRole] = useState<string | undefined>(undefined);
	const t = useTranslate();


	const onFormFinish = (values: any) => {
		// 
		onFinish(values);
	}

	const { data: roleData } = useList<IRole>({
		resource: "users/roles",
	});

	const { data: partnersList } = useList({
		resource: "partner",
	});


	const rolesArray = roleData?.data


	return (
		<Create saveButtonProps={{
			...saveButtonProps,
			style: {
				backgroundColor: "#EDA0D8",
			},
		}}>
			<p className="mb-4 text-gray-500 font-12">{t("users.fields.invitationDescription")}</p>
			<Form {...formProps} layout="vertical" onFinish={onFormFinish}>
				<Form.Item label={t("users.fields.email")} name="email" rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item
					label={t("users.fields.role")}
					name={"role"}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Select onChange={(value) => setRole(value)}>
						{rolesArray?.map((role: IRole) => (
							<Select.Option key={role.toString()} value={role.toString()}>{role.toString()}</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label={t("users.fields.partner")}
					name={["partnerId"]}
					hidden={role !== "PARTNER"}
				>
					<Select>
						{partnersList?.data.map((partner: any) => (
							<Select.Option key={partner.id} value={partner.id}>{partner.socialReason}</Select.Option>
						))}
					</Select>
				</Form.Item>


			</Form>
		</Create >
	);
};
