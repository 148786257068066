import {
	IResourceComponentsProps,
	useShow,
	useTranslate,
	HttpError,
	useUpdate,
} from "@refinedev/core";
import { Button, Card, Typography, Divider, Tag, Row, Col, Input, Image } from "antd";
import React, { useState } from "react";
import { Show } from "../../components/crud/show";
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	FileOutlined,
	ExclamationCircleOutlined,
	InfoCircleOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;
const { TextArea } = Input;

export const DocumentsShow: React.FC<IResourceComponentsProps> = () => {
	const { queryResult } = useShow();
	const { data, isLoading } = queryResult;
	const t = useTranslate();
	const record = data?.data;

	const { mutate: updateDocument } = useUpdate<any>();

	const [notes, setNotes] = useState<string | undefined>(record?.verificationNotes || "");

	const handleStatusChange = (docId: number, status: "VERIFIED" | "REJECTED" | "UNVERIFIED") => {
		updateDocument(
			{
				resource: "documents",
				id: docId,
				values: { verified: status, verificationNotes: notes },
			},
			{
				onSuccess: () => {
					queryResult.refetch(); // Refetch the data after update
				},
			}
		);
	};

	const getStatusTag = (verified: string) => {
		switch (verified) {
			case "VERIFIED":
				return (
					<Tag icon={<CheckCircleOutlined />} color="success">
						{t("documents.status.verified")}
					</Tag>
				);
			case "REJECTED":
				return (
					<Tag icon={<CloseCircleOutlined />} color="error">
						{t("documents.status.rejected")}
					</Tag>
				);
			case "UNVERIFIED":
				return (
					<Tag icon={<ExclamationCircleOutlined />} color="warning">
						{t("documents.status.unverified")}
					</Tag>
				);
			default:
				return (
					<Tag icon={<FileOutlined />} color="default">
						{t("documents.status.pending")}
					</Tag>
				);
		}
	};

	return (
		<Show isLoading={isLoading}>
			<Title level={4}>{t("documents.fields.details")}</Title>
			<Divider />
			<Row gutter={[16, 16]}>
				{record ? (
					record.map((doc: any) => (
						<Col xs={24} md={12} lg={8} key={doc.id}>
							<Card
								title={t(`documents.types.${doc.key}`)}
								extra={getStatusTag(doc.verified)}
								actions={[
									<Button
										type="primary"
										icon={<CheckCircleOutlined />}
										onClick={() => handleStatusChange(doc.id, "VERIFIED")}
										disabled={doc.verified === "VERIFIED"}
									>
										{t("documents.actions.accept")}
									</Button>,
									<Button
										type="default"
										icon={<CloseCircleOutlined />}
										onClick={() => handleStatusChange(doc.id, "REJECTED")}
										disabled={doc.verified === "REJECTED"}
									>
										{t("documents.actions.reject")}
									</Button>,
									<Button
										type="default"
										icon={<ExclamationCircleOutlined />}
										onClick={() => handleStatusChange(doc.id, "UNVERIFIED")}
										disabled={doc.verified === "UNVERIFIED"}
									>
										{t("documents.actions.unverify")}
									</Button>,
								]}
								style={{ height: "100%" }} // Ensures card height is consistent
								bodyStyle={{ padding: 16, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
							>
								<div style={{ marginBottom: 16 }}>
									<Title level={5}>{t("documents.fields.uploadedAt")}</Title>
									<Text>{new Date(doc.uploadedAt).toLocaleString()}</Text>
									<Divider />
									<Image
										src={doc.url}
										alt={t(`documents.types.${doc.key}`)}
										style={{ maxWidth: "100%", maxHeight: 200, display: 'block', margin: 'auto' }}
									/>
									<Divider />
									<Title level={5}>{t("documents.fields.verificationNotes")}</Title>
									<div className="flex items-center gap-2 flex-row mb-2">
										<InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', marginRight: 8 }} />
										<p className="ant-typography">{t("documents.fields.verificationNotesDescription")}</p>
									</div>
									<TextArea
										rows={3}
										placeholder={t("documents.fields.addNotes")}
										defaultValue={doc.verificationNotes}
										onChange={(e) => setNotes(e.target.value)}
										style={{ resize: 'none' }} // Prevents resizing to keep the layout consistent
									/>
								</div>
							</Card>
						</Col>
					))
				) : (
					<Text>{t("documents.messages.noData")}</Text>
				)}
			</Row>
		</Show>
	);
};
