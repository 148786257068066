import {
	DateField,
	NumberField,
	TextField,
} from "@refinedev/antd";
import { IResourceComponentsProps, useOne, useShow, useTranslate, useUpdate } from "@refinedev/core";
import { Space, Tag, Typography, Modal, Image, Button, Card, Divider, Switch, Input, Form, notification } from "antd";
import React, { useState } from "react";
import { Show } from "../../components/crud/show";
import { useNavigation } from "@refinedev/core";
import { CheckCircleOutlined, CloseCircleOutlined, UserOutlined } from "@ant-design/icons";
import MapKitMap from "../../components/mapkitmap";
import dayjs from "dayjs";

const { Title } = Typography;

export const DonationShow: React.FC<IResourceComponentsProps> = () => {
	const { queryResult } = useShow({});
	const { data, isLoading, refetch } = queryResult;
	const record = data?.data;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isModalVisibleRefuse, setIsModalVisibleRefuse] = useState(false);
	const [reason, setReason] = useState("");
	const [selectedArticle, setSelectedArticle] = useState<any>(null);
	const [isNotCompliant, setIsNotCompliant] = useState(false);
	const [notCompliantReason, setNotCompliantReason] = useState("");
	const { show } = useNavigation();
	const { mutateAsync } = useUpdate();

	const t = useTranslate();

	const { data: userData, isLoading: userIsLoading } = useOne({
		resource: "donation/user",
		id: record?.id,
	});

	const userDataLatitute = userData?.data?.address?.latitude;
	const userDataLongitude = userData?.data?.address?.longitude;

	const handleArticleClick = (article: any) => {
		setSelectedArticle(article);
		setIsNotCompliant(article.notCompliant);
		setNotCompliantReason(article.notCompliantReason || "");
		setIsModalVisible(true);
	};

	const handleUserRedirect = () => {
		show("users", userData?.data?.id as string);
	}

	const handleAcceptOrRefuse = async (decision: boolean, reason: string) => {
		if (decision === false && isModalVisibleRefuse === false) {
			setIsModalVisibleRefuse(true);
			return;
		}
		await mutateAsync({
			resource: "donation/" + record?.id + "/review",
			id: "",
			values: {
				reason: reason,
				decision: decision,
				notCompliant: isNotCompliant,
				notCompliantReason: notCompliantReason,
			},
			mutationMode: "pessimistic",
		});
		setIsModalVisibleRefuse(false);
		refetch();
	}

	const handleUpdateCompliance = async () => {
		if (!selectedArticle) return;

		await mutateAsync({
			resource: "donation/donationItem/compliance",
			id: selectedArticle.id,
			values: {
				notCompliant: isNotCompliant,
				notCompliantReason: notCompliantReason,
			},
		});
		setIsModalVisible(false);
		refetch();
	};

	const getStatusTag = (status: string) => {
		switch (status) {
			case "DRAFT":
				return <Tag color="red">{status}</Tag>
			case "TO_REVIEW":
				return <Tag>{status}</Tag>
			case "ACCEPTED":
				return <Tag color="green">{status}</Tag>
			case "REFUSED":
				return <Tag color="red">{status}</Tag>
			case "TO_PICKUP":
				return <Tag>{status}</Tag>
			case "PICKED_UP":
				return <Tag>{status}</Tag>
			case "IN_PROGRESS":
				return <Tag color="blue">{status}</Tag>
			case "PENDING":
				return <Tag color="orange">{status}</Tag>
			default:
				return <Tag>{status}</Tag>
		}
	}

	return (
		<Show isLoading={isLoading}
			contentProps={{
				style: {
					display: "flex",
					flexDirection: "column",
					gap: "1rem",
					backgroundColor: "transparent",
				},
			}}>
			<div className="flex flex-row gap-4 flex-wrap">
				<Card className="flex-1 w-full md:w-1/2 xl:w-1/3 min-w-1/2">
					<Title level={4}>{t("donation.fields.articles.title")}</Title>
					<Space className="flex flex-row flex-wrap gap-4 justify-around">
						{record?.contents?.map((article: any, index: number) => {
							return (
								<div className="flex items-center flex-col bg-gray-100 p-2 border border-gray-200 rounded-md gap-2" onClick={() => handleArticleClick(article)} key={article.id}>
									<p className="text-xs">{t("donation.fields.articles.title")} ({article.id}):  {article.name}</p>
									<img src={article.frontImage ?? "https://via.placeholder.com/150"} alt={article.name} className="w-20 h-20" />
									<Tag color={article.notCompliant ? "red" : "green"}>
										{article.notCompliant ? t("donation.fields.articles.notCompliant") : t("donation.fields.articles.compliant")}
									</Tag>
								</div>
							)
						})}
					</Space>
					<Divider />
					<Title level={4}>{t("donation.maps.title")}</Title>
					<TextField value={userData?.data?.address?.address + ", " + userData?.data?.address?.city + " " + userData?.data?.address?.postalCode + ", " + userData?.data?.address?.country} style={{ marginBottom: '1rem' }} />
					{
						userDataLatitute && userDataLongitude && (
							<MapKitMap mapKitJSToken={import.meta.env.VITE_MAPS_API_KEY}
								latitude={userDataLatitute}
								longitude={userDataLongitude}
							/>
						)
					}
					<Divider />
					<Title level={4} className="mb-4">{t("donation.fields.tracking")}</Title>
					<div className="flex flex-row gap-4 overflow-auto">
						{
							record?.history?.map((history: any, index: number) => {
								return (
									<div className="flex flex-col gap-2 border border-gray-200 p-2 rounded-md align-center justify-center" key={index}>
										<Tag color={"purple"}>{history.action}</Tag>
										<p>{dayjs(history.createdAt).format("DD/MM/YYYY HH:mm")}</p>
										{history.userInvolvedId && <Button type="text" onClick={() => show("users", history.userInvolvedId)}>{t("donation.actions.trackUser")}</Button>}
									</div>
								)
							})
						}
					</div>
				</Card>
				<Card className="flex-2 w-full md:w-1/2 xl:w-1/3 min-w-1/2">
					{
						record?.status === "TO_REVIEW" && (
							<div className="flex flex-row flex-wrap">
								<Button type="text" onClick={() => handleAcceptOrRefuse(true, "")} icon={<CheckCircleOutlined />} className="cursor-pointer bg-green-500 text-white mr-4">{t("actions.accept")}</Button>
								<Button type="text" onClick={() => handleAcceptOrRefuse(false, "")} icon={<CloseCircleOutlined />} className="cursor-pointer bg-red-500 text-white">{t("actions.refuse")}</Button>
							</div>

						)
					}
					<Button type="text" onClick={handleUserRedirect} icon={<UserOutlined />} className="cursor-pointer">{t("donation.actions.seeUser")}</Button>
					<Divider />
					<Title level={4}>{t("donation.fields.title")}</Title>
					<Title level={5}>{"ID"}</Title>
					<NumberField value={record?.id ?? ""} />
					<Title level={5}>{t("donation.fields.status.title")}</Title>
					{getStatusTag(t(`donation.fields.status.${record?.status}`))}
					{
						record?.status === "REFUSED" && (
							<>
								<Title level={5}>{t("donation.fields.refusalReason")}</Title>
								<TextField value={record?.refusalReason} />
							</>
						)
					}
					<Title level={5}>{t("donation.fields.sendingWay")}</Title>
					<TextField value={record?.sendingWay} />
					{
						record?.status !== "DRAFT" && (
							<>
								<Title level={5}>{t("donation.fields.pickUpDayPreference")}</Title>
								{
									record?.pickupDaysPreference?.map((day: string) => (
										<Tag key={day} color="blue">{t(`pickup.days.${day}`)}</Tag>
									))
								}
								<Title level={5}>{t("donation.fields.pickUpTimePreference")}</Title>
								<TextField value={record?.pickupHoursPreference} />
							</>
						)
					}
					<Title level={5}>{t("donation.fields.pointsReward")}</Title>
					<TextField value={record?.pointsReward} />
					<Divider />
					<Title level={4}>{t("users.user")}</Title>
					<Title level={5}>{t("users.fields.firstName")}</Title>
					<TextField value={userData?.data?.firstName} />
					<Title level={5}>{t("users.fields.lastName")}</Title>
					<TextField value={userData?.data?.lastName} />
					<Title level={5}>{t("users.fields.email")}</Title>
					<TextField value={userData?.data?.email} />
					<Title level={5}>{t("users.fields.phoneNumber")}</Title>
					<TextField value={userData?.data?.phoneNumber} />
					<Title level={5}>{t("donation.fields.createdAt")}</Title>
					<DateField value={record?.createdAt} />
				</Card>
			</div>

			<Modal
				title={t("donation.fields.reason")}
				open={isModalVisibleRefuse}
				onOk={() => setIsModalVisibleRefuse(false)}
				onCancel={() => setIsModalVisibleRefuse(false)}
				footer={null}
			>
				<input type="text" value={reason} onChange={(e) => setReason(e.target.value)} className="w-full p-2 border border-gray-200 rounded-md mb-4" placeholder={t("donation.fields.reason")} />
				<Button onClick={() => handleAcceptOrRefuse(false, reason)}>{t("donation.actions.refuse")}</Button>
			</Modal>

			<Modal
				title={t("donation.fields.articles.details")}
				open={isModalVisible}
				onOk={handleUpdateCompliance}
				onCancel={() => setIsModalVisible(false)}
				footer={[
					<Button key="submit" type="primary" onClick={handleUpdateCompliance}>
						{t("buttons.save")}
					</Button>,
				]}
			>
				{selectedArticle && (
					<div className="flex flex-col gap-3">
						<p><strong>ID:</strong> {selectedArticle?.id} </p>
						<p><strong>{t("donation.fields.articles.name")}:</strong> {selectedArticle.name}</p>
						<p><strong>{t("donation.fields.articles.category")}:</strong> {t(`category.categories.${selectedArticle?.category?.code}`)}</p>
						<p><strong>{t("donation.fields.articles.size")}:</strong> {selectedArticle?.size?.value}</p>
						<p><strong>{t("donation.fields.articles.condition")}:</strong> {t(`condition.conditions.${selectedArticle?.condition?.name}`)}</p>
						<p><strong>{t("donation.fields.articles.notCompliant")}:</strong> <Switch checked={isNotCompliant} onChange={setIsNotCompliant} /></p>
						{isNotCompliant && (
							<Form.Item
								label={t("donation.fields.contents.notCompliantReason")}
								rules={[
									{
										required: true,
										message: t("donation.fields.contents.notCompliantReasonRequired"),
									},
								]}
							>
								<Input.TextArea value={notCompliantReason} onChange={(e) => setNotCompliantReason(e.target.value)} />
							</Form.Item>
						)}
						<p><strong>Images:</strong> </p>
						<div className="flex flex-row gap-8">
							{['frontImage', 'backImage', 'tagImage', 'defaultImage', 'detailsImage'].map((image) => {
								if (!selectedArticle[image]) return null;
								return (
									<Image key={image} src={selectedArticle[image]} alt={selectedArticle.name} className="w-10 h-10" />
								);
							})}
						</div>
					</div>
				)}
			</Modal>
		</Show>
	);
};
