import { useMany, CrudFilters, HttpError, IResourceComponentsProps, BaseRecord, useTranslate, useList } from "@refinedev/core";

import {
	List,
	TextField,
	useTable,
	EditButton,
	ShowButton,
	useSelect,
	DateField,
	DeleteButton,
} from "@refinedev/antd";

import { SearchOutlined } from "@ant-design/icons";

import {
	Table,
	Space,
	Form,
	Input,
	Button,
	DatePicker,
	Card,
	Select,
	Tag,
	FormProps,
	Row,
	Col,
	Image,
} from "antd";
import React from "react";

const Filter: React.FC<{ formProps: FormProps, t: any }> = ({ formProps, t }) => {
	const { data: cities, isLoading: userIsLoading } = useList({
		resource: "donation/cities",
	});


	return (
		<Form layout="vertical" {...formProps}>
			<Form.Item name={"status"} label={t("donation.fields.status.title")}>
				<Select
					allowClear
					options={[
						{ label: t("donation.fields.status.DRAFT"), value: "DRAFT" },
						{ label: t("donation.fields.status.TO_REVIEW"), value: "TO_REVIEW" },
						{ label: t("donation.fields.status.ACCEPTED"), value: "ACCEPTED" },
						{ label: t("donation.fields.status.REFUSED"), value: "REFUSED" },
						{ label: t("donation.fields.status.TO_PICKUP"), value: "TO_PICKUP" },
						{ label: t("donation.fields.status.PICKED_UP"), value: "PICKED_UP" },
						{ label: t("donation.fields.status.IN_PROGRESS"), value: "IN_PROGRESS" },
						{ label: t("donation.fields.status.COMPLETED"), value: "COMPLETED" },
					]}
					placeholder={t("donation.filters.statusPlaceholder")}
				/>
			</Form.Item>
			<Form.Item name={"city"} label={t("donation.fields.city.title")}>
				<Select
					allowClear
					options={cities?.data?.map((city: any) => ({
						label: city,
						value: city,
					}))}
					placeholder={t("donation.filters.cityPlaceholder")}
				/>
			</Form.Item>
			<Form.Item>
				<Button htmlType="submit">
					{t("actions.filter")}
				</Button>
				<Button htmlType="submit" onClick={() => formProps?.form?.resetFields()}>
					{t("actions.reset")}
				</Button>
			</Form.Item>
		</Form>
	);
}


export const DonationList: React.FC<IResourceComponentsProps> = () => {
	const t = useTranslate();
	const { tableProps, searchFormProps } = useTable<
		any,
		HttpError,
		any
	>({
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { q, city, status, createdAt } = params;

			filters.push(
				{
					field: "status",
					operator: "eq",
					value: status,
				},
				{
					field: "city",
					operator: "eq",
					value: city,
				},
			);

			return filters;
		},
	});

	return (
		<List>
			<Card title={t("actions.filter")}>
				<Filter formProps={searchFormProps} t={t} />
			</Card>
			<Table {...tableProps} rowKey="id">
				<Table.Column dataIndex="id" title={"ID"} />
				<Table.Column
					dataIndex="status"
					title={t("donation.fields.status.title")}
					render={(value) => {
						let color;
						switch (value) {
							case "DRAFT":
								color = "blue";
								break;
							case "TO_REVIEW":
								color = "orange";
								break;
							case "ACCEPTED":
								color = "green";
								break;
							case "REFUSED":
								color = "red";
								break;
							case "TO_PICKUP":
								color = "blue";
								break;
							case "PICKED_UP":
								color = "green";
								break;
							case "IN_PROGRESS":
								color = "blue";
								break;
							case "COMPLETED":
								color = "green";
								break;
							default:
								color = "";
								break;
						}
						return <Tag color={color}>{t(`donation.fields.status.${value}`).toUpperCase()}</Tag>;
					}}
				/>

				<Table.Column dataIndex="sendingWay" title={t("donation.fields.sendingWay")} />
				<Table.Column dataIndex="pointsReward" title={t("donation.fields.pointsReward")} />
				<Table.Column dataIndex="contents" title={t("donation.fields.articles.title")} render={(value: any, index) => {
					const articles = value.slice(0, 4)
					return (
						<Space key={index + value.length}>
							{articles.map((article: any, index: number) => {
								if (index > 3) return null
								if (!article.frontImage) return null
								return (
									<div className="flex items-center" key={article.id}>
										<img src={article.frontImage} alt={article.name} className="w-10 h-10" />
									</div>
								)
							}
							)}
						</Space>
					)
				}} />
				<Table.Column
					dataIndex={["createdAt"]}
					title={t("donation.fields.createdAt")}
					render={(value: any) => <DateField value={value} />}
				/>
				<Table.Column
					title={"Actions"}
					dataIndex="actions"
					render={(_, record: BaseRecord) => (
						<Space>
							<EditButton hideText size="small" recordItemId={record.id} />
							<ShowButton hideText size="small" recordItemId={record.id} />
							<DeleteButton hideText size="small" recordItemId={record.id} />
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
