import { TextField } from "@refinedev/antd";
import { useShow, useTranslate } from "@refinedev/core";
import { Typography, Card, Descriptions, Tag } from "antd";
import React from "react";
import { Show } from "../../components/crud/show";
import { DateTime } from "luxon";

const { Title } = Typography;

interface User {
	firstName: string;
	lastName: string;
	email: string;
}

interface Device {
	id: number;
	mapKitToken: string | null;
	uniqueDeviceId: string;
	mapKitTokenExpiry: string | null;
	mapKitTokenCount: number;
	systemName: string;
	systemVersion: string;
	model: string;
	manufacturer: string;
	isTablet: boolean;
	deviceId: string;
	deviceType: string;
	pushToken: string | null;
	lang: string;
	userId: number | null;
	createdAt: string;
	updatedAt: string;
	user: User | null;
}

export const DevicesShow: React.FC = () => {
	const { queryResult } = useShow<Device>({});
	const { data, isLoading } = queryResult;

	const record = data?.data;
	const t = useTranslate();

	const renderField = (label: string, value: React.ReactNode, tagColor?: string) => (
		<Descriptions.Item label={label}>
			{tagColor ? <Tag color={tagColor}>{value}</Tag> : value}
		</Descriptions.Item>
	);

	return (
		<Show isLoading={isLoading} contentProps={{
			style: {
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
				backgroundColor: "transparent",
				boxShadow: "none",
			},
		}}>
			<div className="flex flex-col gap-4">
				<div className="flex flex-row gap-4">
					<Card title={t("devices.title")} className="flex-1 w-full md:w-1/2 xl:w-1/3 min-w-1/2">
						<Descriptions column={1} bordered>
							{renderField(t("devices.fields.uniqueDeviceId"), record?.uniqueDeviceId ?? "")}
							{renderField(t("devices.fields.systemName"), record?.systemName ?? "")}
							{renderField(t("devices.fields.systemVersion"), record?.systemVersion ?? "")}
							{renderField(t("devices.fields.model"), record?.model ?? "")}
							{renderField(t("devices.fields.manufacturer"), record?.manufacturer ?? "")}
							{renderField(t("devices.fields.mapKitTokenCount"), record?.mapKitTokenCount ?? 0, "blue")}
							{renderField(t("devices.fields.isTablet"), record?.isTablet ? t("yes") : t("no"), record?.isTablet ? "green" : "red")}
							{renderField(t("devices.fields.deviceId"), record?.deviceId ?? "")}
							{renderField(t("devices.fields.deviceType"), record?.deviceType ?? "")}
							{renderField(t("devices.fields.pushToken"), record?.pushToken ?? "", record?.pushToken ? "blue" : "red")}
							{renderField(t("devices.fields.lang"), record?.lang ?? "")}
							{renderField(t("devices.fields.createdAt"), record?.createdAt ? DateTime.fromISO(record.createdAt).toLocaleString(DateTime.DATETIME_MED) : "")}
							{renderField(t("devices.fields.updatedAt"), record?.updatedAt ? DateTime.fromISO(record.updatedAt).toLocaleString(DateTime.DATETIME_MED) : "")}
							<Descriptions.Item label={t("devices.fields.user")}>
								{record?.user ? `${record.user.firstName} ${record.user.lastName} (${record.user.email})` : t("devices.fields.noUser")}
							</Descriptions.Item>
						</Descriptions>
					</Card>
				</div>
			</div>
		</Show>
	);
};
