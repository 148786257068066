import React, { useCallback, useState } from "react";
import { Row, Col, List, Select, Card, Typography, Button, DatePicker } from "antd";
import MapWithMarkers from "../../components/MapWithMarkers";
import { useCreate, useList, useNavigation, useNotification, useTranslate } from "@refinedev/core";
import { DeleteOutlined } from "@ant-design/icons";
import { Create } from "@refinedev/antd";
import dayjs from "dayjs";

const { Title } = Typography;

export const PickupCreate = () => {
	const [selectedMarkersIds, setSelectedMarkersIds] = useState<string[]>([]);

	const [selectedWeek, setSelectedWeek] = useState("");
	const [selectedCity, setSelectedCity] = useState("");
	const [selectedPickupDays, setSelectedPickupDays] = useState("");
	const [selectedPickupHours, setSelectedPickupHours] = useState("");

	const { data: pickupPointsData, isLoading: pickupPointsIsLoading } = useList({
		resource: "pickup-points",
		filters: [
			{
				field: "city",
				operator: "eq",
				value: selectedCity,
			},
			{
				field: "pickupDaysPreference",
				operator: "eq",
				value: selectedPickupDays,
			},
			{
				field: "pickupHoursPreference",
				operator: "eq",
				value: selectedPickupHours,
			}
		],
		pagination: {
			mode: "off"
		},
	});



	const { data: pickupCitiesData, isLoading: pickupCitiesIsLoading } = useList({
		resource: "pickup-cities",
	});

	const { data: pickupPreferencesData, isLoading: pickupPreferencesIsLoading } = useList({
		resource: "pickup-preferences",
	});

	const pickupPreferences = pickupPreferencesData?.data as unknown as { pickupDays: string[]; pickupHours: string[] };

	const citiesToDeliver = pickupCitiesData?.data

	const markers = pickupPointsData?.data.map((point: any) => ({
		id: point.id,
		latitude: point.user?.address?.latitude,
		longitude: point.user?.address?.longitude,
		pickupDaysPreference: point?.pickupDaysPreference[0],
		pickupHoursPreference: point?.pickupHoursPreference[0],
		title: point.user?.firstName,
		address: point.user?.address?.address,
		city: point.user?.address?.city,
	})) || [];

	// Trouvez la ville sélectionnée dans citiesToDeliver
	const selectedCityCoords = citiesToDeliver?.find(city => city.name === selectedCity);

	const handleSelectMarker = (markerId: string) => {
		// if include, remove
		if (selectedMarkersIds.includes(markerId)) {
			setSelectedMarkersIds(currentIds => currentIds.filter(id => id !== markerId));
			return;
		}
		setSelectedMarkersIds(currentIds => [...currentIds, markerId]);
	}

	const t = useTranslate();

	const { mutateAsync } = useCreate();

	const { list } = useNavigation();


	const onSubmit = useCallback(async (result: any, selectedWeek: string) => {
		// 
		// 
		try {
			await mutateAsync({
				resource: "pickup",
				values: {
					donationsIds: result,
					pickupWeek: selectedWeek
				},

			});

			list("pickup");
		} catch (error) {

		}
	}, [mutateAsync, open, close]);


	return (
		<Create saveButtonProps={{
			style: {
				opacity: 0
			},
			disabled: true,
			hidden: true
		}}>
			<Col className="gap-4">
				<Title level={2}>{t("pickup.title")}</Title>
				<div className="flex flex-row gap-4 pb-4 items-center flex-wrap">
					<div className="flex flex-col flex-wrap gap-2">
						<p>{t("pickup.selectCity")}</p>
						<Select onChange={setSelectedCity} placeholder={t("pickup.selectCity")} value={selectedCity || undefined}>
							{citiesToDeliver?.map(city => (

								<Select.Option key={city.id} value={city.name}>
									{city.name} - {city.postalCode}
								</Select.Option>
							))}
						</Select>
					</div>
					<div className="flex flex-col gap-2">
						<p>{t("pickup.selectPickupDays")}</p>
						<Select onChange={setSelectedPickupDays} placeholder={t("pickup.selectPickupDays")} value={selectedPickupDays || undefined}>
							{pickupPreferences?.pickupDays?.map((day: string) => (
								<Select.Option key={day.toString()} value={day}>
									{t(`pickup.days.${day.toUpperCase()}`)}
								</Select.Option>
							))}
						</Select>
					</div>
					<div className="flex flex-col gap-2">
						<p>{t("pickup.selectPickupHours")}</p>
						<Select onChange={setSelectedPickupHours} placeholder={t("pickup.selectPickupHours")} value={selectedPickupHours || undefined}>
							{pickupPreferences?.pickupHours?.map((hour: string) => (
								<Select.Option key={hour.toString()} value={hour}>
									{hour}
								</Select.Option>
							))}
						</Select>
					</div>
					<div className="flex flex-col gap-2 justify-end">
						<p>{t("pickup.action.clearFilters")}</p>
						<Button
							onClick={() => {
								setSelectedCity("");
								setSelectedPickupDays("");
								setSelectedPickupHours("");
							}}
						>
							{t("pickup.action.clearFilters")}
						</Button>
					</div>
				</div>
				<Row className="flex flex-row">
					<Col xs={24} md={18} className="flex-wrap">
						<MapWithMarkers
							mapKitJSToken={import.meta.env.VITE_MAPS_API_KEY}
							markers={markers}
							selectedMarkersIds={selectedMarkersIds}
							onMarkerSelect={handleSelectMarker}
							focusCoords={selectedCityCoords ? { latitude: selectedCityCoords.latitude, longitude: selectedCityCoords.longitude } : undefined}
						/>
					</Col>
					<Col xs={24} md={6} className="flex bg-gray-100 flex-wrap">
						<List
							size="small"
							header={<div className="text-center"><p className="text-lg">{t("pickup.selectedPickupPoints")}</p></div>}
							bordered
							className="w-full overflow-y-auto h-96"
							dataSource={markers.filter(marker => selectedMarkersIds.includes(marker.id))}
							renderItem={(item: any, index: number) => (
								<List.Item>
									<Card className="w-full flex flex-row items-center gap-2 flex-wrap">
										<div className="rounded-full w-8 h-8 bg-primary flex items-center justify-center mb-4">
											<p className="text-white">{index + 1}</p>
										</div>
										<div>
											<p className="font-bold">{t("pickup.donationid")}: {item.id}</p>
											<p>{t("pickup.pickupDaysPreference")}: {t(`pickup.days.${item.pickupDaysPreference?.toUpperCase()}`)}</p>
											<p>{t("pickup.pickupHoursPreference")}: {item.pickupHoursPreference}</p>
											{/* <p>{t("pickup.donatorFirstName")}: {item.title}</p> */}
											<p className="text-sm">{item.address}, {item.city}</p>
										</div>
										<Button
											type="link"
											className="mt-4"
											danger
											icon={<DeleteOutlined />}
											onClick={() => {
												setSelectedMarkersIds(currentIds => currentIds.filter(id => id !== item.id));
											}}
										/>
									</Card>
								</List.Item>
							)}
						/>
						<div className="flex flex-col justify-center  w-full">
							<span>{t("pickup.selectDate")}</span>
							<DatePicker
								className="w-full"
								picker="week"
								placeholder={t("pickup.selectDate")}
								onChange={(date, dateString) => {

									setSelectedWeek(dateString as string);
								}}
							/>
						</div>
						<div className="flex flex-row justify-center mt-4 w-full">
							<Button
								className=" bg-primary text-white rounded-lg font-semibold text-center"
								disabled={selectedMarkersIds.length === 0 || !selectedWeek}
								// return under array the id of the selected markers
								onClick={() => onSubmit(selectedMarkersIds, selectedWeek)}

							>
								{t("pickup.action.createPickup")}
							</Button>
						</div>
					</Col>
				</Row >

			</Col >
		</Create >
	);
};
