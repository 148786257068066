import {
	DateField,
	DeleteButton,
	EditButton,
	List,
	ShowButton,
	useTable,
} from "@refinedev/antd";
import { BaseRecord, IResourceComponentsProps, useTranslate, CrudFilters, useList } from "@refinedev/core";
import { Space, Table, Tag, Form, Select, Button, Card, Input } from "antd";
import React from "react";

interface IRole {
	name: string;
}

// Composant de filtre pour le rôle
const Filter: React.FC<{ formProps: any, t: any, setFilters: any }> = ({ formProps, t, setFilters }) => {

	const { data: roleData } = useList<IRole>({
		resource: "users/roles",
	});

	const { data: shops } = useList({
		resource: "shops",
	});

	const handleReset = () => {
		formProps?.form?.resetFields();
		setFilters([], "replace");
	};

	return (
		<Form layout="vertical" {...formProps}>
			<Form.Item name="firstName" label={t("users.fields.firstName")}>
				<Input placeholder={t("users.fields.firstName")} />
			</Form.Item>
			<Form.Item name="lastName" label={t("users.fields.lastName")}>
				<Input placeholder={t("users.fields.lastName")} />
			</Form.Item>
			<Form.Item name="email" label={t("users.fields.email")}>
				<Input placeholder={t("users.fields.email")} />
			</Form.Item>
			<Form.Item name="role" label={t("users.fields.role")}>
				<Select
					placeholder={t("users.fields.role")}
					allowClear
					options={roleData?.data?.map((role: IRole) => ({
						label: role,
						value: role,
					}))}
				/>
			</Form.Item>
			<Form.Item name="city" label={t("users.fields.city")}>
				<Select
					placeholder={t("users.fields.city")}
					allowClear
					options={Array.from(new Set(shops?.data?.map((shop: any) => shop?.location?.city)))
						.filter((city: any) => city)
						.map((city: any) => ({
							label: city,
							value: city,
						}))}
				/>
			</Form.Item>
			<Form.Item name="status" label={t("users.fields.status.title")}>
				<Select
					placeholder={t("users.fields.status.title")}
					allowClear
					options={[
						{ label: t("users.fields.status.student"), value: "student" },
						{ label: t("users.fields.status.parent"), value: "parent" },
					]}
				/>
			</Form.Item>
			<Form.Item>
				<Button htmlType="submit">{t("actions.filter")}</Button>
				<Button onClick={handleReset} className="ml-2">
					{t("actions.reset")}
				</Button>
			</Form.Item>
		</Form>
	);
};

export const UserList: React.FC<IResourceComponentsProps> = () => {
	const { tableProps, searchFormProps, setFilters } = useTable<BaseRecord>({
		onSearch: (params: any) => {
			const filters: CrudFilters = [];
			const { role, city, status, firstName, lastName, email } = params;

			filters.push(
				{ field: "firstName", operator: "eq", value: firstName },
				{ field: "lastName", operator: "eq", value: lastName },
				{ field: "email", operator: "eq", value: email },
				{ field: "role", operator: "eq", value: role },
				{ field: "city", operator: "eq", value: city },
				{
					field: "gender",
					operator: "in",
					value: status === "parent" ? ["KIDS"] : ["MAN", "WOMAN"],
				}
			);

			return filters;
		},
	});

	const t = useTranslate();

	const getUserStatus = (genre: any) => {
		if (genre === 'KIDS') {
			return <Tag color="blue">{t("users.fields.status.parent")}</Tag>;
		}

		if (genre === 'MAN' || genre === 'WOMAN') {
			return <Tag color="green">{t("users.fields.status.student")}</Tag>;
		}
	};

	return (
		<List>
			<Card title={t("actions.filter")}>
				<Filter formProps={searchFormProps} t={t} setFilters={setFilters} />
			</Card>
			<Table {...tableProps} rowKey="id">
				<Table.Column dataIndex="id" title={"ID"} />
				<Table.Column dataIndex="firstName" title={t("users.fields.firstName")} render={(value: any) => value ?? "-"} />
				<Table.Column dataIndex="lastName" title={t("users.fields.lastName")} render={(value: any) => value ?? "-"} />
				<Table.Column dataIndex="email" title={t("users.fields.email")} render={(value: any) => value ?? "-"} />
				<Table.Column dataIndex="phoneNumber" title={t("users.fields.phoneNumber")} />
				<Table.Column dataIndex="role" title={t("users.fields.role")} />
				<Table.Column dataIndex="gender" title={t("users.fields.status.title")} render={(value: any) => getUserStatus(value) ?? "-"} />
				<Table.Column
					dataIndex={["createdAt"]}
					title={t("users.fields.createdAt")}
					render={(value: any) => <DateField value={value} />}
				/>
				<Table.Column
					dataIndex="situations"
					title={t("users.fields.isValidated")}
					render={(value: any) => (
						value?.dossierValid ? (
							<Tag color="green">{t("users.fields.validated")}</Tag>
						) : (
							<Tag color="red">{t("users.fields.notValidated")}</Tag>
						)
					)}
				/>
				<Table.Column
					title={"Actions"}
					dataIndex="actions"
					render={(_, record: BaseRecord) => (
						<Space>
							<EditButton hideText size="small" recordItemId={record.id} />
							<ShowButton hideText size="small" recordItemId={record.id} />
							<DeleteButton hideText size="small" recordItemId={record.id} />
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
