import { Edit, useForm } from "@refinedev/antd";
import { IResourceComponentsProps, useList, useTranslate } from "@refinedev/core";
import { AutoComplete, Form, Input, List, Button } from "antd";
import React, { useCallback, useState, useEffect } from "react";

export const ShopsEdit: React.FC<IResourceComponentsProps> = () => {
	const { formProps, saveButtonProps, onFinish, queryResult } = useForm();
	const [inputValue, setInputValue] = useState("");
	const [selectedLabel, setSelectedLabel] = useState<string>("");
	const [completeAddress, setCompleteAddress] = useState<string>("");
	const [nearbyCities, setNearbyCities] = useState<any[]>([]); // Liste des villes proches ajoutées (stocke {label, value})
	const [citySearch, setCitySearch] = useState<string>("");

	const t = useTranslate(); // Hook for translations

	const debounce = (func: any, delay: number) => {
		let timer: any;
		return function (this: any, ...args: any) {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, delay);
		};
	};

	const debouncedSetInputValue = useCallback(debounce(setInputValue, 300), []);
	const debouncedSetCitySearch = useCallback(debounce(setCitySearch, 300), []);

	// Récupérer les données d'autocomplete depuis l'API pour l'adresse complète
	const { data: autoComplete } = useList({
		resource: "map/autocomplete",
		filters: [
			{
				field: "q",
				operator: "eq",
				value: inputValue,
			},
		],
	});

	// Récupérer les données d'autocomplete depuis l'API pour les villes proches
	const { data: cityAutoComplete } = useList({
		resource: "map/autocomplete",
		filters: [
			{
				field: "q",
				operator: "eq",
				value: citySearch,
			},
		],
	});

	const autoCompleteData = autoComplete?.data as any;
	const cityAutoCompleteData = cityAutoComplete?.data as any;

	// Préremplir les données des villes proches si elles existent dans le payload
	useEffect(() => {
		if (queryResult?.data) {
			const shopData = queryResult.data.data;
			// Charger les villes proches depuis le payload
			const loadedNearbyCities = shopData.nearbyCities.map((nearby: any) => ({
				label: nearby.location.city + ", " + nearby.location.postalCode,
				value: nearby.location.id, // Utiliser l'id comme valeur
			}));
			setNearbyCities(loadedNearbyCities);
		}
	}, [queryResult?.data]);

	// Fonction pour ajouter une ville proche (évite les doublons)
	const addNearbyCity = (city: { label: string; value: string }) => {
		if (!nearbyCities.some((c) => c.label === city.label)) {
			setNearbyCities([...nearbyCities, city]); // Ajoute {label, value}
		}
	};

	// Fonction pour supprimer une ville proche
	const removeNearbyCity = (cityLabel: string) => {
		setNearbyCities(nearbyCities.filter((city) => city.label !== cityLabel));
	};

	const onFormFinish = (values: any) => {
		const { name, description } = values;
		console.log("Form values", {
			name: name,
			description: description,
			completeAddress: completeAddress,
			nearbyCities: nearbyCities.map((city) => city.value), // On envoie uniquement les URLs ou IDs des villes proches sélectionnées
		});
		onFinish({
			name: name,
			description: description,
			completeAddress: completeAddress,
			nearbyCities: nearbyCities.map((city) => city.value), // On envoie uniquement les URLs ou IDs des villes proches sélectionnées
		});
	};

	return (
		<Edit saveButtonProps={{
			...saveButtonProps,
			style: {
				backgroundColor: "#EDA0D8",
			},
		}}>
			<Form {...formProps} layout="vertical" onFinish={onFormFinish}>
				<Form.Item label={t("shops.fields.name")} name="name">
					<Input />
				</Form.Item>
				<Form.Item label={t("shops.fields.description")} name="description">
					<Input />
				</Form.Item>
				<Form.Item label={t("shops.fields.location.address")} name="completeAddress">
					<AutoComplete
						defaultValue={
							selectedLabel ||
							`${formProps?.initialValues?.location?.address || ""}, ${formProps?.initialValues?.location?.city || ""}, ${formProps?.initialValues?.location?.postalCode || ""}`
						}
						options={autoCompleteData?.results?.map((result: any) => ({
							label: result?.displayLines?.[0] + ", " + result?.displayLines?.[1],
							value: result?.completionUrl,
						}))}
						onSearch={(searchText) => debouncedSetInputValue(searchText)}
						onSelect={(value, option) => {
							// Mettre à jour le formulaire avec la valeur sélectionnée
							formProps.form?.setFieldsValue({ completeAddress: option?.label?.toString() });
							setCompleteAddress(option?.value?.toString() ?? "");
							setSelectedLabel(option?.label?.toString() ?? "");
						}}
						value={selectedLabel}
						onChange={(_, option) => {
							if (!option) setSelectedLabel("");
						}}
					/>
				</Form.Item>

				{/* AutoComplete pour ajouter des villes proches */}
				<Form.Item label={t("shops.fields.nearbyCities")} name="nearbyCities">
					<AutoComplete
						defaultValue={citySearch || ""}
						options={cityAutoCompleteData?.results
							?.filter((result: any) => result?.structuredAddress?.locality !== null
								&& result?.structuredAddress?.locality !== undefined
								&& result?.structuredAddress?.locality !== ""
								&& result?.structuredAddress?.postCode !== null
								&& result?.structuredAddress?.postCode !== undefined
								&& result?.structuredAddress?.postCode !== ""
								&& result?.structuredAddress?.administrativeArea !== null
								&& result?.structuredAddress?.administrativeArea !== undefined
								&& result?.structuredAddress?.administrativeArea !== "")
							?.reduce((acc: any, result: any) => {
								// Utilisation d'un Set pour vérifier les doublons sur 'locality'
								const locality = result?.structuredAddress?.locality;
								const administrativeArea = result?.structuredAddress?.administrativeArea;
								const postCode = result?.structuredAddress?.postCode;
								if (!acc.localities.has(locality)) {
									acc.localities.add(locality);
									acc.filteredResults.push({
										label: locality + ", " + administrativeArea + ", " + postCode,
										value: result?.completionUrl,
									});
								}
								return acc;
							}, { localities: new Set(), filteredResults: [] }).filteredResults}
						onSearch={(searchText) => debouncedSetCitySearch(searchText)}
						onSelect={(value, option) => {
							formProps.form?.setFieldsValue({ nearbyCities: "" });
							if (option && option.label) {
								addNearbyCity({ label: option.label.toString(), value: option.value?.toString() ?? "" });
							}
							setCitySearch(""); // Vider le champ après sélection
						}}
						value={citySearch}
						onChange={(value) => {
							setCitySearch(value);
						}}
					/>
				</Form.Item>

				{/* Affichage des villes proches ajoutées */}
				<Form.Item label={t("shops.fields.addedNearbyCities")}>
					<List
						dataSource={nearbyCities}
						renderItem={(city) => (
							<List.Item
								actions={[
									<Button
										danger
										onClick={() => removeNearbyCity(city.label)}
									>
										{t("shops.actions.remove")}
									</Button>,
								]}
							>
								{/* Afficher uniquement le label, pas l'URL */}
								{city.label}
							</List.Item>
						)}
					/>
				</Form.Item>
			</Form>
		</Edit>
	);
};
