import axios from 'axios';

const axiosInstance = axios.create();

// Intercepteur de requête pour ajouter le token d'accès dans les en-têtes
axiosInstance.interceptors.request.use(
	(config) => {
		// Obtenez le token d'accès depuis votre stockage local ou état global
		const accessToken = localStorage.getItem('access_token'); // Exemple avec localStorage
		if (accessToken) {
			// Ajoutez le token d'accès aux en-têtes de la requête
			config.headers['Authorization'] = `Bearer ${accessToken}`;
		}
		return config;
	},
	(error) => {
		// Gérer l'erreur de requête ici si nécessaire
		return Promise.reject(error);
	}
);

// Créer un état pour le rafraîchissement et une file d'attente pour les requêtes en attente
let isRefreshing = false;
let refreshSubscribers: any[] = [];

// Fonction pour notifier les souscripteurs du nouveau token
function onRrefreshed(token: string) {
	console.log('Notifying subscribers with new token');
	refreshSubscribers.forEach((callback) => callback(token));
}

// Fonction pour ajouter des souscripteurs à la file d'attente
function addRefreshSubscriber(callback: any) {
	console.log('Adding subscriber to queue');
	refreshSubscribers.push(callback);
}

axiosInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			console.log('Token is expired, refreshing token');
			console.log('Checking if token is already refreshing');
			console.log('Is refreshing:', isRefreshing);
			console.log('Queue:', refreshSubscribers);
			if (isRefreshing) {
				// Si un rafraîchissement est déjà en cours, mettez la requête en file d'attente
				console.log('Token is refreshing, adding request to queue');
				return new Promise((resolve) => {
					console.log('Adding subscriber to queue');
					addRefreshSubscriber((token: string) => {
						originalRequest.headers['Authorization'] = 'Bearer ' + token;
						console.log('Resolving original request');
						resolve(axios(originalRequest));
					});
				});
			}

			originalRequest._retry = true;
			isRefreshing = true;

			try {
				console.log('Refreshing token');
				const refreshToken = localStorage.getItem('refresh_token');
				const response = await axios.post(`${import.meta.env.VITE_API_URL}/auth/refresh-tokens`, {
					refreshToken: refreshToken,
				});

				if (!response.data.access.token || !response.data.refresh.token) {
					throw new Error('Refresh token invalid');
				}

				localStorage.setItem("access_token", response.data.access.token);
				localStorage.setItem("refresh_token", response.data.refresh.token);
				axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access.token;

				// Notifiez les souscripteurs et videz la liste
				console.log('Notifying subscribers');
				onRrefreshed(response.data.access.token);
				refreshSubscribers = [];

				return axios(originalRequest);
			} catch (refreshError) {
				// Gérer l'erreur ici, par exemple en déconnectant l'utilisateur
				localStorage.clear();
				window.location.reload();
				return Promise.reject(refreshError);
			} finally {
				isRefreshing = false;
			}
		}

		return Promise.reject(error);
	}
);

export { axiosInstance };
