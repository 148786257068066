import { useState } from 'react';
import { Form, Button, Image } from 'antd';
import { useForgotPassword, useTranslate, useNavigation, useNotification } from "@refinedev/core";

export const ForgotPassword = () => {
	const [form] = Form.useForm();
	const t = useTranslate();
	const { mutate: forgotPassword, isLoading } = useForgotPassword();
	const navigate = useNavigation();
	const { open } = useNotification();

	const handleSubmit = (values: any) => {
		console.log("values", values);
		// Ici, vous devez implémenter la logique pour envoyer l'email de réinitialisation
		console.log("Sending password reset email to:", values.email);
		forgotPassword(values.email)
		open?.({
			type: "success",
			message: t("pages.forgotPassword.messages.instructionsSent"),
		});
		navigate?.push("/login");
	};

	return (
		<div className="flex justify-center items-center h-screen bg-primary">
			<div className="w-96">
				<div className="bg-white p-6 rounded-lg shadow-lg">
					<div className="flex justify-center mb-8">
						<Image
							width={200}
							src="logo.png"
						/>
					</div>

					<Form
						form={form}
						onFinish={handleSubmit}
					>
						<Form.Item
							labelCol={{ span: 24 }}
							label={t("pages.forgotPassword.fields.email")}
							name="email"
							rules={[
								{
									required: true,
									message: t("pages.forgotPassword.errors.validEmail"),
									type: "email",
								},
							]}
						>
							<input
								className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none"
								type="email"
								placeholder={t("pages.forgotPassword.fields.email")}
							/>
						</Form.Item>

						<div className="mb-4">
							<Button
								onClick={() => form.submit()}
								loading={isLoading}
								className="w-full bg-primary text-white rounded-lg font-semibold text-center">
								{t("pages.login.buttons.sendInstructions")}
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
};
