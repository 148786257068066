import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
	DeleteButton,
	EditButton,
	List,
	ShowButton,
	useTable,
} from "@refinedev/antd";
import { BaseRecord, CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useList, useTranslate } from "@refinedev/core";
import { Button, Card, Form, FormProps, Input, Select, Space, Table } from "antd";
import { DateTime } from "luxon";
import React from "react";

const Filter: React.FC<{ formProps: FormProps, t: any }> = ({ formProps, t }) => {
	const { data: partners } = useList({
		resource: "partner",
	});

	return (
		<Form layout="vertical" {...formProps}>
			<div>
				<Form.Item name={"partnerId"} label={t("offers.fields.partner")}>
					<Select
						key={partners?.data?.length}
						allowClear
						options={partners?.data?.map((partner: any) => ({
							label: '(' + partner?.id + ') ' + partner?.socialReason + ' - ' + partner?.location?.city,
							value: partner?.id,
						}))}
						placeholder={t("offers.fields.partner")}
					/>
				</Form.Item>
			</div>
			<Form.Item>
				<Button htmlType="submit">
					{t("actions.filter")}
				</Button>
				<Button htmlType="submit" onClick={() => formProps?.form?.resetFields()}>
					{t("actions.reset")}
				</Button>
			</Form.Item>
		</Form >
	);
}

export const OffersList: React.FC<IResourceComponentsProps> = () => {

	const t = useTranslate();

	const { tableProps, searchFormProps } = useTable<
		any,
		HttpError,
		any
	>({
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { q, partnerId } = params;

			filters.push(
				{
					field: "partnerId",
					operator: "eq",
					value: partnerId,
				},
			);

			return filters;
		},
	});

	const { data: identity } = useGetIdentity<any>();

	return (
		<List createButtonProps={{
			style: {
				backgroundColor: "#EDA0D8",
			},
			title: t("actions.create"),
			resource: "offers",
			hideText: true,
		}}>
			<Card title={t("actions.filter")} hidden={identity?.partnerId}>
				<Filter formProps={searchFormProps} t={t} />
			</Card>
			<Table {...tableProps} rowKey="id">
				<Table.Column dataIndex="id" title={"ID"} />
				<Table.Column dataIndex="partner" title={t("partner.fields.socialReason")} render={(value: any) => '(' + value?.id + ') ' + value?.socialReason + ' - ' + value?.location?.city} />
				<Table.Column dataIndex="name" title={t("offers.fields.name")} />
				<Table.Column dataIndex="price" title={t("offers.fields.price")} />
				<Table.Column dataIndex="multipleUse" title={t("offers.fields.multiple_use")} render={(value: any) => value ? <CheckOutlined /> : <CloseOutlined />} />
				<Table.Column dataIndex="type" title={t("offers.fields.type")} />
				<Table.Column dataIndex="digitalCode" title={t("offers.fields.digitalCode")} render={(value: any) => value ? value : "-"} />
				<Table.Column
					title={"Actions"}
					dataIndex="actions"
					render={(_, record: BaseRecord) => (
						<Space>
							<EditButton hideText size="small" recordItemId={record.id} />
							<ShowButton hideText size="small" recordItemId={record.id} />
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
