import { AuthProvider } from "@refinedev/core";

export const authProvider: AuthProvider = {
	login: async ({ email, password, role }) => {
		try {
			const response = await fetch(`${import.meta.env.VITE_API_URL}/auth/login`, {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email,
					password,
					role,
				}),
			});

			if (!response.ok) {
				throw new Error("Invalid credentials");
			}
			const { user, tokens } = await response.json();

			localStorage.setItem("access_token", tokens.access.token);
			localStorage.setItem("refresh_token", tokens.refresh.token);
			localStorage.setItem("user_info", JSON.stringify(user));

			return {
				success: true,
				redirectTo: "/",
			};
		} catch (e) {
			const error = e as Error;

			return {
				success: false,
				error: {
					message: "message" in error ? error.message : "Login failed",
					name: "name" in error ? error.name : "Invalid credentials",
				},
			};
		}
	},
	register: async ({ token, email, password, firstName, lastName, phoneNumber }) => {

		try {
			const response = await fetch(`${import.meta.env.VITE_API_URL_BASE}/auth/register-backoffice`, {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					token,
					email,
					password,
					firstName,
					lastName,
					phoneNumber,
				}),
			});

			if (!response.ok) {
				throw new Error("Invalid token");
			}

			const { user, tokens } = await response.json();

			localStorage.setItem("access_token", tokens.access.token);
			localStorage.setItem("refresh_token", tokens.refresh.token);
			localStorage.setItem("user_info", JSON.stringify(user));

			return {
				success: true,
				redirectTo: "/",
			};
		} catch (e) {
			const error = e as Error;
			return {
				success: false,
				error: {
					message: "message" in error ? error.message : "Login failed",
					name: "name" in error ? error.name : "Invalid token",
				},
			};
		}
	},
	logout: async () => {
		localStorage.removeItem("access_token");
		localStorage.removeItem("user_info");

		return {
			success: true,
			redirectTo: "/login",
		};
	},
	onError: async (error) => {
		if (error.statusCode === "UNAUTHENTICATED") {
			return {
				logout: true,
			};
		}

		return { error };
	},
	check: async () => {
		const accessToken = localStorage.getItem("access_token");
		if (!accessToken) {
			return {
				authenticated: false,
				redirectTo: "/login",
			};
		}

		return {
			authenticated: true,
			redirectTo: "/",
		};
	},
	getIdentity: async () => {
		const user = localStorage.getItem("user_info");

		if (user) {
			return JSON.parse(user);
		}
		return undefined;
	},
	forgotPassword: async (email) => {
		try {
			const response = await fetch(`${import.meta.env.VITE_API_URL_BASE}/auth/forgot-password`, {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email,
				}),
			});

			if (!response.ok) {
				throw new Error("Invalid email");
			}

			return {
				success: true,
			};
		} catch (e) {
			const error = e as Error;
			return {
				success: false,
				error: {
					message: "message" in error ? error.message : "Login failed",
					name: "name" in error ? error.name : "Invalid email",
				},
			};
		}
	},
	updatePassword: async (params) => {
		try {
			const response = await fetch(`${import.meta.env.VITE_API_URL_BASE}/auth/reset-password?token=${params.token}`, {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					password: params.password,
				}),
			});

			if (!response.ok) {
				throw new Error("Invalid token");
			}

			return {
				success: true,
			};
		} catch (e) {
			const error = e as Error;
			return {
				success: false,
				error: {
					message: "message" in error ? error.message : "Login failed",
					name: "name" in error ? error.name : "Invalid token",
				},
			};
		}
	}

};
