import { NumberField, TextField } from "@refinedev/antd";
import { IResourceComponentsProps, useNavigation, useShow, useTranslate } from "@refinedev/core";
import { Button, Typography } from "antd";
import { Card } from "antd/lib";
import React from "react";
import { DateTime } from "luxon";
const { Title } = Typography;
import { Show } from "../../components/crud/show";

export const PotsShow = () => {
	const { queryResult } = useShow({});
	const { data, isLoading } = queryResult;

	const record = data?.data;
	const t = useTranslate();

	const { show } = useNavigation()

	return (
		<Show isLoading={isLoading} contentProps={{
			style: {
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
				backgroundColor: "transparent",
				boxShadow: "none",
			},
		}}>
			<div className="flex flex-col gap-4">
				<div className="flex flex-row gap-4">
					<Card title={t("pots.title")} className="flex-1 w-full md:w-1/2 xl:w-1/3 min-w-1/2">
						<Title level={5}>{t("users.fields.lastName")}</Title>
						<TextField value={record?.user?.lastName ?? ""} />
						<Title level={5}>{t("users.fields.firstName")}</Title>
						<TextField value={record?.user?.firstName ?? ""} />
						<Title level={5}>{t("pots.fields.balance")}</Title>
						<TextField value={record?.balance ?? ""} />
						<Title level={5}>{t("pots.fields.potHistory")}</Title>
						<div className="flex flex-row gap-4 mt-4 overflow-auto">
							{record?.history.map((history: any) => {
								return (
									<Card title={t("pots.fields.historyId") + " " + history.id} key={history.id}>
										<Title level={5}>{t("pots.fields.action")}</Title>
										<TextField value={history.action} />
										<Title level={5}>{t("pots.fields.amount")}</Title>
										<TextField value={history.amount} />
										<Title level={5}>{t("pots.fields.date")}</Title>
										<TextField value={DateTime.fromISO(history.date).toLocaleString(DateTime.DATETIME_MED)} />
										{
											history.donationId && (
												<>
													<Title level={5}>{t("pots.fields.donationId")}</Title>
													<TextField value={history.donationId} />
												</>
											)
										}
										{
											history.offerId && (
												<>
													<Title level={5}>{t("pots.fields.offerId")}</Title>
													<TextField value={history.offerId} />
												</>
											)
										}
										{
											history.userInvolvedId && (
												<>
													<Title level={5}>{t("pots.fields.userInvolvedId")}</Title>
													<Button type="text" onClick={() => show("users", history.userInvolvedId)}>{t("buttons.seeUser")}</Button>
												</>
											)
										}
									</Card>
								);
							}
							)}
						</div>
					</Card>
				</div>
			</div>
		</Show>
	);
};
