import React from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

const EnvironmentBanner: React.FC = () => {
	const { t } = useTranslation();
	const isQaEnvironment = import.meta.env.VITE_ENV === 'qa';

	if (!isQaEnvironment) {
		return null;
	}

	const handleClick = () => {
		// Redirect to the production URL
		window.location.href = "https://admin.dressed-shop.fr/";
	};

	return (
		<div style={{ marginBottom: '6px' }}>
			<Alert
				message={t("environment.qa_message")}
				description={
					<div onClick={handleClick} style={{ cursor: 'pointer' }}>
						{t("environment.qa_description")}{" "}
						<br />
						<a href="https://admin.dressed-shop.fr/" onClick={(e) => e.preventDefault()} className='text-blue-500'>
							{t("environment.qa_link")}
						</a>
					</div>
				}
				type="warning"
				showIcon
				banner
				style={{
					width: '100%',
					zIndex: 1000,
					top: 0,
					left: 0,
				}}
			/>
		</div>
	);
};

export default EnvironmentBanner;
