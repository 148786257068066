import React, { useEffect, useRef } from 'react';

type MarkerData = {
	id: string;
	latitude: number;
	longitude: number;
};

type MapWithLinesProps = {
	mapKitJSToken: string;
	markers: MarkerData[];
};

const MapWithLines = ({ mapKitJSToken, markers }: MapWithLinesProps) => {
	const mapContainerRef = useRef<HTMLDivElement>(null);
	const mapRef = useRef<any>(null);

	useEffect(() => {
		if (!window.mapkit) return;

		window.mapkit.init({
			authorizationCallback: (done: (token: string) => void) => {
				done(mapKitJSToken);
			},
		});

		if (mapContainerRef.current && !mapRef.current) {
			mapRef.current = new window.mapkit.Map(mapContainerRef.current);

			// Focaliser sur un point initial, comme la France, si nécessaire
			const center = new window.mapkit.Coordinate(47.081012, 2.398782);
			const span = new window.mapkit.CoordinateSpan(8, 8);
			const region = new window.mapkit.CoordinateRegion(center, span);
			mapRef.current.region = region;
		}
	}, [mapKitJSToken]);

	useEffect(() => {
		if (!mapRef.current) return;

		mapRef.current.removeAnnotations(mapRef.current.annotations);
		mapRef.current.removeOverlays(mapRef.current.overlays);

		const coordinates = markers.map(marker => new window.mapkit.Coordinate(marker.latitude, marker.longitude));
		const offset = 0.0003;
		// if a marker has the exact same coordinates as another, it will be hidden behind the other, so we add a small offset if markers have the same coordinates
		markers.forEach((marker, index) => {
			if (markers.findIndex(m => m.latitude === marker.latitude && m.longitude === marker.longitude) !== index) {
				marker.latitude += offset;
				marker.longitude += offset;
			}
		});

		markers.forEach(marker => {
			const location = new window.mapkit.Coordinate(marker.latitude, marker.longitude);
			const annotation = new window.mapkit.MarkerAnnotation(location, {
				title: marker.id.toString(),
				color: "#4eabe9",
			});

			mapRef.current.addAnnotation(annotation);
		});

		// Dessiner une ligne qui relie tous les marqueurs
		if (coordinates.length > 1) { // S'assurer qu'il y a au moins deux points pour dessiner une ligne
			const polyline = new window.mapkit.PolylineOverlay(coordinates, {
				strokeColor: "#007aff", // La couleur de la ligne
				lineWidth: 2, // L'épaisseur de la ligne
			});

			mapRef.current.addOverlay(polyline);
		}
	}, [markers]);

	return <div id="map-with-markers-container" ref={mapContainerRef} style={{ height: '50vh', width: '100%' }} />;
};

export default MapWithLines;
