import { Create, getValueFromEvent, useForm } from "@refinedev/antd";
import { IResourceComponentsProps, file2Base64, useGetIdentity, useList, useTranslate } from "@refinedev/core";
import { AutoComplete, Button, Card, DatePicker, Form, Input, InputNumber, Select, TimePicker, Tooltip, Typography, Upload } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";

const { Title } = Typography;

export const OffersCreate: React.FC<IResourceComponentsProps> = () => {
	const { formProps, saveButtonProps, onFinish } = useForm({});
	const [isDigital, setIsDigital] = useState<string>("");
	const { data: identity } = useGetIdentity<any>();

	const onFormFinish = async (values: any) => {
		const body = {
			...values,
			partnerId: identity?.partnerId ?? values?.partnerId,
		}
		onFinish(body);
	}

	const t = useTranslate();
	const { data: partnersList } = useList({
		resource: "partner",
	});





	return (
		<Create saveButtonProps={{
			...saveButtonProps,
			style: {
				backgroundColor: "#EDA0D8",
			},

		}}>
			<Form {...formProps} layout="vertical" onFinish={onFormFinish}>
				<Form.Item label={t("offers.fields.name")} name="name" rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item label={t("offers.fields.price")} name="price" rules={[{ required: true }]}>
					<InputNumber />
				</Form.Item>
				<Form.Item label={t("offers.fields.multiple_use")} name="multipleUse" initialValue={formProps?.initialValues?.multipleUse} rules={[{ required: true }]}>
					<Select placeholder={t("offers.fields.selectMultipleUse")}>
						<Select.Option value={true}>{t("actions.yes")}</Select.Option>
						<Select.Option value={false}>{t("actions.no")}</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label={t("offers.fields.partner")} name="partnerId" hidden={!!identity?.partnerId} rules={[{ required: identity?.partnerId ? false : true }]}>
					<Select placeholder={t("offers.fields.selectPartner")}>
						{
							partnersList?.data?.map((partner: any) => (
								<Select.Option value={partner?.id}>{partner?.socialReason} - ({partner?.id}) - {partner?.location?.city} - {partner?.location?.address}</Select.Option>
							))
						}
					</Select>
				</Form.Item>
				<Form.Item label={t("offers.fields.type")} name="type" rules={[{ required: true }]}>
					<Select placeholder={t("offers.fields.selectType")} onSelect={(value) => setIsDigital(value)}>
						<Select.Option value="PHYSICAL">{t("offers.fields.physical")}</Select.Option>
						<Select.Option value="DIGITAL">{t("offers.fields.digital")}</Select.Option>
					</Select>
				</Form.Item>
				{
					isDigital === "DIGITAL" && (
						<Form.Item label={t("offers.fields.code")} name="digitalCode" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
					)
				}

			</Form>
		</Create>
	);
};
