import { BooleanField, ImageField, NumberField, TextField } from "@refinedev/antd";
import { IResourceComponentsProps, useNavigation, useShow, useTranslate } from "@refinedev/core";
import { Button, Card, Divider, Typography } from "antd";
import React from "react";
import { Show } from "../../components/crud/show";
import { EuroCircleOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons";

const { Title } = Typography;

export const OffersShow: React.FC<IResourceComponentsProps> = () => {
	const { queryResult } = useShow({});
	const { data, isLoading } = queryResult;
	const t = useTranslate();
	const record = data?.data;

	return (
		<Show isLoading={isLoading}>
			<Title level={5}>{"ID"}</Title>
			<NumberField value={record?.id ?? ""} disabled />
			<Title level={5}>{t("partner.fields.socialReason")}</Title>
			<TextField value={record?.partner?.socialReason} />
			<Title level={5}>{t("offers.fields.name")}</Title>
			<TextField value={record?.name} />
			<Title level={5}>{t("offers.fields.price")}</Title>
			<NumberField value={record?.price} />
			<Title level={5}>{t("offers.fields.multiple_use")}</Title>
			<BooleanField value={record?.multipleUse} />
			<Title level={5}>{t("offers.fields.type")}</Title>
			<TextField value={record?.type} />
		</Show>
	);
};
