import React, { useEffect, useRef } from 'react';

declare global {
	interface Window {
		mapkit: any; // Use 'any' or a more specific type if available
	}
}

const MapKitMap = ({ mapKitJSToken, latitude, longitude }: { mapKitJSToken: string; latitude: number; longitude: number }) => {
	const mapContainerRef = useRef(null);
	const mapInitialized = useRef(false);

	useEffect(() => {
		const setupMapKitJs = () => {
			if (window.mapkit && mapContainerRef.current && !mapInitialized.current) {
				const map = new window.mapkit.Map(mapContainerRef.current);

				const region = new window.mapkit.CoordinateRegion(
					new window.mapkit.Coordinate(latitude, longitude),
					new window.mapkit.CoordinateSpan(0.1, 0.1)
				);
				map.region = region;

				const location = new window.mapkit.Coordinate(latitude, longitude);
				const annotation = new window.mapkit.MarkerAnnotation(location, {
					color: "#4eabe9",
					glyphText: "\u{1F4CD}"
				});

				map.addAnnotation(annotation);
				mapInitialized.current = true; // Ensure setup runs only once
			}
		};

		setupMapKitJs();
		// We depend only on latitude and longitude to potentially re-setup the map if these props change
	}, [latitude, longitude, mapKitJSToken]);

	return (
		<div
			id="map-container"
			ref={mapContainerRef}
			style={{
				height: '50vh', // 50% of the viewport height
				width: '100%' // takes up the full width available
			}}
		/>
	);
};

export default MapKitMap;
