import { NumberField, TextField } from "@refinedev/antd";
import { IResourceComponentsProps, useList, useShow, useTranslate, useUpdate } from "@refinedev/core";
import { Button, Divider, Popconfirm, Select, Tag, Typography } from "antd";
import { Card } from "antd/lib";
import React, { useCallback, useState } from "react";
import { DateTime } from "luxon";
const { Title } = Typography;
import { Show } from "../../components/crud/show";
import { BulbTwoTone, CopyFilled, GoogleOutlined, HeatMapOutlined, WechatOutlined } from "@ant-design/icons";
import MapWithLines from "../../components/MapWithLines";
import { IconMap } from "antd/es/result";

export const PickupShow: React.FC<IResourceComponentsProps> = () => {
	const { queryResult } = useShow({});
	const { data, isLoading, refetch } = queryResult;
	const [selectedShopId, setSelectedShopId] = useState<string | undefined>(undefined);

	const record = data?.data;

	const { data: shops } = useList({
		resource: "shops",
	});


	const markers = record?.donations?.map((donation: any) => ({
		id: donation.id,
		latitude: donation?.user?.address?.latitude,
		longitude: donation?.user?.address?.longitude,
	}));

	const t = useTranslate();

	const { mutateAsync } = useUpdate();

	const onConfirmPickup = useCallback(async (result: any) => {
		try {
			await mutateAsync({
				resource: "pickup/" + result + "/pickup",
				id: "",
				values: {},
				mutationMode: "pessimistic",
			});

			refetch();
		} catch (error) {

		}
	}, [mutateAsync, open, close]);

	const onCancelPickup = async (result: any) => {
		try {
			await mutateAsync({
				resource: "pickup/" + result + "/cancel",
				id: "",
				values: {},
				// mutationMode: "pessimistic",

			});

			refetch();
		} catch (error) {

		}
	};

	const onFinishPickup = async (result: any, shopId: string) => {
		try {
			await mutateAsync({
				resource: "pickup/" + result + "/complete",
				id: "",
				values: {
					shopId: shopId,
				},
				// mutationMode: "pessimistic",

			});

			refetch();
		} catch (error) {

		}
	};



	const weekFormatter = (week: string) => {
		if (!week) return "";
		const [year, weekPart] = week?.split('-');
		const weekNumber = parseInt(weekPart.replace('th', ''), 10);

		// Calculate the first day of the first week of the year
		let firstDayOfYear = DateTime.fromISO(`${year}-01-01`);
		// Adjust to the first Monday of the year, assuming weeks start on Monday
		if (firstDayOfYear.weekday !== 1) {
			firstDayOfYear = firstDayOfYear.plus({ days: 8 - firstDayOfYear.weekday });
		}

		// Calculate the start and end of the target week
		const startOfWeek = firstDayOfYear.plus({ weeks: weekNumber - 1 });
		const endOfWeek = startOfWeek.plus({ days: 6 });

		// Now you can format these dates as needed
		return `${startOfWeek.toFormat('yyyy-MM-dd')} - ${endOfWeek.toFormat('yyyy-MM-dd')}`;

	}

	const confirm = (result: any) => {

		onCancelPickup(result);
	};

	return (
		<Show isLoading={isLoading} contentProps={{
			style: {
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
				backgroundColor: "transparent",
				boxShadow: "none",
			},
		}}>
			<div className="flex flex-col gap-4">
				<Button
					style={{ backgroundColor: "#EDA0D8", color: 'white' }}
					className="flex items-center p-4 cursor-pointer"
					type="text" onClick={() => {
						// open google maps with the waypoints
						const waypoints = record?.donations?.map((donation: any) => donation?.user?.address?.latitude + "," + donation?.user?.address?.longitude).join("/");
						window.open(`https://www.google.com/maps/dir/${waypoints}`);


					}} icon={<GoogleOutlined />}>{t("pickup.action.openOnGoogleMaps")}</Button>
				{
					markers?.length > 0 && (<MapWithLines markers={markers} mapKitJSToken={import.meta.env.VITE_MAPS_API_KEY} />
					)
				}
				<div className="flex gap-4">
					<Card title={t("pickup.title")} className="flex-1 w-full md:w-1/2 xl:w-1/3 min-w-1/2">
						<Title level={5}>{t("pickup.fields.id")}</Title>
						<NumberField value={record?.id ?? ""} />
						<Title level={5}>{t("pickup.fields.week")}</Title>
						{/* format pickupWeek as the week firstDay-lastDay pickupWeek format is string as 2024-12th" */}
						<TextField value={weekFormatter(record?.pickupWeek)} />
						<Title level={5}>{t("pickup.fields.deliverer")}</Title>
						<TextField value={record?.user?.firstName + " " + record?.user?.lastName} />
						<Title level={5}>{t("pickup.fields.status.title")}</Title>
						<Tag color={record?.status === "COMPLETED" ? "green" : record?.status === "IN_PROGRESS" ? "blue" : record?.status === "PENDING" ? "orange" : ""}>{t(`pickup.status.${record?.status}`)}</Tag>
					</Card>
				</div>
				{record?.status != "CANCELED" && (<Title level={5}>{t("pickup.donations.title")}</Title>)}
				<div className="flex flex-row gap-4 flex-wrap">
					{
						record?.donations?.map((donation: any) => (
							<Card title={donation.name}>
								{/* <Button
									className="bg-primary text-white rounded-lg font-semibold text-center mb-4"
									onClick={() => { }}><WechatOutlined /> {t("pickup.donations.action.chat")}
								</Button> */}
								<Tag color={donation.status === "TO_PICKUP" ? "red" : donation.status === "PICKED_UP" ? "green" : donation.status === "IN_PROGRESS" ? "orange" : ""}>{t(`donation.fields.status.${record?.status}`)}</Tag>
								<Title level={5}>{t("pickup.donations.fields.id")}</Title>
								<NumberField value={donation.id} />
								<Title level={5}>{t("pickup.donations.donator.name")}</Title>
								<TextField value={donation?.user?.firstName + " " + donation?.user?.lastName} />
								<Title level={5}>{t("pickup.donations.donator.phoneNum")}</Title>
								<TextField value={donation?.user?.phoneNumber} />
								{/* allow user to copy to clipboard the address */}
								<Title level={5}>{t("pickup.donations.donator.address")}</Title>
								<div className="flex flex-row gap-4 items-center">
									<TextField value={donation?.user?.address?.address + ", " + donation?.user?.address?.city + ", " + donation?.user?.address?.postalCode} />
									<Button
										onClick={() => { navigator.clipboard.writeText(donation?.user?.address?.address + ", " + donation?.user?.address?.city + ", " + donation?.user?.address?.postalCode) }}
									><CopyFilled />
									</Button>
								</div>
								<Title level={5}>{t("pickup.donations.fields.quantity")}</Title>
								<NumberField value={donation.contents.length} />
								<Divider />
								{
									donation?.status === "TO_PICKUP" && (

										<div className="flex flex-row flex-wrap gap-4">
											<Popconfirm
												title={t("pickup.donations.action.validate")}
												description={t("pickup.donations.action.validateDescription")}
												onConfirm={() => onConfirmPickup(donation.id)}
												okType="danger"
												okText="Yes"
												cancelText="No"
											>

												<Button
													className="bg-green-500 text-white rounded-lg font-semibold text-center"
												>
													{t("pickup.donations.action.picked_up")}
												</Button>
											</Popconfirm>
											<Popconfirm
												title={t("pickup.donations.action.cancel")}
												description={t("pickup.donations.action.cancelDescription")}
												onConfirm={() => confirm(donation.id)}
												okType="danger"
												okText="Yes"
												cancelText="No"
											>
												<Button
													danger
													className="text-white rounded-lg font-semibold text-center"
												>
													{t("pickup.donations.action.cancel")}
												</Button>
											</Popconfirm>
										</div>)
								}
							</Card>
						))
					}
				</div>
				{
					record?.donations?.filter((donation: any) => donation.status === "TO_PICKUP").length === 0 && record?.status != "COMPLETED" && record?.status != "CANCELED" && (
						<div className="flex flex-col gap-4">
							<Title level={5}>{t("pickup.action.selectTheShopDestination")}</Title>
							<Select
								key={shops?.data?.length}
								allowClear
								options={shops?.data?.map((shop: any) => ({
									label: shop?.name + " - " + shop?.location?.address,
									value: shop?.id,
								}))}
								onChange={(value) => setSelectedShopId(value)}
								placeholder={t("event.fields.shop.title")}
							/>

							<Popconfirm
								title={t("pickup.action.finish")}
								description={t("pickup.action.finishDescription")}
								onConfirm={() => onFinishPickup(record?.id, selectedShopId ?? "")}
								okType="danger"
								okText="Yes"
								cancelText="No"
							>
								<Button
									disabled={!selectedShopId}
									className="bg-green-500 text-white rounded-lg font-semibold text-center w-full"
								>
									{t("pickup.action.finish")}
								</Button>
							</Popconfirm>
						</div>
					)
				}
			</div>
		</Show >
	);
};
