import { Create, useForm } from "@refinedev/antd";
import { IResourceComponentsProps, useList, useTranslate } from "@refinedev/core";
import { Button, Card, DatePicker, Form, Input, InputNumber, Select, TimePicker, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

const { Title } = Typography;

export const EventCreate: React.FC<IResourceComponentsProps> = () => {
	const { formProps, saveButtonProps, onFinish } = useForm({});
	const [eventSlots, setEventSlots] = useState<any[]>(formProps?.initialValues?.slots || []);

	useEffect(() => {
		setEventSlots(formProps?.initialValues?.slots || []);
	}, [formProps?.initialValues?.slots]);

	const onFormFinish = (values: any) => {
		onFinish({
			date: values?.eventDate || formProps?.initialValues?.date,
			slots: eventSlots,
			shopId: values?.shopId,
		});
	}
	const t = useTranslate();

	const { data: shops } = useList({
		resource: "shops",
	});


	return (
		<Create saveButtonProps={{
			...saveButtonProps,
			style: {
				backgroundColor: "#EDA0D8",
			},
			disabled: eventSlots.length === 0,

		}}>
			<Form {...formProps} layout="vertical" onFinish={onFormFinish}>
				<Form.Item label={t("event.fields.date")} name={"eventDate"} rules={[{ required: true }]}>
					<DatePicker
						format="DD/MM/YYYY"
						placeholder={t("event.fields.date")}
						defaultValue={formProps?.initialValues?.date ? dayjs(formProps?.initialValues?.date) : undefined}
						onChange={(date, dateString) => formProps?.form?.setFieldsValue({ eventDate: date })} />
				</Form.Item>
				<Form.Item name={"shopId"} label={t("event.fields.shop.title")} initialValue={formProps?.initialValues?.shop?.id} rules={[{ required: true }]}>
					<Select
						key={shops?.data?.length}
						allowClear
						options={shops?.data?.map((shop: any) => ({
							label: shop?.name + " - " + shop?.location?.address,
							value: shop?.id,
						}))}
						placeholder={t("event.fields.shop.title")}
					/>
				</Form.Item>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<Title level={4}>{t("event.fields.slots.title")}</Title>
					<Button
						onClick={() => {
							// need this default format for time 2024-03-19T15:00:00.000Z
							setEventSlots([...eventSlots, { time: dayjs(), remainingSlots: 0, eventId: formProps?.initialValues?.id }]);
						}}
					>
						{t("event.action.addSlot")}
					</Button>
				</div>
				{
					eventSlots?.map((slot: any, index: number) => {
						return (
							<Card key={slot?.id + index}>
								<Form.Item label={t("event.fields.slots.title")} name={`slots[${index}].time`} key={`time-${index}`} rules={[{ required: true }]}>
									<TimePicker
										format="HH:mm"
										value={eventSlots[index]?.time ? dayjs(eventSlots[index]?.time) : undefined}
										defaultValue={dayjs(eventSlots[index]?.time)}
										onChange={(date, dateString) => {
											console.log("date", dateString);
											setEventSlots(eventSlots.map((s, i) => {
												if (i === index) {
													return { ...s, time: dateString };
												}
												return s;
											}));
											formProps?.form?.setFieldsValue({ [`slots[${index}].time`]: date })
										}}

										changeOnScroll={true}
										style={{ width: "100%" }}
										needConfirm={false}

									/>
								</Form.Item>
								<Form.Item label={t("event.fields.remainingSlots")} name={`slots[${index}].remainingSlots`} key={`remainingSlots-${index}`}>
									<InputNumber type="phone" defaultValue={slot?.remainingSlots} onChange={(e) => {
										setEventSlots(eventSlots.map((s, i) => {
											if (i === index) {
												return { ...s, remainingSlots: parseInt(e) };
											}
											return s;
										})
										);
									}
									} />
								</Form.Item>
								<Tooltip title={formProps?.initialValues?.reservations?.find((r: any) => r?.eventSlotReservationId === slot?.id) ? t("event.action.slotHasReservations") : ""}>
									<Button
										onClick={() => {
											setEventSlots(eventSlots.filter((s, i) => i !== index));
										}}
										disabled={formProps?.initialValues?.reservations?.find((r: any) => r?.eventSlotReservationId === slot?.id)}
										onMouseOver={(e) => {
											<Tooltip title={t("event.action.slotHasReservations")} open={true} />
										}
										}
									>
										{t("event.action.removeSlot")}
									</Button>
								</Tooltip>
							</Card>
						)
					})
				}
			</Form>
		</Create>
	);
};
