import { useState, useEffect } from 'react';
import { colors } from '../../contants'; // Assurez-vous que le chemin d'accès est correct
import { Button, Form, Image, Select } from 'antd';
import { useList, useLogin, useTranslate } from "@refinedev/core";

export interface ILoginForm {
	email: string;
	password: string;
	role: string;
}

type IRole = ["ADMIN" | "PARTNER" | "DELIVERER"]

export const Login = () => {
	const [form] = Form.useForm<ILoginForm>();
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
	const t = useTranslate();

	const { data: roleData } = useList<IRole>({
		resource: "users/roles",
	});

	const rolesArray = roleData?.data?.filter((role: any) => role !== "BENEFICIARY" && role !== "DONATOR");
	const values = Form.useWatch([], form);

	useEffect(() => {
		form.validateFields({ validateOnly: true })
			.then((formValues) => {

				setIsSubmitDisabled(!Object.values(formValues).length)  // check keys to handle init state
			})
			.catch(() => {
				setIsSubmitDisabled(true)
			})
	}, [values]);


	const { mutate: login, isLoading } = useLogin<ILoginForm>();

	return (
		<div className="flex justify-center items-center h-screen bg-primary">
			<div className="w-96">
				<div className="bg-white p-6 rounded-lg shadow-lg">
					<div className="flex justify-center mb-8">
						<Image
							width={200}
							src="logo.png"
						/>
					</div>

					<Form
						form={form}
						onFinish={(values) => {

							login({ email: values.email, password: values.password, role: values.role });
						}}
					>
						<Form.Item
							labelCol={{ span: 24 }}

							label={t("pages.login.fields.email")}
							name="email"
							rules={[
								{
									required: true,
									message: t("pages.login.errors.validEmail"),
								},
							]}
						>
							<input
								className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none"
								id="email"
								type="email"
								placeholder={t("pages.login.fields.email")}
							/>
						</Form.Item>
						<Form.Item
							labelCol={{ span: 24 }}
							label={t("pages.login.fields.password")}
							name="password"
							rules={[
								{
									required: true,
									message: t("pages.login.errors.validPassword"),
								},
							]}
						>
							<input
								className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none"
								id="password"
								type="password"
								placeholder={t("pages.login.fields.password")}
							/>
						</Form.Item>
						<Form.Item
							labelCol={{ span: 24 }}
							label={t("pages.login.fields.role")}
							name="role"
							rules={[
								{
									required: true,
									message: t("pages.login.errors.validRole"),
								},
							]}
						>
							<Select placeholder={t("pages.login.fields.role")} data-cy="role-select" className="w-full">
								{rolesArray?.map((role: IRole) => (
									<Select.Option key={role.toString()} value={role.toString()}>{role.toString()}</Select.Option>
								))}
							</Select>
						</Form.Item>
						{/* forgot password */}
						<div className="flex justify-end mb-4">
							<a href="/forgot-password" className="text-red">
								{t("pages.login.buttons.forgotPassword")}
							</a>
						</div>
						<div className="mb-4">
							<Button
								loading={isLoading}
								className="w-full bg-primary text-white rounded-lg font-semibold text-center"
								disabled={isSubmitDisabled}

								onClick={() => {
									form.submit();
								}
								}
							>
								{t("pages.login.signin")}
							</Button>
						</div>

					</Form>
				</div>
			</div>
		</div>

	);
};
