import { NumberField, TextField } from "@refinedev/antd";
import { IResourceComponentsProps, useShow, useTranslate } from "@refinedev/core";
import { Typography } from "antd";
import { Card } from "antd/lib";
import React from "react";
import { DateTime } from "luxon";
const { Title } = Typography;
import { Show } from "../../components/crud/show";

export const ReservationShow: React.FC<IResourceComponentsProps> = () => {
	const { queryResult } = useShow({});
	const { data, isLoading } = queryResult;

	const record = data?.data;
	const t = useTranslate();

	return (
		<Show isLoading={isLoading} contentProps={{
			style: {
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
				backgroundColor: "transparent",
				boxShadow: "none",
			},
		}}>
			<div className="flex flex-col gap-4">
				<div className="flex flex-row gap-4">
					<Card title={t("reservations.title")} className="flex-1 w-full md:w-1/2 xl:w-1/3 min-w-1/2">
						<Title level={5}>{t("users.fields.lastName")}</Title>
						<TextField value={record?.user?.lastName ?? ""} />
						<Title level={5}>{t("users.fields.firstName")}</Title>
						<TextField value={record?.user?.firstName ?? ""} />
						<Title level={5}>{t("event.fields.time")}</Title>
						<TextField value={DateTime.fromISO(record?.eventSlotReservation.time).toLocaleString(DateTime.TIME_SIMPLE)} />
						<Title level={5}>{t("event.fields.attended")}</Title>
						<TextField value={record?.attended ? t("event.fields.attended") : t("event.fields.notAttended")} />
						<Title level={5}>{t("event.fields.cancelled")}</Title>
						<TextField value={record?.cancelled ? t("event.fields.cancelled") : t("event.fields.notCancelled")} />
					</Card>
					<Card title={t("event.event")} className="flex-1 w-full md:w-1/2 xl:w-1/3 min-w-1/2">
						<Title level={5}>{"ID"}</Title>
						<NumberField value={record?.event?.id ?? ""} />
						<Title level={5}>{t("shops.fields.location.address")}</Title>
						<TextField value={record?.event?.shop?.location?.address + ", " + record?.event?.shop?.location?.city + " " + record?.event?.shop?.location?.postalCode + ", " + record?.event?.shop?.location?.country} />
						<TextField value={record?.user?.firstName ?? ""} />
						<Title level={5}>{t("event.fields.date")}</Title>
						<TextField value={DateTime.fromISO(record?.event?.date).toLocaleString(DateTime.DATE_SHORT)} />
					</Card>
				</div>
			</div>
		</Show>
	);
};
