import {
	DeleteButton,
	EditButton,
	List,
	ShowButton,
	useTable,
} from "@refinedev/antd";
import { BaseRecord, CrudFilters, HttpError, IResourceComponentsProps, useList, useTranslate } from "@refinedev/core";
import { AutoComplete, Button, Card, Form, FormProps, Input, Select, Space, Table } from "antd";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";

const Filter: React.FC<{ formProps: FormProps, t: any }> = ({ formProps, t }) => {
	const { data: shops } = useList({
		resource: "shops",
	});

	const { data: categoriesData, isLoading: userIsLoading } = useList({
		resource: "donation/categories",

	});

	const { data: sizesData, isLoading: sizesIsLoading } = useList({
		resource: "donation/categories/sizes",
	});


	const [categories, setCategories] = useState<any[]>(categoriesData?.data || []);

	useEffect(() => {
		setCategories(categoriesData?.data || []);
	}, [categoriesData]);

	return (
		<Form layout="vertical" {...formProps}>
			<div>
				{/* <div>
					<Form.Item name={"firstName"} label={t("users.fields.firstName")}>
						<Input placeholder={t("users.fields.firstName")} />
					</Form.Item>
					<Form.Item name={"lastName"} label={t("users.fields.lastName")}>
						<Input placeholder={t("users.fields.lastName")} />
					</Form.Item>
					<Form.Item name={"phoneNumber"} label={t("users.fields.phoneNumber")}>
						<Input placeholder={t("users.fields.phoneNumber")} />
					</Form.Item>
				</div> */}
				<div>
					<Form.Item name={"shopId"} label={t("event.fields.shop.title")}>
						<Select
							key={shops?.data?.length}
							allowClear
							options={shops?.data?.map((shop: any) => ({
								label: shop?.name + " - " + shop?.location?.address,
								value: shop?.id,
							}))}
							placeholder={t("event.fields.shop.title")}
						/>
					</Form.Item>
					<Form.Item name={"category"} label={t("donation.fields.category")}>
						<AutoComplete
							options={categories?.map((category: any) => ({
								label: category.code,
								value: category.id,
							}))}
							placeholder={t("donation.fields.category")}
							filterOption={(inputValue, option) =>
								option?.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
							}
						/>
					</Form.Item>
					<Form.Item name={"sizeId"} label={t("donation.fields.size")}>
						<Select
							allowClear
							options={sizesData?.data?.map((size: any) => ({
								label: size.value,
								value: size.id,
							}))}
							placeholder={t("donation.fields.size")}
						/>
					</Form.Item>
				</div>
			</div>
			<Form.Item>
				<Button htmlType="submit">
					{t("actions.filter")}
				</Button>
				<Button htmlType="submit" onClick={() => formProps?.form?.resetFields()}>
					{t("actions.reset")}
				</Button>
			</Form.Item>
		</Form>
	);
}

export const InventoryList: React.FC<IResourceComponentsProps> = () => {
	const t = useTranslate();

	const { tableProps, searchFormProps } = useTable<
		any,
		HttpError,
		any
	>({
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { shopId, category, sizeId } = params;

			filters.push(

				{
					field: "shopId",
					operator: "eq",
					value: shopId,
				},
				{
					field: "categoryId",
					operator: "eq",
					value: category,
				},
				{
					field: "sizeId",
					operator: "eq",
					value: sizeId,
				},
			);

			return filters;
		},
	});

	return (
		<List>
			<Card title={t("actions.filter")}>
				<Filter formProps={searchFormProps} t={t} />
			</Card>
			<Table {...tableProps} rowKey="id">
				<Table.Column dataIndex="id" title={"ID"} />
				<Table.Column title={t("shops.fields.name")} render={(value: any) => value?.shop?.name + " (" + value?.shop?.id + ")" + " - " + value?.shop?.location?.address} />
				<Table.Column dataIndex="items" title={t("inventory.fields.itemsToReview")} render={(value: any) => value?.filter((item: any) => item.status === "TO_REVIEW").length} />
				<Table.Column dataIndex="items" title={t("inventory.fields.itemsAvailable")} render={(value: any) => value?.filter((item: any) => item.status === "AVAILABLE").length} />

				<Table.Column
					title={"Actions"}
					dataIndex="actions"
					render={(_, record: BaseRecord) => (
						<Space>
							<ShowButton hideText size="small" recordItemId={record.id} />
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
