import {
	DeleteButton,
	EditButton,
	List,
	ShowButton,
	useTable,
} from "@refinedev/antd";
import { BaseRecord, CrudFilters, HttpError, IResourceComponentsProps, useList, useTranslate } from "@refinedev/core";
import { Button, Card, Form, FormProps, Input, Select, Space, Table } from "antd";
import { DateTime } from "luxon";
import React from "react";

const Filter: React.FC<{ formProps: FormProps, t: any }> = ({ formProps, t }) => {
	const { data: shops } = useList({
		resource: "shops",
	});

	return (
		<Form layout="vertical" {...formProps}>
			<div>
				{/* <div>
					<Form.Item name={"firstName"} label={t("users.fields.firstName")}>
						<Input placeholder={t("users.fields.firstName")} />
					</Form.Item>
					<Form.Item name={"lastName"} label={t("users.fields.lastName")}>
						<Input placeholder={t("users.fields.lastName")} />
					</Form.Item>
					<Form.Item name={"phoneNumber"} label={t("users.fields.phoneNumber")}>
						<Input placeholder={t("users.fields.phoneNumber")} />
					</Form.Item>
				</div> */}
				<div>
					<Form.Item name={"shopId"} label={t("event.fields.shop.title")}>
						<Select
							key={shops?.data?.length}
							allowClear
							options={shops?.data?.map((shop: any) => ({
								label: shop?.name + " - " + shop?.location?.address,
								value: shop?.id,
							}))}
							placeholder={t("event.fields.shop.title")}
						/>
					</Form.Item>
				</div>
			</div>
			<Form.Item>
				<Button htmlType="submit">
					{t("actions.filter")}
				</Button>
				<Button htmlType="submit" onClick={() => formProps?.form?.resetFields()}>
					{t("actions.reset")}
				</Button>
			</Form.Item>
		</Form>
	);
}

export const EventList: React.FC<IResourceComponentsProps> = () => {
	const t = useTranslate();

	const { tableProps, searchFormProps } = useTable<
		any,
		HttpError,
		any
	>({
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { shopId } = params;

			filters.push(

				{
					field: "shopId",
					operator: "eq",
					value: shopId,
				},
			);

			return filters;
		},
	});


	return (
		<List createButtonProps={{
			style: {
				backgroundColor: "#EDA0D8",
			},
			title: t("actions.create"),
			resource: "event",
			hideText: true,
		}}>
			<Card title={t("actions.filter")}>
				<Filter formProps={searchFormProps} t={t} />
			</Card>
			<Table {...tableProps} rowKey="id">
				<Table.Column dataIndex="id" title={"ID"} />
				<Table.Column title={t("event.fields.shop.name")} render={(value: any) => value?.shop?.name + " (" + value?.shop?.id + ")" + " - " + value?.shop?.location?.address} />
				<Table.Column dataIndex="date" title={t("event.fields.date")} render={(value: any) => DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED)} />
				<Table.Column dataIndex="piecesLimit" title={t("event.fields.piecesLimit")} />
				<Table.Column render={(value: any) => value?.slots?.length} title={t("event.fields.slots.openSlots")} />
				<Table.Column render={(value: any) => value?.reservations?.length} title={t("event.fields.slots.reservations")} />
				<Table.Column
					title={"Actions"}
					dataIndex="actions"
					render={(_, record: BaseRecord) => (
						<Space>
							<EditButton hideText size="small" recordItemId={record.id} />
							<ShowButton hideText size="small" recordItemId={record.id} />
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
