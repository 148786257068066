import { Edit, useForm } from "@refinedev/antd";
import { IResourceComponentsProps, useList } from "@refinedev/core";
import MDEditor from "@uiw/react-md-editor";
import { Form, Input, Select } from "antd";
import React from "react";
import { dataProvider } from "../../rest-data-provider";
import { useTranslate } from "@refinedev/core";
interface IRole {
	name: string;
}

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
	const { formProps, saveButtonProps, formLoading, onFinish } = useForm({
		resource: "users",
	});
	const t = useTranslate();

	const onFormFinish = (values: any) => {
		const { id, ...rest } = values;
		onFinish({ ...rest });
	}

	const { data: roleData } = useList<IRole>({
		resource: "users/roles",
	});

	const rolesArray = roleData?.data

	return (
		<Edit saveButtonProps={{
			...saveButtonProps, style: {
				backgroundColor: "#EDA0D8",
			}
		}} isLoading={formLoading}>
			<Form {...formProps} layout="vertical" onFinish={onFormFinish}>
				<Form.Item
					label={"ID"}
					name={["id"]}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input disabled />
				</Form.Item>
				<Form.Item
					label={t("users.fields.firstName")}
					name="firstName"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label={t("users.fields.lastName")}
					name={["lastName"]}
					initialValue={formProps?.initialValues?.category?.id}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label={t("users.fields.email")}
					name={["email"]}
					initialValue={"draft"}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label={t("users.fields.role")}
					name={["role"]}
					initialValue={"draft"}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Select>
						{rolesArray?.map((role: IRole) => (
							<Select.Option value={role.toString()}>{role.toString()}</Select.Option>
						))}
					</Select>
				</Form.Item>
			</Form>
		</Edit>
	);
};
