import { Authenticated, GitHubBanner, Refine, useTranslate } from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import {
	ErrorComponent,
	ThemedHeaderV2,
	ThemedLayoutV2,
	ThemedSiderV2,
	ThemedTitleV2,
	useNotificationProvider,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import routerBindings, {
	CatchAllNavigate,
	DocumentTitleHandler,
	NavigateToResource,
	UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { dataProvider } from "./rest-data-provider";
import { App as AntdApp, Image } from "antd";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider } from "./providers/auth";
import {
	BlogPostCreate,
	BlogPostEdit,
	BlogPostList,
	BlogPostShow,
} from "./pages/blog-posts";
import {
	CategoryEdit,
	CategoryList,
} from "./pages/categories";
import {
	UserList,
	UserEdit,
	UserShow,
	UserCreate
} from "./pages/users";
import {
	DonationList,
	DonationShow,
	DonationEdit
} from "./pages/donations";
import {
	ReservationList,
	ReservationShow,
	ReservationEdit
} from "./pages/reservations";
import {
	ShopsList,
	ShopsShow,
	ShopsEdit,
	ShopsCreate
} from "./pages/shops";
import {
	EventList,
	EventShow,
	EventEdit,
	EventCreate
} from "./pages/events";
import {
	PartnersList,
	PartnersShow,
	PartnersEdit,
	PartnersCreate
} from "./pages/partners";
import {
	OffersList,
	OffersShow,
	OffersEdit,
	OffersCreate
} from "./pages/offers";
import {
	PickupList,
	PickupCreate,
	PickupShow
} from "./pages/pickup";
import {
	InventoryShow,
	InventoryList,
} from "./pages/inventories";
import {
	DashboardPage
} from "./pages/dashboard";
import {
	ConditionEdit,
	ConditionList
} from "./pages/conditions";
import {
	PotsEdit,
	PotsList,
	PotsShow
} from "./pages/pots";

import {
	DevicesList,
	DevicesShow
} from "./pages/devices";

import {
	NotificationsList,
	NotificationsShow,
	NotificationsCreate
} from "./pages/notifications";

import {
	DocumentsList,
	DocumentsShow
} from "./pages/documents";

import { userHasRole } from "./utils/getRoles";

import { ForgotPassword } from "./pages/forgotPassword";
import { Login } from "./pages/login";
import { Invitation } from "./pages/invitation";
import './index.css'
import { AuditOutlined, BankOutlined, BookOutlined, BoxPlotOutlined, CalendarOutlined, CarOutlined, DropboxOutlined, EuroCircleOutlined, GiftOutlined, HomeOutlined, NotificationOutlined, OrderedListOutlined, PhoneOutlined, QrcodeOutlined, ShopOutlined, SkinOutlined, TagsOutlined, UserOutlined } from "@ant-design/icons";
import type { I18nProvider } from "@refinedev/core";
import { useTranslation } from "react-i18next";
import { Header } from "./components/header";
import { useEffect } from "react";
import { TFunctionDetailedResult } from "i18next";
import { ResetPassword } from "./pages/resetPassword";
import EnvironmentBanner from "./components/EnvironmentBanner";


type UserRole = 'ADMIN' | 'EDITOR' | 'VIEWER' | 'PARTNER' | 'DELIVERER' | 'LOGISTICIAN';

type PermissionsMap = {
	[K in UserRole]: {
		[resource: string]: string[];
	};
};

const rolePermissions: PermissionsMap = {
	ADMIN: { '*': ['*'] }, // Admin has access to all resources and actions
	EDITOR: {
		'posts': ['create', 'edit', 'delete', 'view'],
		'comments': ['view', 'edit'],
	},
	VIEWER: {
		'posts': ['view'],
		'comments': ['view'],
	},
	PARTNER: {
		'offers': ['create', 'edit', 'delete', 'list', 'show'],
		'partners': ['edit', 'delete', 'list', 'show'],
	},
	DELIVERER: {
		'pickup': ['create', 'edit', 'delete', 'list', 'show'],
		'donations': ['create', 'edit', 'delete', 'list', 'show'],
	},
	LOGISTICIAN: {
		'inventory': ['create', 'edit', 'delete', 'list', 'show'],
		'donations': ['create', 'edit', 'delete', 'list', 'show'],
		'donation': ['create', 'edit', 'delete', 'list', 'show'],
		'user': ['view', 'list', 'show'],
		'documents': ['create', 'edit', 'delete', 'list', 'show'],
	},
};

function App() {
	const { t, i18n } = useTranslation();

	const i18nProvider: I18nProvider = {
		translate: (key: string, options?: any) => t(key, options) as string,
		changeLocale: (lang: string) => i18n.changeLanguage(lang),
		getLocale: () => i18n.language,
	};

	useEffect(() => {
		const initMapKit = () => {
			if (!window.mapkit) {
				const script = document.createElement('script');
				script.src = "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js";
				script.async = true;
				script.onload = () => {
					window.mapkit.init({
						authorizationCallback: (done: any) => {
							done(import.meta.env.VITE_MAPS_API_KEY as string);
						}
					});
				};
				document.body.appendChild(script);
			}
		};

		initMapKit();
	}, []);

	return (
		<BrowserRouter>
			<RefineKbarProvider>
				<AntdApp>
					<DevtoolsProvider>
						<EnvironmentBanner />
						<Refine
							dataProvider={dataProvider(import.meta.env.VITE_API_URL)}
							i18nProvider={i18nProvider}
							notificationProvider={useNotificationProvider}
							authProvider={authProvider}
							routerProvider={routerBindings}
							accessControlProvider={{
								can: async ({ resource, action }) => {
									if (!authProvider.getIdentity) {

										return { can: false, reason: 'Unauthorized' };
									}

									const user = await authProvider.getIdentity() as { role: string } | null;

									if (user?.role === undefined) {

										return { can: false, reason: 'Unauthorized' };
									}

									const validRoles: UserRole[] = ['ADMIN', 'EDITOR', 'VIEWER', 'PARTNER', 'DELIVERER', 'LOGISTICIAN'];

									const userRole = validRoles.find(role => role === user.role) as UserRole | undefined;


									if (!userRole) {

										return { can: false, reason: 'Unauthorized' };
									}

									const permissions = rolePermissions[userRole];

									if (permissions['*']?.includes('*')) {
										return { can: true };
									}

									const resourcePermissions = permissions[resource as keyof typeof permissions];
									if (Array.isArray(resourcePermissions) && resourcePermissions?.includes(action as string)) {

										return { can: true };
									}

									return { can: false, reason: 'Unauthorized' };
								},
							}}

							resources={[
								{
									name: "dashboard",
									list: "/",
									meta: {
										label: "Dashboard",
										icon: <HomeOutlined />,
									},
								},
								{
									name: "donations",
									meta: { icon: <GiftOutlined />, label: i18nProvider.translate("donation.donations") },
								},
								{
									name: "donation",
									meta: { parent: "donations", icon: <GiftOutlined /> },
									list: "/donation",
									show: "/donation/show/:id",
									edit: "/donation/edit/:id",
								},
								{
									name: "pickup",
									meta: { parent: "donations", icon: <CarOutlined /> },
									list: "/pickup",
									create: "/pickup/create",
									show: "/pickup/show/:id",
								},
								{
									name: "Categories",
									meta: { parent: "donations", icon: <OrderedListOutlined /> },
									list: "/categories",
									edit: "/categories/edit/:id",
								},
								{
									name: "conditions",
									meta: { parent: "donations", icon: <SkinOutlined /> },
									list: "/conditions",
									show: "/conditions/show/:id",
									edit: "/conditions/edit/:id",
								},
								{
									name: "Events",
									meta: { icon: <CalendarOutlined />, label: i18nProvider.translate("event.events") },
								},
								{
									name: "event",
									meta: { parent: "Events", icon: <CalendarOutlined /> },
									list: "/events",
									show: "/events/show/:id",
									edit: "/events/edit/:id",
									create: "/events/create",
								},
								{
									name: "reservations",
									meta: { parent: "Events", icon: <QrcodeOutlined /> },
									list: "/reservations",
									show: "/reservations/show/:id",
									edit: "/reservations/edit/:id",
								},
								{
									name: "shops",
									meta: { icon: <ShopOutlined /> },
									list: "/shops",
									show: "/shops/show/:id",
									edit: "/shops/edit/:id",
									create: "/shops/create",
								},
								{
									name: "inventory",
									meta: { icon: <DropboxOutlined /> },
									list: "/inventory",
									show: "/inventory/show/:id",
								},
								{
									name: "Partners",
									meta: { icon: <AuditOutlined />, label: i18nProvider.translate("partner.partners") },
								},
								{
									name: "partner",
									meta: { parent: "Partners", icon: <AuditOutlined /> },
									list: "/partners",
									show: "/partners/show/:id",
									edit: "/partners/edit/:id",
									create: "/partners/create",
								},
								{
									name: "offers",
									meta: { parent: "Partners", icon: <EuroCircleOutlined /> },
									list: "/offers",
									show: "/offers/show/:id",
									edit: "/offers/edit/:id",
									create: "/offers/create",
								},
								{
									name: "user",

									meta: { icon: <UserOutlined />, label: i18nProvider.translate("users.user") },
								},
								{
									name: "users",
									meta: { parent: "user", icon: <UserOutlined /> },
									list: "/users",
									edit: "/users/edit/:id",
									show: "/users/show/:id",
									create: "/users/create",
								},
								{
									name: "documents",
									meta: { parent: "user", icon: <BookOutlined />, label: i18nProvider.translate("documents.documents") },
									list: "/documents",
									show: "/documents/show/:id",
								},
								{
									name: "pots",
									meta: { parent: "user", icon: <BankOutlined />, label: i18nProvider.translate("pots.pots") },
									list: "/pots",
									edit: "/pots/edit/:id",
									show: "/pots/show/:id",
								},
								{
									name: "Devices",
									meta: { parent: "user", icon: <PhoneOutlined />, label: i18nProvider.translate("devices.devices") },
									list: "/devices",
									show: "/devices/show/:id",
								},
								{
									name: "Notifications",
									meta: { parent: "user", icon: <NotificationOutlined />, label: i18nProvider.translate("notifications.notifications") },
									list: "/notifications",
									create: "/notifications/create",
									show: "/notifications/show/:id",
								},
								// 	{
								// 		name: "CMS",
								// 	},
								// 	{
								// 		name: "posts",
								// 		meta: { parent: "CMS" },
								// 		list: "/posts",
								// 	},

								//   {
								//     name: "blog_posts",
								//     list: "/blog-posts",
								//     create: "/blog-posts/create",
								//     edit: "/blog-posts/edit/:id",
								//     show: "/blog-posts/show/:id",
								//     meta: {
								//       canDelete: true,
								//     },
								//   },
								//   {
								//     name: "categories",
								//     list: "/categories",
								//     create: "/categories/create",
								//     edit: "/categories/edit/:id",
								//     show: "/categories/show/:id",
								//     meta: {
								//       canDelete: true,
								//     },
								//   },
							]}
							options={{
								syncWithLocation: true,
								warnWhenUnsavedChanges: true,
								useNewQueryKeys: true,
								projectId: "akxSny-orXgRX-BkD28Z",
							}}
						>
							<Routes>
								<Route
									element={
										<Authenticated
											key="authenticated-inner"
											fallback={<CatchAllNavigate to="/login" />}
										>
											<ThemedLayoutV2
												Header={() => <Header />}
												Sider={() => (
													<ThemedSiderV2
														Title={({ collapsed }) => (
															<div className="flex items-center justify-center">
																<img src="logo.png" />
															</div>
														)}
														render={({ items, logout, collapsed }) => {
															return (
																<>
																	{items}
																	{logout}
																</>
															);
														}}
													/>
												)}
											>
												<Outlet />
											</ThemedLayoutV2>
										</Authenticated>
									}
								>
									<Route index element={<DashboardPage />} />
									{/* <Route path="/blog-posts">
										<Route index element={<BlogPostList />} />
										<Route path="create" element={<BlogPostCreate />} />
										<Route path="edit/:id" element={<BlogPostEdit />} />
										<Route path="show/:id" element={<BlogPostShow />} />
									</Route> */}
									<Route path="/categories">
										<Route index element={<CategoryList />} />
										<Route path="edit/:id" element={<CategoryEdit />} />
									</Route>
									<Route path="/conditions">
										<Route index element={<ConditionList />} />
										<Route path="edit/:id" element={<ConditionEdit />} />
									</Route>
									<Route path="/users">
										<Route index element={<UserList />} />
										<Route path="edit/:id" element={<UserEdit />} />
										<Route path="show/:id" element={<UserShow />} />
										<Route path="create" element={<UserCreate />} />
									</Route>
									<Route path="/donation">
										<Route index element={<DonationList />} />
										<Route path="show/:id" element={<DonationShow />} />
										<Route path="edit/:id" element={<DonationEdit />} />
									</Route>
									<Route path="/pickup">
										<Route index element={<PickupList />} />
										<Route path="create" element={<PickupCreate />} />
										<Route path="show/:id" element={<PickupShow />} />
									</Route>
									<Route path="/reservations">
										<Route index element={<ReservationList />} />
										<Route path="show/:id" element={<ReservationShow />} />
										<Route path="edit/:id" element={<ReservationEdit />} />
									</Route>
									<Route path="/shops">
										<Route index element={<ShopsList />} />
										<Route path="show/:id" element={<ShopsShow />} />
										<Route path="edit/:id" element={<ShopsEdit />} />
										<Route path="create" element={<ShopsCreate />} />
									</Route>
									<Route path="/inventory">
										<Route index element={<InventoryList />} />

										<Route path="show/:id" element={<InventoryShow />} />
									</Route>
									<Route path="/events">
										<Route index element={<EventList />} />
										<Route path="show/:id" element={<EventShow />} />
										<Route path="edit/:id" element={<EventEdit />} />
										<Route path="create" element={<EventCreate />} />
									</Route>
									<Route path="/partners">
										<Route index element={<PartnersList />} />
										<Route path="show/:id" element={<PartnersShow />} />
										<Route path="edit/:id" element={<PartnersEdit />} />
										<Route path="create" element={<PartnersCreate />} />
									</Route>
									<Route path="/offers">
										<Route index element={<OffersList />} />
										<Route path="show/:id" element={<OffersShow />} />
										<Route path="edit/:id" element={<OffersEdit />} />
										<Route path="create" element={<OffersCreate />} />
									</Route>
									<Route path="/pots">
										<Route index element={<PotsList />} />
										<Route path="show/:id" element={<PotsShow />} />
										<Route path="edit/:id" element={<PotsEdit />} />
									</Route>
									<Route path="/devices">
										<Route index element={<DevicesList />} />
										<Route path="show/:id" element={<DevicesShow />} />
									</Route>
									<Route path="/notifications">
										<Route index element={<NotificationsList />} />
										<Route path="create" element={<NotificationsCreate />} />
										<Route path="show/:id" element={<NotificationsShow />} />
									</Route>
									<Route path="/documents">
										<Route index element={<DocumentsList />} />
										<Route path="show/:id" element={<DocumentsShow />} />
									</Route>
									<Route path="*" element={<ErrorComponent />} />
								</Route>
								<Route
									element={
										<Authenticated
											key="authenticated-outer"
											fallback={<Outlet />}
										>
											<NavigateToResource resource="dashboard" />
										</Authenticated>
									}
								>
									<Route path="/login" element={<Login />} />
									<Route path="/invitation" element={<Invitation />} />
									<Route
										path="/forgot-password"
										element={<ForgotPassword />}
									/>
									<Route path="/reset-password" element={<ResetPassword />} />
								</Route>
							</Routes>

							<RefineKbar />
							<UnsavedChangesNotifier />
							<DocumentTitleHandler />
						</Refine>
						<DevtoolsPanel />
					</DevtoolsProvider>
				</AntdApp>
			</RefineKbarProvider>
		</BrowserRouter>
	);
}

export default App;
