import { Edit, useForm } from "@refinedev/antd";
import { IResourceComponentsProps, useList, useMany, useTranslate } from "@refinedev/core";
import MDEditor from "@uiw/react-md-editor";
import { Button, Form, Input, Select, Space, Switch, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { dataProvider } from "../../rest-data-provider";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { axiosInstance } from "@refinedev/simple-rest";

interface IRole {
	name: string;
}


export const DonationEdit: React.FC<IResourceComponentsProps> = () => {
	const { formProps, saveButtonProps, formLoading, onFinish } = useForm({
		resource: "donation",
	});

	const t = useTranslate();
	const onFormFinish = (values: any) => {
		const { id, pickupDaysPreference, pickupHoursPreference, ...rest } = values;
		// 
		// 	pickupDaysPreference: pickupDaysPreference.toString(),
		// 	pickupHoursPreference: pickupHoursPreference.toString(),
		// 	status: values.status,
		// 	sendingWay: "PICKUP",
		// 	pointsReward: values.pointsReward,
		// 	contents: {
		// 		...rest.contents,
		// 		name: rest.contents.name,
		// 		categoryId: rest.contents.categoryId,
		// 		sizeId: rest.contents.sizeId,
		// 		conditionId: rest.contents.conditionId,
		// 	},
		// 	...rest
		// });
		onFinish({
			pickupDaysPreference: pickupDaysPreference.toString(),
			pickupHoursPreference: pickupHoursPreference.toString(),
			status: values.status,
			sendingWay: "PICKUP",
			pointsReward: values.pointsReward,
			contents: {
				...rest.contents,
				name: rest.contents.name,
				categoryId: rest.contents.categoryId,
				sizeId: rest.contents.sizeId,
				conditionId: rest.contents.conditionId,
			},
			...rest
		});
	}

	const { data: categoriesData, isLoading: userIsLoading } = useList({
		resource: "donation/categories",
		pagination: {
			pageSize: 1000,
		}

	});

	const { data: sizesData, isLoading: sizesIsLoading } = useList({
		resource: "donation/categories/sizes",
	});

	const { data: conditionsData, isLoading: conditionsIsLoading } = useList({
		resource: "donation/conditions",
	});

	const flattenConditions = (conditions: any) => {
		if (!conditions) return [];
		const options = conditions.map((condition: any) => {
			return {
				label: t(`condition.conditions.${condition.name}`), // t(`donation.conditions.${condition.name}`
				value: condition.id,
			};
		});
		return options;
	}


	const flattentSizes = () => {
		if (!sizesData) return [];
		const options = sizesData?.data?.map((size: any) => {
			return {
				label: size.value,
				value: size.id,
			};
		});
		return options;
	}


	const flattenCategories = (categories: any) => {
		if (!categories) return [];
		// keep only categories which are a child of another category (i.e. not a root category)
		const children = categories.filter((category: any) => category.parentId);
		const options = children.map((category: any) => {
			return {
				label: t(`category.categories.${category.code}`),
				value: category.id,
			};
		});
		return options;
	}



	return (
		<Edit saveButtonProps={{
			...saveButtonProps, style: {
				backgroundColor: "#EDA0D8",
			}
		}} isLoading={formLoading}>
			<Form {...formProps} layout="vertical" onFinish={onFormFinish}>
				<Form.Item
					label={"ID"}
					name={["id"]}
					data-cy="id"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input disabled />
				</Form.Item>
				<Form.Item
					label={t("donation.fields.status.title")}
					name={["status"]}
					data-cy="status"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Select>
						<Select.Option value={"DRAFT"}>{t("donation.fields.status.DRAFT")}</Select.Option>
						<Select.Option value={"TO_REVIEW"}>{t("donation.fields.status.TO_REVIEW")}</Select.Option>
						<Select.Option value={"ACCEPTED"}>{t("donation.fields.status.ACCEPTED")}</Select.Option>
						<Select.Option value={"REFUSED"}>{t("donation.fields.status.REFUSED")}</Select.Option>
						<Select.Option value={"TO_PICKUP"}>{t("donation.fields.status.TO_PICKUP")}</Select.Option>
						<Select.Option value={"PICKED_UP"}>{t("donation.fields.status.PICKED_UP")}</Select.Option>
						<Select.Option value={"IN_PROGRESS"}>{t("donation.fields.status.IN_PROGRESS")}</Select.Option>
						<Select.Option value={"COMPLETED"}>{t("donation.fields.status.COMPLETED")}</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item
					label={t("donation.fields.pickUpDayPreference")}
					name={["pickupDaysPreference"]}
					data-cy="pickupDaysPreference"
					initialValue={t("donation.filters.pickUpDayPreferenceEnum." + formProps?.initialValues?.pickupDaysPreference)}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Select>
						<Select.Option value={"MONDAY"}>{t("donation.filters.pickUpDayPreferenceEnum.MONDAY")}</Select.Option>
						<Select.Option value={"TUESDAY"}>{t("donation.filters.pickUpDayPreferenceEnum.TUESDAY")}</Select.Option>
						<Select.Option value={"WEDNESDAY"}>{t("donation.filters.pickUpDayPreferenceEnum.WEDNESDAY")}</Select.Option>
						<Select.Option value={"THURSDAY"}>{t("donation.filters.pickUpDayPreferenceEnum.THURSDAY")}</Select.Option>
						<Select.Option value={"FRIDAY"}>{t("donation.filters.pickUpDayPreferenceEnum.FRIDAY")}</Select.Option>
						<Select.Option value={"SATURDAY"}>{t("donation.filters.pickUpDayPreferenceEnum.SATURDAY")}</Select.Option>
						<Select.Option value={"SUNDAY"}>{t("donation.filters.pickUpDayPreferenceEnum.SUNDAY")}</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item
					label={t("donation.fields.pickUpTimePreference")}
					name={["pickupHoursPreference"]}
					data-cy="pickupHoursPreference"
					initialValue={formProps?.initialValues?.pickupHoursPreference}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Select>
						<Select.Option value={"8:00-10:00"}>8:00-10:00</Select.Option>
						<Select.Option value={"10:00-12:00"}>10:00-12:00</Select.Option>
						<Select.Option value={"12:00-14:00"}>12:00-14:00</Select.Option>
						<Select.Option value={"14:00-16:00"}>14:00-16:00</Select.Option>
						<Select.Option value={"16:00-18:00"}>16:00-18:00</Select.Option>
					</Select>
				</Form.Item>
				{/* <Form.Item
					label={t("donation.fields.pointsReward")}
					name={["pointsReward"]}
					data-cy="pointsReward"
					initialValue={formProps?.initialValues?.pointsReward}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input type="tel" />
				</Form.Item> */}
				<Form.List name="contents">
					{(fields, { add, remove }) => (
						<>
							{fields.map(({ key, name, ...restField }) => (

								<Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
									<Form.Item
										data-cy="content-name"
										{...restField}
										name={[name, "name"]}
										label={t("donation.fields.contents.name")}
										rules={[{ required: true, message: "Missing name" }]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										data-cy="content-category"
										{...restField}
										name={[name, "categoryId"]}
										// it has to replace the id of the object that is being sent
										initialValue={formProps?.initialValues?.contents[name]?.category?.code}
										label={t("donation.fields.contents.category")}
										rules={[{ required: true, message: "Missing category" }]}
									>
										<Select
											placeholder="Select category"
											// pull it flat
											options={flattenCategories(categoriesData?.data)}

											loading={userIsLoading}
										/>
									</Form.Item>

									<Form.Item
										{...restField}
										name={[name, "sizeId"]}
										data-cy="content-size"
										initialValue={formProps?.initialValues?.contents[name]?.size?.value}
										label={t("donation.fields.contents.size")}
										rules={[{ required: true, message: "Missing size" }]}
									>
										<Select
											placeholder="Select size"
											options={flattentSizes()}
											loading={sizesIsLoading}
										/>
									</Form.Item>
									<Form.Item
										{...restField}
										name={[name, "conditionId"]}
										data-cy="content-condition"
										initialValue={formProps?.initialValues?.contents[name]?.condition?.name}
										label={t("donation.fields.contents.condition")}
										rules={[{ required: true, message: "Missing condition" }]}
									>
										<Select
											placeholder="Select condition"
											options={flattenConditions(conditionsData?.data)}
											loading={conditionsIsLoading}
										/>
									</Form.Item>
									<Tooltip title={t("donation.fields.contents.notCompliantTooltip")}>
										<Form.Item
											{...restField}
											name={[name, "notCompliant"]}
											data-cy="content-notCompliant"
											label={t("donation.fields.contents.notCompliant")}
											valuePropName="checked"
										>
											<Switch />
										</Form.Item>
									</Tooltip>
									<Form.Item
										{...restField}
										name={[name, "notCompliantReason"]}
										data-cy="content-notCompliantReason"
										label={t("donation.fields.contents.notCompliantReason")}
									>
										<Input />
									</Form.Item>


									{/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
								</Space>
							))}
							{/* <Form.Item data-cy="add-content">
								<Button
									type="dashed"
									onClick={() => add()}
									icon={<PlusOutlined />}
								>
									{t("donation.fields.contents.add")}
								</Button>
							</Form.Item> */}
						</>
					)}
				</Form.List>
			</Form>
		</Edit>
	);
};
