import { Create, getValueFromEvent, useForm } from "@refinedev/antd";
import { IResourceComponentsProps, file2Base64, useList, useTranslate } from "@refinedev/core";
import { AutoComplete, Button, Card, DatePicker, Form, Input, InputNumber, Select, TimePicker, Tooltip, Typography, Upload } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";

const { Title } = Typography;

export const PartnersCreate: React.FC<IResourceComponentsProps> = () => {
	const { formProps, saveButtonProps, onFinish } = useForm({});
	const [inputValue, setInputValue] = useState("");
	const [selectedLabel, setSelectedLabel] = useState<string>("");
	const [completeAddress, setCompleteAddress] = useState<string>("");

	const onFormFinish = async (values: any) => {
		const { logo } = values;

		if (logo && logo[0].originFileObj) {

			const base64 = await file2Base64(logo[0]);
			onFinish({ ...values, completeAddress: completeAddress, logo: base64 });
		} else {
			onFinish({ ...values, completeAddress: completeAddress });
		}
	}

	const t = useTranslate();
	const debounce = (func: any, delay: number) => {
		let timer: any;
		return function (this: any, ...args: any) {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, delay);
		};
	};

	const debouncedSetInputValue = useCallback(debounce(setInputValue, 300), []);

	const { data: autoComplete } = useList({
		resource: "map/autocomplete",
		filters: [
			{
				field: "q",
				operator: "eq",
				value: inputValue,
			},
		],
	});

	const autoCompleteData = autoComplete?.data as any;

	return (
		<Create saveButtonProps={{
			...saveButtonProps,
			style: {
				backgroundColor: "#EDA0D8",
			},

		}}>
			<Form {...formProps} layout="vertical" onFinish={onFormFinish}>
				<Form.Item label={t("partner.fields.socialReason")} name="socialReason" rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item label="Logo" name="logo" rules={[{ required: true }]}>
					<Form.Item
						name="logo"
						valuePropName="logo"
						getValueFromEvent={getValueFromEvent}
						noStyle
						rules={[
							{
								required: true,
							},
						]}
					>
						<Upload.Dragger

							listType="picture"
							multiple={false}
							accept="image/*"
							maxCount={1}
							beforeUpload={() => false}

						>
							<p className="ant-upload-text">{t("partner.fields.logoUpload")}</p>
						</Upload.Dragger>
					</Form.Item>
				</Form.Item>
				<Form.Item label={t("partner.fields.location.address")} name={"completeAddress"} rules={[{ required: true }]}>
					<AutoComplete
						options={autoCompleteData?.results?.map((result: any) => ({
							label: result?.displayLines?.[0] + ", " + result?.displayLines?.[1],
							value: result?.completionUrl,
						}))}
						onSearch={(searchText) => debouncedSetInputValue(searchText)}
						onSelect={(value, option) => {
							// Mettre à jour le formulaire avec la valeur (envoyée en interne)
							formProps.form?.setFieldsValue({ completeAddress: option?.label?.toString() });
							setCompleteAddress(option?.value?.toString() ?? "");
						}}
						value={selectedLabel}
						onChange={(_, option) => {
							// Optionnel: gérer des cas où l'utilisateur efface la sélection
							if (!option) setSelectedLabel("");
						}}
					/>
				</Form.Item>

			</Form>
		</Create>
	);
};
