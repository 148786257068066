import { DateField, Edit, useForm } from "@refinedev/antd";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Card, DatePicker, Form, Input, Select } from "antd";
import { DateTime } from "luxon";
import React, { useState } from "react";

export const PotsEdit = (props: IResourceComponentsProps) => {
	const { formProps, saveButtonProps, formLoading, onFinish } = useForm({});
	const t = useTranslate();

	const onFormFinish = (values: any) => {
		onFinish(values);
	}

	return (
		<Edit saveButtonProps={{
			...saveButtonProps,
			style: {
				backgroundColor: "#EDA0D8",
			},
		}} isLoading={formLoading} contentProps={{
			style: {
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
			},

		}}>
			<Form {...formProps} layout="vertical" onFinish={onFormFinish}>
				<Form.Item label={t("pots.fields.firstName")} name="firstName" initialValue={formProps.initialValues?.user?.firstName}>
					<Input disabled />
				</Form.Item>
				<Form.Item label={t("pots.fields.lastName")} name="lastName" initialValue={formProps.initialValues?.user?.lastName}>
					<Input disabled />
				</Form.Item>
				<Form.Item label={t("pots.fields.balance")} name="balance" initialValue={formProps.initialValues?.balance}>
					<Input />
				</Form.Item>
			</Form>
		</Edit >
	);
};
